import './moncss.css';
import React,  {Component} from 'react';
import { Nav,Navbar,Button,Form,Container,Row,Col} from 'react-bootstrap';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { Redirect } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal'
import Toast from 'react-bootstrap/Toast'
import Card from 'react-bootstrap/Card'
import logo from './logo.png';
import Bounce from 'react-reveal/Bounce';
import Flip from 'react-reveal/Flip';
import Fade from 'react-reveal/Fade';
import Slide from 'react-reveal/Slide';
import Zoom from 'react-reveal/Zoom';
import axios from 'axios';
import { BrowserView, MobileView, isBrowser, isMobile} from "react-device-detect";
import Carousel from 'react-bootstrap/Carousel';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Fab from '@material-ui/core/Fab';
import FacebookIcon from '@material-ui/icons/Facebook';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import YouTubeIcon from '@material-ui/icons/YouTube';
import MailIcon from '@material-ui/icons/Mail';
import CallIcon from '@material-ui/icons/Call';
import HouseIcon from '@material-ui/icons/House';
import AddIcon from '@material-ui/icons/Add';
import PersonIcon from '@material-ui/icons/Person';
import SearchIcon from '@material-ui/icons/Search';
import SendIcon from '@material-ui/icons/Send';
import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendar';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/core/styles';
import { green, purple, red } from '@material-ui/core/colors';
//import Autocomplete from '@material-ui/lab/Autocomplete';
import queryString from 'query-string';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Login from './Login';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { isExpired, decodeToken }from "react-jwt";
import Loader from "react-js-loader";
const key = "MIICXAIBAAKBgQCiQ5lLUPoLiI3VI69VfZ32tWjsL6HvqzYEtUbxutptHb3PYhKcbqirJ2cADUyWBWpfWgAQyshSciov5PhskWF5wRyhxc0WOLuK72icFqOu2ZLE2TvRvAzjNT2TaBHBeeJ2t39u6pPBz9ejZuXc05AapG2Jh7HfCORkTsCIhwGydwIDAQABAoGAT1wYzMeF/RJuQV85mWcG9w8NKs53y68yxDoQ0ZBNaKCztaGSFwR5UzhZZsn"
const CryptoJS = require("crypto-js");

var errocode = 0;

const typeuser = [
  {
    label: "",
    value: "0",
  },
  {
    label: "Administrateur",
    value: "1",
  },
  {
    label: "Manager",
    value: "2",
  },
  {
    label: "Utilisateur",
    value: "3",
  },

];

const societedata = [
  {
    label: "",
    value: "0",
  },
  {
    label: "UNSAID",
    value: "1",
  },
  {
    label: "ADENNES",
    value: "2",
  },
];

class Profil extends Component{
    
    constructor(props) {
    super(props);
    this.state = {
      wbslink:'',
      selectedFile: null,
      dureesound:'',
      selectedOption: null,
      listsound: [],
      persons: [],
      speakers: [],
      listsociete: [],
      listtypeuser: [],
      speakers: [],
      selectedValue: "",
      diarizer: [],
      iduser:0,
      usertype:0,
      iduserupdate:0,
      nom: '',
      prenoms: '',
      mail: '',
      motdepasse: '',
      societe: 0,
      societecentre:14,
      telephone: '',
      poste: '',
      errorform: false,
      errormessage:'',
      signin : false,
      errorformpassword: false,
      signinpassword : false,
      tokenuser:[],
      isModalthematique: false,
      isModalspeaker: false,
      speaker1: '',
      speaker2: '',
      idaudioselect: 0,
      thematique:'',
      show_input:'text',
      lng:'',
      listcentre:[],
      motdepassenew:'',
      motdepasserenew:'',
      showpassword : 'password',
      imagefilename:'',
      imageselect:'',
      imageshow:'',
      nameprofil:'',
      imageprofillink:'',
      selectedFile: '',
      loadingdata: true,

    };

    this.selectusertype = this.selectusertype.bind(this);
    this.selectsociety = this.selectsociety.bind(this);
    this.selectsocietycentre = this.selectsocietycentre.bind(this);
        
  }

  componentWillMount() {

    let wbslinkload = sessionStorage.getItem('wbs');

    this.setState({
      wbslink:wbslinkload,
    })

    let tokenuser = sessionStorage.getItem('tokenunsaid');

    const myDecodedToken = decodeToken(tokenuser);

    if(myDecodedToken == null){
      this.setState({ typeuser: null})
      document.location.href = '/login';
      return;
    }
    const isMyTokenExpired = isExpired(tokenuser);

    if(isMyTokenExpired == true){
      this.setState({ typeuser: null})
      document.location.href = '/login';
      return;
    }

    let tokencredit = JSON.stringify(myDecodedToken.uid).replace(/\"/g, "");
    
    fetch(wbslinkload + `getdatauser`,{                  
          
      mode: 'cors',     
      method:'post',
      headers: {'Authorization': 'Bearer '+ tokencredit},

      })

      .then(res => {

        errocode = res.status;
  
        if (!res.ok) {
            
            return res.json().then(json => { throw json; });
  
        }
  
        return res.json();
  
      })
      .then(data => {

        if(JSON.stringify(data.status).replace(/"/g, "") == "unsubscribed" && window.location.pathname != "/Buycredit"){

          document.location.href = "/Buycredit";

        }else{

          this.setState({
            loadingdata:false
          })
          
        }
        
    })

  }
    
  async componentDidMount() {  
    
    let lnguser = sessionStorage.getItem('langueuser');

    this.setState({ 
        lng:lnguser
    })

    let token = sessionStorage.getItem('tokenunsaid');
    
    const myDecodedToken = decodeToken(token);

    if(myDecodedToken == null){
      this.setState({ typeuser: null})
      document.location.href = '/login';
      return;
    }
    const isMyTokenExpired = isExpired(token);

    if(isMyTokenExpired == true){
      this.setState({ typeuser: null})
      document.location.href = '/login';
      return;
    }

    token = JSON.stringify(myDecodedToken.uid).replace(/\"/g, "");

    const response = await fetch(this.state.wbslink + 'getuser',{                       
      mode:'cors',
      method:'post',
      headers: {'accept': 'application/json','Authorization': 'Bearer '+ token},
      });
    const data = await response.json();

    if(data.length>0){

        this.setState({
          nom: JSON.stringify(data[0].nomutilisateur).replace(/\"/g, ""),
          prenoms: JSON.stringify(data[0].prenomsutilisateur).replace(/\"/g, ""),
          mail: JSON.stringify(data[0].email).replace(/\"/g, ""),
          societe: parseInt(JSON.stringify(data[0].idsocieteutilisateur).replace(/\"/g, "")),
          societecentre: parseInt(JSON.stringify(data[0].idcentreutilisateur).replace(/\"/g, "")),
          telephone: JSON.stringify(data[0].telephoneutilisateur).replace(/\"/g, ""),
          poste: JSON.stringify(data[0].posteutilisateur).replace(/\"/g, ""),
          usertype: parseInt(JSON.stringify(data[0].idtypeutilisateur).replace(/\"/g, "")),
          imageprofillink: JSON.stringify(data[0].imageprofil).replace(/\"/g, ""),
        })
    
    }

    const responsesociety = await fetch(this.state.wbslink + 'society',{                       
      mode:'cors',
      method:'get',
      headers: {'accept': 'application/json','Authorization': 'Bearer '+ token},
      });
    const datasociety = await responsesociety.json();

    if(datasociety.length>0){

      this.setState({ listsociete: datasociety})

    }else{
      this.setState({ loadershow: false})
    }

    const responsetypeuser = await fetch(this.state.wbslink + 'listtypeuser',{                       
      mode:'cors',
      method:'post',
      headers: {'accept': 'application/json','Authorization': 'Bearer '+ token},
      });
    const datatypeuser = await responsetypeuser.json();

    if(datatypeuser.length>0){

      this.setState({ listtypeuser: datatypeuser})

    }else{
      this.setState({ loadershow: false})
    }

    const responsecenter = await fetch(this.state.wbslink + 'center',{                       
      mode:'cors',
      method:'get',
      headers: {'accept': 'application/json','Authorization': 'Bearer '+ token},
      });
    const datacenter = await responsecenter.json();
    this.setState({ listcentre: datacenter})

}

    updateuser () {

      let lnguser = sessionStorage.getItem('langueuser');

      if (parseInt(this.state.usertype)  == 0) {

        if(lnguser === "FR"){
          this.setState({ errorform: true, errormessage: 'Type d\'utilisateur vide. Veuillez seléctionner un type d\'utilisateur.'  });
        }else{
          this.setState({ errorform: true, errormessage: 'User type not define' });
        }

      return;
        
    }

        if (this.state.mail == "" ) {

            var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

            if (!pattern.test(this.state.mail)) {

              if(lnguser === "FR"){
                this.setState({ errorform: true, errormessage: 'Email invalide. Veuillez saisir une adresse e-mail valide s\'il vous plait.'  });
              }else{
                this.setState({ errorform: true, errormessage: 'Email not valid. Please put exact mail.' });
              }
                
              return;

            }
            
        }

        if (this.state.nom == "" && this.state.prenoms == "") {

          if(lnguser === "FR"){
    
            this.setState({ errorform: true, errormessage: 'Veuillez saisir votre nom ou prénoms.'  });
    
          }else{
    
            this.setState({ errorform: true, errormessage: 'Please put your name or lastname.' });
    
          }
              
          return;
    
        }
      {/** 
      if (parseInt(this.state.societecentre)  == 0 && idtype > 2) {

          if(lnguser === "FR"){
            this.setState({ errorform: true, errormessage: 'Centre non séléctionnée. Veuillez seléctionner un centre de l\'utilisateur.' });
          }else{
            this.setState({ errorform: true, errormessage: 'Center not selected. Please select a center for user.' });
          }

          return;
          
      }
      */}

    // Create an object of formData 
    const formData = new FormData(); 

    formData.append("typeutilisateur",this.state.usertype);
    formData.append("nomutilisateur",this.state.nom);
    formData.append("prenomsutilisateur",this.state.prenoms);
    formData.append("idsocieteutilisateur",this.state.societe);
    formData.append("idcentreutilisateur",this.state.societecentre);
    formData.append("telephoneutilisateur",this.state.telephone);
    formData.append("posteutilisateur",this.state.poste);


    let token = sessionStorage.getItem('tokenunsaid');
  
    const myDecodedToken = decodeToken(token);

    if(myDecodedToken == null){
      this.setState({ typeuser: null})
      document.location.href = '/login';
      return;
    }
    const isMyTokenExpired = isExpired(token);

    if(isMyTokenExpired == true){
      this.setState({ typeuser: null})
      document.location.href = '/login';
      return;
    }

    token = JSON.stringify(myDecodedToken.uid).replace(/\"/g, "");

    fetch(this.state.wbslink + `updateuser`,{                  
          
      mode: 'cors',     
      method:'post',
      headers: {'Authorization': 'Bearer '+ token},
      body: formData

      })

      .then(res => {

        errocode = res.status;
  
        if (!res.ok) {
            
            return res.json().then(json => { throw json; });
  
        }
  
        return res.json();
  
      })
    .then(data => {

    if(data.length > 0){

      if(this.state.lng === "FR"){

        alert("Modification bien éfféctuée.")

      }else{

        alert("Update successful.")
        
      }

    }else{

      this.setState({ listdata : [],idsociete: 0, question:''});

    }

  })

  .catch(err => {
  
    if(errocode == 401 || errocode == 0){
      
      sessionStorage.removeItem("tokenunsaid");
      document.location.href = "/login";

      return

    }

    alert("Erreur :" + errocode + ", "+JSON.stringify(err.message).replace(/\"/g, ""))

    this.setState({
      loadershow: false
    })

  });  
  
  };

  changepassword(){

    let lnguser = sessionStorage.getItem('langueuser');

    if (this.state.motdepassenew == "") {

      if(lnguser === "FR"){
        this.setState({ errorformpassword: true, errormessage: 'Mot de passe requis. Veuillez saisir votre mot de passe.' });
      }else{
        this.setState({ errorformpassword: true, errormessage: 'Password is null. Please enter a password.' });
      }
            
      return;

    }

    if (this.state.motdepassenew.length >1 && this.state.motdepassenew.length <8) {

      if(lnguser === "FR"){
        this.setState({ errorformpassword: true, errormessage: 'Mot de passe manquant. Veuillez saisir au moins 8 caractères.' });
      }else{
        this.setState({ errorformpassword: true, errormessage: 'Password is invalid. Please enter minimum 8 characters.' });
      }
            
      return;

    }

    if (this.state.motdepassenew != this.state.motdepasserenew) {

      if(this.state.lng === 'FR'){

        this.setState({ errorformpassword: true, errormessage: 'Veuillez saisir le même mot de passe.' });
        return
      
      }
    
      if(this.state.lng === 'EN'){
    
        this.setState({ errorformpassword: true, errormessage: 'Please write same password.' });
        return
      
      }

    }

    // Create an object of formData 
    const formData = new FormData(); 
    let token = sessionStorage.getItem('tokenunsaid');
  
    const myDecodedToken = decodeToken(token);

    if(myDecodedToken == null){
      this.setState({ typeuser: null})
      document.location.href = '/login';
      return;
    }
    const isMyTokenExpired = isExpired(token);

    if(isMyTokenExpired == true){
      this.setState({ typeuser: null})
      document.location.href = '/login';
      return;
    }

    token = JSON.stringify(myDecodedToken.uid).replace(/\"/g, "");

    formData.append("newpassword",this.state.motdepassenew);

    fetch(this.state.wbslink + `updateuserpassword`,{                  
          
      mode: 'cors',     
      method:'post',
      headers: {'Authorization': 'Bearer '+ token},
      body: formData

      })

      .then(res => {

        errocode = res.status;
  
        if (!res.ok) {
            
            return res.json().then(json => { throw json; });
  
        }
  
        return res.json();
  
      })

      .then(data => {

        this.setState({
          errorformpassword: false,
          errormessage:'',
          signinpassword : true,
          motdepassenew:'',
          motdepasserenew:'',
          showpassword:"password",
        });              

        if(this.state.lng === "FR"){

          alert("Modification bien éfféctuée.")
    
        }else{
    
          alert("Update successful.")
          
        }

      })
      .catch(error => {
          this.setState({ tokenuser: error});
      });  


  }

  selectusertype(e) {
    this.setState({ usertype: e.target.value });
  }

  selectsociety(e) {
    this.setState({ societe: e.target.value });
  }

  selectsocietycentre(e) {
    this.setState({ societecentre: e.target.value });
  }
   
  onFileChange = event => { 
    // Update the state 
    this.setState({
      selectedFile: event.target.files[0],
      imagefilename : event.target.files[0].name,
      imageselect: URL.createObjectURL(event.target.files[0]),
     }); 

  }; 
   
  // On file upload (click the upload button) 
  changeimageprofil (){

    if(this.state.selectedFile === null || this.state.selectedFile === 'null'){

      this.setState({
        loadershow: false,
        textloading:'Please select a file.'
      });   

      return;

    }

    if(this.state.selectedFile.size > 25 * 1024 * 1024)
    {
      this.setState({
        loadershow: false,
        textloading:'Image need to be under 25Mb.'
      });
      
      return;

    }


    if(this.state.name === '' ){

      this.setState({
        loadershow: false,
        textloading:'Please put a name of profil.'
      });
      
      return;
    
    }

    this.setState({
      loadershow: true,
      textloading:'Uploading file ...'
    });

    let token = sessionStorage.getItem('tokenunsaid');
  
    const myDecodedToken = decodeToken(token);

    token = JSON.stringify(myDecodedToken.uid).replace(/\"/g, "");

    // Create an object of formData 
    const formData = new FormData(); 

    formData.append("name",this.state.name);
    formData.append("imageprofil",this.state.selectedFile);
  
  
    fetch(this.state.wbslink + `imageprofil`,{     
  
      mode: 'cors',     
      method:'post',
      headers: {'Authorization': 'Bearer '+ token},
      body: formData

    })

    .then(res => {

      errocode = res.status;

      if (!res.ok) {
          
          return res.json().then(json => { throw json; });

      }

      return res.json();

    })
    .then(data => {

      
      this.setState({ imageprofillink: JSON.stringify(data).replace(/\"/g, "")})

    this.setState({
      selectedFile:null,
      imageselect:'',
      imagefilename : '',
      loadershow: false,
      modaladd: false,
    })

    if(this.state.lng === "FR"){

      alert("Modification bien éfféctuée.")

    }else{

      alert("Update successful.")
      
    }

    window.location.reload();

    //this.refreshdata();

    })
    .catch(err => {
    
      if(errocode == 401 || errocode == 0){
        
        sessionStorage.removeItem("tokenunsaid");
        document.location.href = "/login";

        return

      }

      alert("Erreur :" + errocode + ", "+JSON.stringify(err.message).replace(/\"/g, ""))

      this.setState({
        loadershow: false
      })

    });  


}

  render() {

          return (

                <div>

                  {this.state.loadingdata == true?

                    null
                  
                    :

                    <div style={{marginTop:'120px'}}>

                  <div className="container">

                    <div className="row">

                      {/*IMAGE USER */}
                      <div className="col-sm">

                      <div className="d-flex justify-content-center">
                        <Card style={{ width: '100%', marginTop:"30px", marginBottom:"50px" }}>
                          
                          <Card.Body>
                            <Card.Title style={{textAlign:"center"}}>{this.state.lng ==="FR" ? 'Photo de profil' : 'Profile picture'}</Card.Title>
                            <Card.Text style={{marginTop:"30px"}} className="contentcenter">

                              <img src={this.state.wbslink + 'images/profil/' + this.state.imageprofillink}  style={{width:"250px", height:"250px", borderRadius:"250px", marginLeft:"5px"}}/>

                              {this.state.imageselect === '' ?

                                <div style={{textAlign:"center", marginTop:"20px"}}>
                                    {this.state.lng == 'FR'? 'Pas d\'image sélectionné':'No selected file'}                   
                                </div>

                                :

                                <div className="imagemodal">
                                  <p>{this.state.lng == 'FR'? 'Pas de nouvelle image':'No image'}</p>
                                  <img alt={this.state.imagefilename} src={this.state.imageselect} style={{width:"250px", height:"250px", borderRadius:"250px", marginLeft:"5px"}}/> 
                                  <p>{this.state.imagefilename}</p>
                                </div>

                                }

                                <div className='selectbutton'> 
                                <label htmlFor="filePickerimgcat" style={{background:"#0d6efd", padding:"5px 10px", marginTop:'20px', color:'white', borderRadius:'5px',textAlign:'center', width:'200px' }} variant="primary" >{this.state.lng == 'FR'? 'Sélectionner une image':'Select an image'}</label>
                                <input id="filePickerimgcat" style={{visibility:"hidden"}} name="imgcat" type="file" accept="image/*" onChange={this.onFileChange}/>
                                </div>


                                {this.state.loadershow === true ?


                                <Loader type="spinner-cub" bgColor={'#0275d8'} title={this.state.textloading} color={'#0275d8'} size={100} />
                                  

                                :

                                null

                                }

                                <div className='d-flex justify-content-center'>
                                          
                                  {this.state.selectedFile === null ?

                                    null

                                    :

                                    <Button className="btn" style={{ margin:"10px",right: 0, width:'200px'}} onClick={() => this.changeimageprofil()} variant="success">{this.state.lng == 'FR'? 'Mettre à jour':'Update'}</Button>

                                  }
                                  
                                </div>


                            </Card.Text>
                              
                          </Card.Body>
                          
                        </Card>

                      </div>
                        
                       
                      </div>

                      {/*DATA USER */}
                      <div className="col-sm">

                      <div className="d-flex justify-content-center">
                        <Card style={{ width: '100%', marginTop:"30px", marginBottom:"50px" }}>
                          
                          <Card.Body>
                            <Card.Title style={{textAlign:"center"}}>{this.state.lng ==="FR" ? 'Informations' : 'Informations'}</Card.Title>
                            <Card.Text style={{marginTop:"30px"}}>
                            

                            <div className="form-group" style={{margin:'5px'}}>

                            <label htmlFor="name">{this.state.lng ==="FR" ? 'Vous êtes : *' : 'You are : *'}</label>
                            <div>
                            <div>
                              <Form.Select style={{height:'30px', paddingTop:'0px', paddingLeft:'10px' }} value={this.state.usertype} onChange={this.selectusertype}>
                                {this.state.listtypeuser.map((typ) => (
                                  <option value={typ.idtypeutilisateur} disabled={true}>{typ.nomtypeutilisateur}</option>
                                ))}
                              </Form.Select>
                            </div>
                            </div>

                            </div>
                                  
                            <div className="form-group" style={{margin:'5px'}}>
                            <label>{this.state.lng ==="FR" ? 'Nom' : 'Last name'}</label>
                            <input type="text" className="form-control" value={this.state.nom} onChange={(e) => this.setState({ nom: e.target.value})} />
                            </div>

                            <div className="form-group" style={{margin:'5px'}}>
                            <label>{this.state.lng ==="FR" ? 'Prénom(s)' : 'First name'}</label>
                            <input type="text" className="form-control" value={this.state.prenoms} onChange={(e) => this.setState({ prenoms: e.target.value})} />
                            </div>

                            {/*
                            <div className="form-group" style={{margin:'5px'}}>
                            <label>Email *</label>
                            <input type="email" className="form-control" aria-describedby="emailHelp" value={this.state.mail} onChange={(e) => this.setState({ mail: e.target.value})} />
                            </div>
                            */}

                            {/*
                            <div className="form-group" style={{margin:'5px'}}>
                            <label>{this.state.lng ==="FR" ? 'Mot de passe *' : 'Password *'}</label>
                            <input type="password" className="form-control" value={this.state.motdepasse} onChange={(e) => this.setState({ motdepasse: e.target.value})} />
                            </div>
                            */}

                            {parseInt(this.state.iduser) == 2 ?

                            <div className="form-group" style={{margin:'5px'}}>
                            <label >{this.state.lng ==="FR" ? 'Société :' : 'Society: '}</label>
                            <div>
                              <Form.Select style={{height:'30px', paddingTop:'0px', paddingLeft:'10px' }} value={this.state.societe} onChange={this.selectsociety}>
                                {this.state.listsociete.map((soc) => (
                                  <option value={soc.idsociete}>{soc.nomsociete}</option>
                                ))}
                              </Form.Select>
                            </div>
                            </div>

                            :

                            null

                            }


                            {/*<div className="form-group" style={{margin:'5px'}}>
                            <label>Société</label>
                            <input type="email" className="form-control" value={this.state.societe} onChange={(e) => this.setState({ societe: e.target.value})} />
                            </div>*/}

                            <div className="form-group" style={{margin:'5px'}}>
                            <label >{this.state.lng ==="FR" ? 'Centre :' : 'Center: '}</label>
                            <div>
                              <Form.Select style={{height:'30px', paddingTop:'0px', paddingLeft:'10px' }} value={this.state.societecentre} onChange={this.selectsocietycentre}>
                                {this.state.listcentre.map((soc) => (
                                  <option value={soc.idsocietycenter}>{soc.name}</option>
                                ))}
                              </Form.Select>
                            </div>
                            </div>


                            <div className="form-group" style={{margin:'5px'}}>
                            <label>{this.state.lng ==="FR" ? 'Téléphone' : 'Phone number'}</label>
                            <input type="text" className="form-control" value={this.state.telephone} onChange={(e) => this.setState({ telephone: e.target.value})} />
                            </div>


                            <div className="form-group" style={{margin:'5px'}}>
                            <label>{this.state.lng ==="FR" ? 'Poste' : 'Job'}</label>
                            <input type="email" className="form-control" value={this.state.poste} onChange={(e) => this.setState({ poste: e.target.value})} />
                            </div>

                            {this.state.errorform == true ? 

                            <Alert severity="error">
                              <AlertTitle>Erreur</AlertTitle>
                              {this.state.errormessage}
                            </Alert>



                            :

                            null

                            }

                            {this.state.signin == true ?
                              

                              <div >
                                <a className="d-flex justify-content-center" style={{marginTop:"15px", color:"#2A83E8", textDecoration:"none", textAlign:"center"}}>{this.state.lng ==="FR" ? 'L\'utilisateur a été modifié.' : 'The user has been update.'}</a>
                              </div>
                              

                            :

                            null

                            }

                            <div className="d-flex justify-content-center">
                              <Button className="btn btn-success" style={{ marginTop:'10px'}} onClick={() => this.updateuser()} >{this.state.lng ==="FR" ? 'Mettre à jour les informations' : 'Update informations'}</Button>
                            </div>

                            </Card.Text>
                              
                          </Card.Body>
                          
                        </Card>

                      </div>


                      </div>

                      {/*PASSWORD USER */}
                      <div className="col-sm">
                        
                      <div className="d-flex justify-content-center">
                        <Card style={{ width: '100%', marginTop:"30px", marginBottom:"50px" }}>
                          
                          <Card.Body>
                            <Card.Title style={{textAlign:"center"}}>{this.state.lng ==="FR" ? 'Mot de passe' : 'Password'}</Card.Title>
                            <Card.Text style={{marginTop:"30px"}}>

                            <label style={{marginLeft:isBrowser? '10px':'0px'}}>{this.state.lng ==="FR" ? 'Nouveau mot de passe' : 'New password'}</label>

                            <div className='d-flex flex-row'>
                          
                              <input type={this.state.showpassword == "text" ? "text" : "password"} className="form-control" value={this.state.motdepassenew} onChange={(e) => this.setState({ motdepassenew: e.target.value})} /> 
  
                              {this.state.showpassword == "password" ? <span onClick={() => this.setState({ showpassword: "text"})} style={{marginLeft:"10px"}}size={25}><VisibilityIcon/></span> : <span onClick={() => this.setState({ showpassword: "password"})} size={25}><VisibilityOffIcon/></span>}
                            
                            </div>

                            <label style={{marginLeft:isBrowser?'10px':'0px',marginTop:"20px"}}>{this.state.lng ==="FR" ? 'Resaisir le nouveau mot de passe' : 'Re-enter New password'}</label>

                            <div className='d-flex flex-row'>
                          
                              <input type={this.state.showpassword == "text" ? "text" : "password"} className="form-control" value={this.state.motdepasserenew} onChange={(e) => this.setState({ motdepasserenew: e.target.value})} /> 
                                
                              {this.state.showpassword == "password" ? <span onClick={() => this.setState({ showpassword: "text"})} style={{marginLeft:"10px"}}size={25}><VisibilityIcon/></span> : <span onClick={() => this.setState({ showpassword: "password"})} size={25}><VisibilityOffIcon/></span>}

                            </div>

                            {this.state.errorformpassword == true ? 

                              <Alert severity="error">
                                <AlertTitle>Erreur</AlertTitle>
                                {this.state.errormessage}
                              </Alert>

                              

                              :

                              null

                            }

                            </Card.Text>
                              <div className="d-flex justify-content-center">
                                <Button className="btn btn-success" style={{ marginTop:'10px'}} onClick={() => this.changepassword()} >{this.state.lng ==="FR" ? 'Modifier le mot de passe' : 'Change password'}</Button>
                              </div>

                              

                              {this.state.signinpassword == true ?
                                  
                              
                                  <div >
                                    <a className="d-flex justify-content-center" style={{marginTop:"15px", color:"#2A83E8", textDecoration:"none", textAlign:"center"}}>{this.state.lng ==="FR" ? 'Le mot de passe a été modifié.' : 'Password has been update.'}</a>
                                  </div>
                                  

                                :

                                null

                              }

                          </Card.Body>
                        </Card>
                        </div>


                      </div>


                    </div>

                  </div>

                </div>

                  }

                </div>
              
          );

        }
    
}

export default Profil;
