import React,  {Component} from 'react';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import queryString from 'query-string';
//import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import ScheduleSendIcon from '@mui/icons-material/ScheduleSend';
import CheckIcon from '@mui/icons-material/Check';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import './moncss.css';
import { Button,Form,Modal} from 'react-bootstrap';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { BrowserView, MobileView, isBrowser, isMobile} from "react-device-detect";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Card from 'react-bootstrap/Card'
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table'
import ReactPaginate from 'react-paginate';
import { isExpired, decodeToken }from "react-jwt";
import AddIcon from '@material-ui/icons/Add';
import OtpInput from 'react-otp-input';
const key = "MIICXAIBAAKBgQCiQ5lLUPoLiI3VI69VfZ32tWjsL6HvqzYEtUbxutptHb3PYhKcbqirJ2cADUyWBWpfWgAQyshSciov5PhskWF5wRyhxc0WOLuK72icFqOu2ZLE2TvRvAzjNT2TaBHBeeJ2t39u6pPBz9ejZuXc05AapG2Jh7HfCORkTsCIhwGydwIDAQABAoGAT1wYzMeF/RJuQV85mWcG9w8NKs53y68yxDoQ0ZBNaKCztaGSFwR5UzhZZsn"
const CryptoJS = require("crypto-js");

var errocode = 0;

var listdata=[];

const langueuser = [
  { name: '', value: '' },
  { name: 'FR', value: 'FR' },
  { name: 'EN', value: 'EN' },
];

class Utilisateurs extends Component{
    
    constructor(props) {
    super(props);
      this.state = {
        admin:'',
        selectedFile: null,
        dureesound:'',
        selectedOption: null,
        listuser: [],
        persons: [],
        speakers: [],
        speaker1: [],
        speaker1sentiment: [],
        speaker2: [],
        speaker2sentiment: [],
        speakernegatif: 0.00,
        speakerneutre: 0.00,
        speakerpositif: 0.00,
        selectedValue: "",
        diarizer: [],
        idtype : 0,
        iduserupdate : 0,
        activeuserupdate : 0,
        nom:'',
        prenoms:'',
        societe:1,
        societeinit:1,
        societecentre:1,
        telephone:'',
        poste:'',
        usertype:1,
        langue:'',
        mail:'',
        taxe:0.00,
        tokenuser:[],
        isModalthematique: false,
        isModalspeaker: false,
        idutilisateurselect: 0,
        thematique:'',
        show_input:'text',
        tokenexist: true,
        tokenvalue: '',
        username: '',
        soundlink:'',
        listsociete: [],
        listtypeuser: [],
        modalupdate: false,
        modaldelete: false,
        modalcredit:false,
        lng:'',
        wbslink:'',
        nombrecredit:0,
        errorform: false,
        errormessage:'',
        showpassword : "password",
        motdepasse:'',
        listuserinit: [],
        filterdata:'',
        offset: 0,
        listuserpage: [],
        perPage: 5,
        currentPage: 0,
        mailutilisateur:'',
        loadingdata: true,
        otp:'',
        modalvalidate:false,
      };

      this.selectsociety = this.selectsociety.bind(this);
      this.selectusertype = this.selectusertype.bind(this);
      this.selectsocietycentre = this.selectsocietycentre.bind(this);
      this.selectlangue = this.selectlangue.bind(this);
      this.handlePageClick = this.handlePageClick.bind(this);
        
  }

  handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    this.setState({
        currentPage: selectedPage,
        offset: offset
    }, () => {
        this.loadMoreData(this.state.listuserpage)
    });

};

  loadMoreData(data) {

  const slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
  this.setState({
    pageCount: Math.ceil(data.length / this.state.perPage),
    listuser:slice
  })

  window.scrollTo(0, 0)

  }

  componentWillMount() {

    let wbslinkload = sessionStorage.getItem('wbs');

    this.setState({ wbslink:wbslinkload})

    let lnguser = sessionStorage.getItem('langueuser');

    let token = sessionStorage.getItem('tokenunsaid');

    if(token === '' || token === 'Undefined' || token === null){
      this.setState({ 
        tokenvalue: '',
        connected:false,
        admin: '',
        manager: '',
        user: '',
        lng:'',
      })
      return;
    }

    const myDecodedToken = decodeToken(token);
    
    if(myDecodedToken == null){
      this.setState({ 
        tokenvalue: '',
        connected:false,
        admin: '',
        manager: '',
        user: '',
        lng:'',
      })
      this.setState({ typeuser: null})
      document.location.href = '/login';
      return;
    }
    const isMyTokenExpired = isExpired(token);

    if(isMyTokenExpired == true){
      this.setState({ 
        tokenvalue: '',
        connected:false,
        admin: '',
        manager: '',
        user: '',
        lng:'',
      })
      this.setState({ typeuser: null})
      document.location.href = '/login';
      return;
    }

    const datauser = JSON.stringify(myDecodedToken.init).replace(/\"/g, "");

    
    var bytes  = CryptoJS.AES.decrypt(datauser.toString().replace(/p1L2u3S/g, '+' ).replace(/p1L2u3S/g, '+' ).replace(/s1L2a3S4h/g, '/').replace(/e1Q2u3A4l/g, '=').toString(), key);
    var originaldata = bytes.toString(CryptoJS.enc.Utf8);
    var userdata  = originaldata.split('-');

    //alert("IP:" + userdata[0]+", ID TYPE:" + userdata[1]+", ID USER:" + userdata[2]+", MAIL:" + userdata[4].replace(/tirait/g, '-' )+", LNG:" + userdata[5]+", NAME:" + userdata[6])

    {/** 
    if(userdata[5] === "FR"){
      this.setState({ lng:'FR' })
    }else{
      this.setState({ lng:'EN'})
    }
    */}

    let tokencredit = JSON.stringify(myDecodedToken.uid).replace(/\"/g, "");
    
    fetch(wbslinkload + `getdatauser`,{                  
          
      mode: 'cors',     
      method:'post',
      headers: {'Authorization': 'Bearer '+ tokencredit},

      })

      .then(res => {

        errocode = res.status;
  
        if (!res.ok) {
            
            return res.json().then(json => { throw json; });
  
        }
  
        return res.json();
  
      })
      .then(data => {
        
        if(JSON.stringify(data.status).replace(/"/g, "") == "unsubscribed" && window.location.pathname != "/Buycredit"){

          document.location.href = "/Buycredit";

        }else{

          this.setState({
            loadingdata:false
          })
          
        }
        
    })

    if(parseInt(userdata[1])==2){
      this.setState({ admin: 'yes'})
    }else{
      this.setState({
        admin: '',
        societe: parseInt(userdata[3]),
      })
    }
    
    if(parseInt(userdata[1])>0){
      this.setState({ idtype: parseInt(userdata[1]),societe: parseInt(userdata[3]),})
    }
    

    if(token === '' || token === 'Undefined'){
      this.setState({ tokenexist: false})
      return
    }else{

      this.setState({ tokenvalue: token,})
      this.reloaddata();
      
    }

  }

  reloaddata () {

    if(this.state.tokenvalue !== ''){

          //window.location.reload(false);
          this.setState({ reload: true})
          
    }

  }
    
  async componentDidMount() { 

    let token = sessionStorage.getItem('tokenunsaid');
    
    const myDecodedToken = decodeToken(token);

    if(myDecodedToken == null){
      this.setState({ typeuser: null})
      document.location.href = '/login';
      return;
    }
    const isMyTokenExpired = isExpired(token);

    if(isMyTokenExpired == true){
      this.setState({ typeuser: null})
      document.location.href = '/login';
      return;
    }
    
    token = JSON.stringify(myDecodedToken.uid).replace(/\"/g, ""); 

    const response = await fetch(this.state.wbslink + 'listuser',{                       
      mode:'cors',
      method:'post',
      headers: {'accept': 'application/json','Authorization': 'Bearer '+ token},
      });
    const data = await response.json();

    if(data.length>0){

      this.setState({ listuser: data, listuserinit: data,listuserpage:data,loadershow: false})

      data.forEach((item)=>{
        listdata.push({ idutilisateur:item.idutilisateur,nomutilisateur:item.nomutilisateur,prenomsutilisateur:item.prenomsutilisateur,mailutilisateur : item.email , motdepasseutilisateur	:item.motdepasseutilisateur	,idsocieteutilisateur:item.idsocieteutilisateur,idcentreutilisateur:item.idcentreutilisateur,	telephoneutilisateur:item.telephoneutilisateur,posteutilisateur:item.posteutilisateur, typeutilisateur:item.typeutilisateur, active: item.active, creditactuel:item.creditactuel, creditachete:item.creditachete, creditconsomme:item.creditconsomme,	langue:item.langue,lastconnected:item.lastconnected,nomtypeutilisateur:item.nomtypeutilisateur,nomsociete:item.nomsociete,validateuser:item.validateuser});
      })
    
      var slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
      
      this.setState({
          pageCount: Math.ceil(data.length / this.state.perPage),
          listuserinit :data,
          listuser:slice
      })

    }else{
      this.setState({ loadershow: false})
    }

    const responsesociety = await fetch(this.state.wbslink + 'society',{                       
      mode:'cors',
      method:'get',
      headers: {'accept': 'application/json','Authorization': 'Bearer '+ token},
      });
    const datasociety = await responsesociety.json();

    if(datasociety.length>0){

      this.setState({ listsociete: datasociety})

    }else{
      this.setState({ loadershow: false})
    }

    const responsetypeuser = await fetch(this.state.wbslink + 'listtypeuser',{                       
      mode:'cors',
      method:'post',
      headers: {'accept': 'application/json','Authorization': 'Bearer '+ token},
      });
    const datatypeuser = await responsetypeuser.json();

    if(datatypeuser.length>0){

      this.setState({ listtypeuser: datatypeuser})

    }else{
      this.setState({ loadershow: false})
    }

    if(this.state.lng === "FR"){
      document.title = "Liste des utilisateurs";
    }else{
      document.title = "Users list";
    }   
  
}   

showupdateuser(type,societe,active,nom,prenoms,mailutilisateur,taxe){
 
  this.setState({
    nom: nom,
    prenoms: prenoms,
    societe: societe,
    activeuserupdate: active,
    usertype : type,
    mailutilisateur:mailutilisateur,
    taxe:taxe,
    modalupdate: true
  })

}

showvalidateuser(nom,prenoms,mailutilisateur){
 
  this.setState({
    nom: nom,
    prenoms: prenoms,
    mailutilisateur:mailutilisateur,
    modalvalidate: true
  })

}

validateuser(){

    // Create an object of formData 
    const formData = new FormData(); 

    formData.append("mailutilisateur",this.state.mailutilisateur);

    let token = sessionStorage.getItem('tokenunsaid');

    const myDecodedToken = decodeToken(token);

    if(myDecodedToken == null){
      this.setState({ typeuser: null})
      document.location.href = '/login';
      return;
    }
    const isMyTokenExpired = isExpired(token);

    if(isMyTokenExpired == true){
      this.setState({ typeuser: null})
      document.location.href = '/login';
      return;
    }

    token = JSON.stringify(myDecodedToken.uid).replace(/\"/g, "");

    fetch(this.state.wbslink + `validateuseradmin`,{                  
          
      mode: 'cors',     
      method:'post',
      headers: {'Authorization': 'Bearer '+ token},
      body: formData

      })

    .then(res => {

      errocode = res.status;

      if (!res.ok) {
          
          return res.json().then(json => { throw json; });

      }

      return res.json();

    })
    .then(data => {

    if(data.length > 0){

      for (var i = 0; i < this.state.listuser.length; i++) {
        if (this.state.listuser[i].id === this.state.iduserupdate) {

          this.state.listuser[i].validateuser = 1;

        }
      }

      for (var i = 0; i < this.state.listuserinit.length; i++) {
        if (this.state.listuserinit[i].id === this.state.iduserupdate) {

          this.state.listuser[i].validateuser = 1;

        }
      }

      for (var i = 0; i < this.state.listuser.length; i++) {
        if (this.state.listuserpage[i].id === this.state.iduserupdate) {

          this.state.listuser[i].validateuser = 1;

        }
      }    

      listdata.length = 0;

      this.setState({ listuser: data, listuserinit: data,listuserpage:data,loadershow: false})

      data.forEach((item)=>{
        listdata.push({ idutilisateur:item.idutilisateur,nomutilisateur:item.nomutilisateur,prenomsutilisateur:item.prenomsutilisateur,mailutilisateur : item.email , motdepasseutilisateur	:item.motdepasseutilisateur	,idsocieteutilisateur:item.idsocieteutilisateur,idcentreutilisateur:item.idcentreutilisateur,	telephoneutilisateur:item.telephoneutilisateur,posteutilisateur:item.posteutilisateur, typeutilisateur:item.typeutilisateur, active: item.active, creditactuel:item.creditactuel, creditachete:item.creditachete, creditconsomme:item.creditconsomme,	langue:item.langue,lastconnected:item.lastconnected,nomtypeutilisateur:item.nomtypeutilisateur,nomsociete:item.nomsociete,validateuser:item.validateuser});
      })
      
      
      var slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
      
      this.setState({
          //pageCount: Math.ceil(data.length / this.state.perPage),
          listuserinit :data,
          listuser:slice
      })
    

      this.setState({
        nom: '',
        prenoms: '',
        mailutilisateur:'',
        usertype: 0,
        taxe: 0,
        activeuserupdate: 0,
        modalvalidate: false
      })

      if(this.state.lng === "FR"){
        document.title = "Liste des utilisateurs";
      }else{
        document.title = "Users list";
      }    

    }else{

      this.setState({ listdata : [],idsociete: 0, question:''});

    }

  })
  .catch(err => {

    if(errocode == 401 || errocode == 0){
      
      sessionStorage.removeItem("tokenunsaid");
      document.location.href = "/login";

      return

    }

    alert("Erreur :" + errocode + ", "+JSON.stringify(err.message).replace(/\"/g, ""))

    this.setState({
      loadershow: false
    })

  });  


}

updateuser(){

  if (parseFloat(this.state.taxe) <= 0.00) {

    if(this.state.lng === "FR"){

      this.setState({ errorform: true, errormessage: 'Veuillez saisir la taxe de l\'utilisateur.'  });

    }else{

      this.setState({ errorform: true, errormessage: 'Please put taxe of user.' });

    }
        
    return;

  }

    // Create an object of formData 
    const formData = new FormData(); 

    formData.append("mailutilisateur",this.state.mailutilisateur);
    formData.append("typeutilisateur",this.state.usertype);
    formData.append("idsocieteutilisateur",Number(this.state.societe));
    formData.append("taxe",this.state.taxe);
    formData.append("active",Number(this.state.activeuserupdate));

    let token = sessionStorage.getItem('tokenunsaid');

    const myDecodedToken = decodeToken(token);

    if(myDecodedToken == null){
      this.setState({ typeuser: null})
      document.location.href = '/login';
      return;
    }
    const isMyTokenExpired = isExpired(token);

    if(isMyTokenExpired == true){
      this.setState({ typeuser: null})
      document.location.href = '/login';
      return;
    }

    token = JSON.stringify(myDecodedToken.uid).replace(/\"/g, "");

    fetch(this.state.wbslink + `updateuseradmin`,{                  
          
      mode: 'cors',     
      method:'post',
      headers: {'Authorization': 'Bearer '+ token},
      body: formData

      })

    .then(res => {

      errocode = res.status;

      if (!res.ok) {
          
          return res.json().then(json => { throw json; });

      }

      return res.json();

    })
    .then(data => {

    if(data.length > 0){

      for (var i = 0; i < this.state.listuser.length; i++) {
        if (this.state.listuser[i].id === this.state.iduserupdate) {

          this.state.listuser[i].nomutilisateur = this.state.nom;
          this.state.listuser[i].prenomsutilisateur = this.state.prenoms;
          this.state.listuser[i].idsocieteutilisateur= Number(this.state.societe);
          this.state.listuser[i].typeutilisateur= this.state.usertype;
          this.state.listuser[i].active= Number(this.state.active);

        }
      }

      for (var i = 0; i < this.state.listuserinit.length; i++) {
        if (this.state.listuserinit[i].id === this.state.iduserupdate) {

          this.state.listuserinit[i].nomutilisateur = this.state.nom;
          this.state.listuserinit[i].prenomsutilisateur = this.state.prenoms;
          this.state.listuserinit[i].idsocieteutilisateur= Number(this.state.societe);
          this.state.listuserinit[i].typeutilisateur= this.state.usertype;
          this.state.listuserinit[i].active= Number(this.state.active);

        }
      }

      for (var i = 0; i < this.state.listuser.length; i++) {
        if (this.state.listuserpage[i].id === this.state.iduserupdate) {

          this.state.listuserpage[i].nomutilisateur = this.state.nom;
          this.state.listuserpage[i].prenomsutilisateur = this.state.prenoms;
          this.state.listuserpage[i].idsocieteutilisateur= Number(this.state.societe);
          this.state.listuserpage[i].typeutilisateur= this.state.usertype;
          this.state.listuserpage[i].active= Number(this.state.active);

        }
      }    

      listdata.length = 0;

      this.setState({ listuser: data, listuserinit: data,listuserpage:data,loadershow: false})

      data.forEach((item)=>{
        listdata.push({ idutilisateur:item.idutilisateur,nomutilisateur:item.nomutilisateur,prenomsutilisateur:item.prenomsutilisateur,mailutilisateur : item.email , motdepasseutilisateur	:item.motdepasseutilisateur	,idsocieteutilisateur:item.idsocieteutilisateur,idcentreutilisateur:item.idcentreutilisateur,	telephoneutilisateur:item.telephoneutilisateur,posteutilisateur:item.posteutilisateur, typeutilisateur:item.typeutilisateur, active: item.active, creditactuel:item.creditactuel, creditachete:item.creditachete, creditconsomme:item.creditconsomme,	langue:item.langue,lastconnected:item.lastconnected,nomtypeutilisateur:item.nomtypeutilisateur,nomsociete:item.nomsociete,validateuser:item.validateuser});
      })
      
      
      var slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
      
      this.setState({
          //pageCount: Math.ceil(data.length / this.state.perPage),
          listuserinit :data,
          listuser:slice
      })
    

      this.setState({
        nom: '',
        prenoms: '',
        usertype: 0,
        taxe: 0,
        activeuserupdate: 0,
        modalupdate: false
      })

      if(this.state.lng === "FR"){
        document.title = "Liste des utilisateurs";
      }else{
        document.title = "Users list";
      }    

    }else{

      this.setState({ listdata : [],idsociete: 0, question:''});

    }

  })
  .catch(err => {

    if(errocode == 401 || errocode == 0){
      
      sessionStorage.removeItem("tokenunsaid");
      document.location.href = "/login";

      return

    }

    alert("Erreur :" + errocode + ", "+JSON.stringify(err.message).replace(/\"/g, ""))

    this.setState({
      loadershow: false
    })

  });  

}

showcredituser(nom,prenoms,mailutilisateur){

  let token = sessionStorage.getItem('tokenunsaid');

  if(token === '' || token === 'Undefined' || token === null){
    this.setState({ 
      tokenvalue: '',
      connected:false,
      admin: '',
      manager: '',
      user: '',
      lng:'',
    })
    return;
  }

  const myDecodedToken = decodeToken(token);
  
  if(myDecodedToken == null){
    this.setState({ 
      tokenvalue: '',
      connected:false,
      admin: '',
      manager: '',
      user: '',
      lng:'',
    })
    this.setState({ typeuser: null})
    document.location.href = '/login';
    return;
  }
  const isMyTokenExpired = isExpired(token);

  if(isMyTokenExpired == true){
    this.setState({ 
      tokenvalue: '',
      connected:false,
      admin: '',
      manager: '',
      user: '',
      lng:'',
    })
    this.setState({ typeuser: null})
    document.location.href = '/login';
    return;
  }

  const datauser = JSON.stringify(myDecodedToken.init).replace(/\"/g, "");

  
  var bytes  = CryptoJS.AES.decrypt(datauser.toString().replace(/p1L2u3S/g, '+' ).replace(/p1L2u3S/g, '+' ).replace(/s1L2a3S4h/g, '/').replace(/e1Q2u3A4l/g, '=').toString(), key);
  var originaldata = bytes.toString(CryptoJS.enc.Utf8);
  var userdata  = originaldata.split('-');

  //alert("IP:" + userdata[0]+", ID TYPE:" + userdata[1]+", ID USER:" + userdata[2]+", MAIL:" + userdata[4].replace(/tirait/g, '-' )+", LNG:" + userdata[5]+", NAME:" + userdata[6])

  {/** 
  if(userdata[5] === "FR"){
    this.setState({ lng:'FR' })
  }else{
    this.setState({ lng:'EN'})
  }
  */}

  let tokencredit = JSON.stringify(myDecodedToken.uid).replace(/\"/g, "");
  
  fetch(this.state.wbslink + `otpcode`,{                  
        
    mode: 'cors',     
    method:'post',
    headers: {'Authorization': 'Bearer '+ tokencredit},

    })

    .then(res => {

      errocode = res.status;

      if (!res.ok) {
          
          return res.json().then(json => { throw json; });

      }

      return res.json();

    })
    .then(data => {

      if(JSON.stringify(data).replace(/"/g, "") == 'true'){

        this.setState({
          nom: nom,
          prenoms: prenoms,
          mailutilisateur:mailutilisateur,
          otp:'',
          nombrecredit:0.00,
          modalcredit: true
        })

      }else{

        this.setState({
          nom: '',
          prenoms: '',
          mailutilisateur:'',
          otp:'',
          nombrecredit:0.00,
          modalcredit: false
        })
        
      }
      
  })

  .catch(err => {
     
    if(errocode == 401 || errocode == 0){
      
      sessionStorage.removeItem("tokenunsaid");
      document.location.href = "/login";
  
      return
  
    }
  
    alert("Erreur :" + errocode + ", "+JSON.stringify(err.message).replace(/\"/g, ""))
  
    this.setState({
      loadershow: false
    })
  
  });  

}

addcredituser(){

  if(parseFloat(this.state.nombrecredit)<1.00){
    
    if(this.state.lng === "FR"){
      this.setState({ errorform: true, errormessage: 'Veuillez entrer un nombre supérieur 1.' });
      return;
    }else{
      this.setState({ errorform: true, errormessage: 'Please add number greater than 1.' });
    return;
    }

  }

  if(this.state.otp.length<5){

    if(this.state.lng === "FR"){
      this.setState({ errorform: true, errormessage: 'Veuillez réctifier le code OTP.' });
      return;
    }else{
      this.setState({ errorform: true, errormessage: 'Please verify the OTP CODE.' });
    return;
    }
  
  }

  // Create an object of formData 
  const formData = new FormData(); 

  formData.append("mailutilisateur",this.state.mailutilisateur);
  formData.append("creditadd",this.state.nombrecredit);
  formData.append("otp",this.state.otp);

  let token = sessionStorage.getItem('tokenunsaid');

  const myDecodedToken = decodeToken(token);

  if(myDecodedToken == null){
    this.setState({ typeuser: null})
    document.location.href = '/login';
    return;
  }
  const isMyTokenExpired = isExpired(token);

  if(isMyTokenExpired == true){
    this.setState({ typeuser: null})
    document.location.href = '/login';
    return;
  }

  token = JSON.stringify(myDecodedToken.uid).replace(/\"/g, "");

  fetch(this.state.wbslink + `addcredit`,{                  
        
    mode: 'cors',     
    method:'post',
    headers: {'Authorization': 'Bearer '+ token},
    body: formData

    })

  .then(res => {

    errocode = res.status;

    if (!res.ok) {
        
        return res.json().then(json => { throw json; });

    }

    return res.json();

  })
  .then(data => {

    if(this.state.lng === "FR"){

      alert('Le crédit est bien ajouté.');

    }else{

      alert('Credit is added.' );

    }

  if(data.length > 0){

    listdata.length = 0;

    this.setState({ listuser: data, listuserinit: data,listuserpage:data,loadershow: false})

    data.forEach((item)=>{
      listdata.push({ idutilisateur:item.idutilisateur,nomutilisateur:item.nomutilisateur,prenomsutilisateur:item.prenomsutilisateur,mailutilisateur : item.email , motdepasseutilisateur	:item.motdepasseutilisateur	,idsocieteutilisateur:item.idsocieteutilisateur,idcentreutilisateur:item.idcentreutilisateur,	telephoneutilisateur:item.telephoneutilisateur,posteutilisateur:item.posteutilisateur, typeutilisateur:item.typeutilisateur, active: item.active, creditactuel:item.creditactuel, creditachete:item.creditachete, creditconsomme:item.creditconsomme,	langue:item.langue,lastconnected:item.lastconnected,nomtypeutilisateur:item.nomtypeutilisateur,nomsociete:item.nomsociete,validateuser:item.validateuser});
    })
  
    var slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
    
    this.setState({
        //pageCount: Math.ceil(data.length / this.state.perPage),
        listuserinit :data,
        listuser:slice
    })

    this.setState({
      nom: '',
      prenoms: '',
      usertype: 0,
      activeuserupdate: 0,
      nombrecredit:0,
      errorform: false,
      errormessage: '',
      showpassword : "password",
      motdepasse:'',
      modalcredit: false
    })

    if(this.state.lng === "FR"){
      document.title = "Liste des utilisateurs";
    }else{
      document.title = "Users list";
    }    
    
  }

})
.catch(err => {
     
  if(errocode == 401 || errocode == 0){
    
    sessionStorage.removeItem("tokenunsaid");
    document.location.href = "/login";

    return

  }

  alert("Erreur :" + errocode + ", "+JSON.stringify(err.message).replace(/\"/g, ""))

  this.setState({
    loadershow: false
  })

});  

}

showdeleteuser(nom,prenoms,type,mailutilisateur){
  
  this.setState({
    nom: nom,
    prenoms: prenoms,
    usertype : type,
    mailutilisateur:mailutilisateur,
    modaldelete: true
  })

}

deleteuser(){

  // Create an object of formData 
  const formData = new FormData(); 

  formData.append("mailutilisateur",this.state.mailutilisateur);

  let token = sessionStorage.getItem('tokenunsaid');

  const myDecodedToken = decodeToken(token);

  if(myDecodedToken == null){
    this.setState({ typeuser: null})
    document.location.href = '/login';
    return;
  }
  const isMyTokenExpired = isExpired(token);

  if(isMyTokenExpired == true){
    this.setState({ typeuser: null})
    document.location.href = '/login';
    return;
  }

  token = JSON.stringify(myDecodedToken.uid).replace(/\"/g, "");

  fetch(this.state.wbslink + `deleteuser`,{                  
        
    mode: 'cors',     
    method:'post',
    headers: {'Authorization': 'Bearer '+ token},
    body: formData

    })

  .then(res => {

    errocode = res.status;

    if (!res.ok) {
        
        return res.json().then(json => { throw json; });

    }

    return res.json();

  })
  .then(data => {

  if(data.length > 0){

    listdata.length = 0;

    this.setState({ listuser: data, listuserinit: data,listuserpage:data,loadershow: false})

    data.forEach((item)=>{
      listdata.push({ idutilisateur:item.idutilisateur,nomutilisateur:item.nomutilisateur,prenomsutilisateur:item.prenomsutilisateur,mailutilisateur : item.email , motdepasseutilisateur	:item.motdepasseutilisateur	,idsocieteutilisateur:item.idsocieteutilisateur,idcentreutilisateur:item.idcentreutilisateur,	telephoneutilisateur:item.telephoneutilisateur,posteutilisateur:item.posteutilisateur, typeutilisateur:item.typeutilisateur, active: item.active, creditactuel:item.creditactuel, creditachete:item.creditachete, creditconsomme:item.creditconsomme,	langue:item.langue,lastconnected:item.lastconnected,nomtypeutilisateur:item.nomtypeutilisateur,nomsociete:item.nomsociete,validateuser:item.validateuser});
    })
  
    var slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
    
    this.setState({
        pageCount: Math.ceil(data.length / this.state.perPage),
        listuserinit :data,
        listuser:slice
    })

    this.setState({
      iduserupdate: 0,
      nom: '',
      prenoms: '',
      usertype: 0,
      activeuserupdate: 0,
      nombrecredit:0,
      errorform: false,
      errormessage: '',
      showpassword : "password",
      motdepasse:'',
      modaldelete: false
    })

    if(this.state.lng === "FR"){
      document.title = "Liste des utilisateurs";
    }else{
      document.title = "Users list";
    }    
    
  }

})
.catch(err => {
     
  if(errocode == 401 || errocode == 0){
    
    sessionStorage.removeItem("tokenunsaid");
    document.location.href = "/login";

    return

  }

  alert("Erreur :" + errocode + ", "+JSON.stringify(err.message).replace(/\"/g, ""))

  this.setState({
    loadershow: false
  })

});  

}

selectusertype(e) {
  this.setState({ usertype: e.target.value });
}

selectsociety(e) {
  this.setState({ societe: e.target.value });
}

selectsocietycentre(e) {
  this.setState({ societecentre: e.target.value });
}

selectlangue(e) {
  this.setState({ langue: e.target.value });
}



filterlist(e){

  
  this.setState({filterdata: e})

  if(e.lenght == 0){
    this.setState({listuser: this.state.listuserinit})
    return;
  }

  const filteredItems = this.state.listuserinit.filter((userlist) => {
    return (userlist.nomutilisateur).toLowerCase().includes(e.toLowerCase()) || (userlist.prenomsutilisateur).toLowerCase().includes(e.toLowerCase()) || (userlist.email).toLowerCase().includes(e.toLowerCase()) || (userlist.telephoneutilisateur).toLowerCase().includes(e.toLowerCase()) || (userlist.posteutilisateur).toLowerCase().includes(e.toLowerCase()) || (userlist.nomtypeutilisateur).toLowerCase().includes(e.toLowerCase()) || (userlist.langue).toLowerCase().includes(e.toLowerCase()) || (userlist.nomsociete).toLowerCase().includes(e.toLowerCase());
  });

  
  if(filteredItems.length>0){

    this.setState({listuser: filteredItems})

    var slice = filteredItems.slice(this.state.offset, this.state.offset + this.state.perPage)
          
          this.setState({
              pageCount: Math.ceil(filteredItems.length / this.state.perPage),
              listuser:slice,
              listuserpage:filteredItems,
          })

  }else{

    this.setState({listuser: [], listuserpage:[]})

  }
 
  
}

openadduser(){

  this.setState({
    isModaladd:true,
    nom:'',
    prenoms:'',
    societecentre:1,
    telephone:'',
    poste:'',
    usertype:1,
    taxe: 0.00,
    langue:'',
    mail:'',
  })

}

closeadduser(){

  this.setState({
    isModaladd:false,
    nom:'',
    prenoms:'',
    societecentre:1,
    telephone:'',
    poste:'',
    usertype:1,
    taxe: 0.00,
    langue:'',
    mail:'',
  })

}

adduser () {

  let lnguser = sessionStorage.getItem('langueuser');

  if (parseInt(this.state.usertype)  == 1) {

    if(lnguser === "FR"){
      this.setState({ errorform: true, errormessage: 'Type d\'utilisateur vide. Veuillez seléctionner un type d\'utilisateur.'  });
    }else{
      this.setState({ errorform: true, errormessage: 'User type not define' });
    }

  return;
    
  }

    if(parseInt(this.state.societe) == 1){

      if(this.state.lng === "FR"){
        this.setState({ errorform: true, errormessage: 'Veuillez séléctionner la société.' });
        return;
      }else{
        this.setState({ errorform: true, errormessage: 'Please select society.' });
      return;
      }
    
    }

    if (this.state.mail == "" ) {

      var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

      if (!pattern.test(this.state.mail)) {

        if(lnguser === "FR"){
          this.setState({ errorform: true, errormessage: 'Email invalide. Veuillez saisir une adresse e-mail valide s\'il vous plait.'  });
        }else{
          this.setState({ errorform: true, errormessage: 'Email not valid. Please put exact mail.' });
        }
          
        return;

      }
      
  }

    if (this.state.nom == "" && this.state.prenoms == "") {

      if(lnguser === "FR"){

        this.setState({ errorform: true, errormessage: 'Veuillez saisir le nom ou prénoms de l\'utilisateur.'  });

      }else{

        this.setState({ errorform: true, errormessage: 'Please put name or lastname of user.' });

      }
          
      return;

    }

    if (parseFloat(this.state.taxe) <= 0.00) {

      if(lnguser === "FR"){

        this.setState({ errorform: true, errormessage: 'Veuillez saisir la taxe de l\'utilisateur.'  });

      }else{

        this.setState({ errorform: true, errormessage: 'Please put taxe of user.' });

      }
          
      return;

    }

    if(this.state.langue == ""){

      if(this.state.lng === "FR"){
        this.setState({ errorform: true, errormessage: 'Veuillez séléctionner la langue.' });
        return;
      }else{
        this.setState({ errorform: true, errormessage: 'Please select langue.' });
      return;
      }
    
    } 



  // Create an object of formData 
  const formData = new FormData(); 

  formData.append("name",this.state.prenoms + " " + this.state.nom);
  formData.append("email",this.state.mail);
  formData.append("nomutilisateur",this.state.nom);
  formData.append("prenomsutilisateur",this.state.prenoms);
  formData.append("idsocieteutilisateur",parseInt(this.state.societe));
  formData.append("idcentreutilisateur",this.state.societecentre);
  formData.append("telephoneutilisateur",this.state.telephone);
  formData.append("posteutilisateur",this.state.poste);
  formData.append("typeutilisateur",this.state.usertype);
  formData.append("langue",this.state.langue);
  formData.append("taxe",this.state.taxe);
 
  let token = sessionStorage.getItem('tokenunsaid');

  const myDecodedToken = decodeToken(token);

  if(myDecodedToken == null){
    this.setState({ typeuser: null})
    document.location.href = '/login';
    return;
  }
  const isMyTokenExpired = isExpired(token);

  if(isMyTokenExpired == true){
    this.setState({ typeuser: null})
    document.location.href = '/login';
    return;
  }

  token = JSON.stringify(myDecodedToken.uid).replace(/\"/g, "");

  fetch(this.state.wbslink + `register`,{                  
        
    mode: 'cors',     
    method:'post',
    headers: {'Authorization': 'Bearer '+ token},
    body: formData

    })

    .then(res => {

      errocode = res.status;

      if (!res.ok) {
          
          return res.json().then(json => { throw json; });

      }

      return res.json();

    })
  .then(data => {

  if(data.length > 0){

    listdata.length = 0;

    this.setState({ listuser: data, listuserinit: data,listuserpage:data,loadershow: false})

    data.forEach((item)=>{
      listdata.push({ idutilisateur:item.idutilisateur,nomutilisateur:item.nomutilisateur,prenomsutilisateur:item.prenomsutilisateur,mailutilisateur : item.email , motdepasseutilisateur	:item.motdepasseutilisateur	,idsocieteutilisateur:item.idsocieteutilisateur,idcentreutilisateur:item.idcentreutilisateur,	telephoneutilisateur:item.telephoneutilisateur,posteutilisateur:item.posteutilisateur, typeutilisateur:item.typeutilisateur, active: item.active, creditactuel:item.creditactuel, creditachete:item.creditachete, creditconsomme:item.creditconsomme,	langue:item.langue,lastconnected:item.lastconnected,nomtypeutilisateur:item.nomtypeutilisateur,nomsociete:item.nomsociete,validateuser:item.validateuser});
    })
  
    var slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
    
    this.setState({
        pageCount: Math.ceil(data.length / this.state.perPage),
        listuserinit :data,
        listuser:slice
    })

    this.setState({
      isModaladd:false,
      nom:'',
      prenoms:'',
      societecentre:1,
      telephone:'',
      poste:'',
      usertype:1,
      langue:'',
      mail:'',
    })

    if(this.state.lng === "FR"){

      alert("Utilisatueur ajouté avec succès.")

    }else{

      alert("User added successfuly.")
      
    }

  }else{

    this.setState({ listdata : [], question:''});

  }

})

.catch(err => {

if(errocode == 401 || errocode == 0){
  
  sessionStorage.removeItem("tokenunsaid");
  document.location.href = "/login";

  return

}

alert("Erreur :" + errocode + ", "+JSON.stringify(err.message).replace(/\"/g, ""))

this.setState({
  loadershow: false
})

});  

};

otpchange = (otp) => this.setState({ otp });
    
  render() {

          return (


                <div>

                  {this.state.loadingdata == true?

                    null
                  
                    :
                    
                    <div className="App" style={{marginTop:'80px'}}>
                  
                    {this.state.tokenvalue === '' ?
                              
  
                              <Redirect push to="/Login"/>
                                
  
                              :
  
                              null
  
                      }
  
                      {this.state.isModaladd === true ?
  
                      <Modal
  
                      show={this.state.isModaladd}
                      onHide={ () => this.closeadduser()}
                      dialogClassName="modal-dialog"
                      aria-labelledby="example-custom-modal-styling-title" centered
                      >
                        <Modal.Body>
                                          
                          <div className="d-flex flex-column justify-content-center" >
  
                            <div className="form-group" style={{margin:'5px'}}>
         
                              <label htmlFor="name">{this.state.lng ==="FR" ? 'Type *' : 'Type *'}</label>
                              <div>
                                <div>
                                  <Form.Select style={{height:'30px', paddingTop:'0px', paddingLeft:'10px' }} value={this.state.usertype} onChange={this.selectusertype}>
                                    {this.state.listtypeuser.map((typ) => (
                                      <option value={typ.idtypeutilisateur}>{typ.nomtypeutilisateur}</option>
                                    ))}
                                  </Form.Select>
                                </div>
                              </div>
  
                              {this.state.admin === 'yes'?
  
                                <div>
                                  <label>{this.state.lng ==='FR'? 'Société *': 'Society *'}</label>
                                  <div>
                                    <div>
                                      <Form.Select style={{height:'30px', paddingTop:'0px', paddingLeft:'10px' }} value={this.state.societe} onChange={this.selectsociety}>
                                        {this.state.listsociete.map((soc) => (
                                          <option value={soc.idsociete}>{soc.nomsociete}</option>
                                        ))}
                                      </Form.Select>
                                    </div>
                                  </div>
                                </div>
                                
                                :
  
                                null
  
                              }
  
                              <label>Email *</label>
                              <input type="email" className="form-control" aria-describedby="emailHelp" value={this.state.mail} onChange={(e) => this.setState({ mail: e.target.value})} />
           
                            </div>
  
                            <div className="form-group" style={{margin:'5px'}}>
                              <label>{this.state.lng ==="FR" ? 'Nom' : 'Last name'}</label>
                              <input type="text" className="form-control" value={this.state.nom} onChange={(e) => this.setState({ nom: e.target.value})} />
                            </div>
  
                            <div className="form-group" style={{margin:'5px'}}>
                              <label>{this.state.lng ==="FR" ? 'Prénom(s)' : 'First name'}</label>
                              <input type="text" className="form-control" value={this.state.prenoms} onChange={(e) => this.setState({ prenoms: e.target.value})} />
                            </div>
                       
                            <div className="form-group" style={{margin:'5px'}}>
                              <label>{this.state.lng ==="FR" ? 'Téléphone' : 'Phone number'}</label>
                              <input type="text" className="form-control" value={this.state.telephone} onChange={(e) => this.setState({ telephone: e.target.value})} />
                            </div>
  
  
                            <div className="form-group" style={{margin:'5px'}}>
                              <label>{this.state.lng ==="FR" ? 'Poste' : 'Job'}</label>
                              <input type="email" className="form-control" value={this.state.poste} onChange={(e) => this.setState({ poste: e.target.value})} />
                            </div>
  
                            <div className="form-group" style={{margin:'5px'}}>
                              <label>{this.state.lng ==="FR" ? 'Taxe *' : 'Taxe *'}</label>
                              <input type="number" className="form-control" value={this.state.taxe} onChange={(e) => this.setState({ taxe: e.target.value})} />
                            </div>
  
                            <div className="form-group" style={{margin:'5px'}}>
                              <label>{this.state.lng ==='FR'? 'Langue *' : 'Langue *'}</label>
                                <div>
                                  <div>
                                    <Form.Select style={{height:'30px', paddingTop:'0px', paddingLeft:'10px' }} value={this.state.langue} onChange={this.selectlangue}>
                                      {langueuser.map((lnguser) => (
                                        <option value={lnguser.value}>{lnguser.name}</option>
                                      ))}
                                    </Form.Select>
                                  </div>
                                </div>
                            </div>
  
                            {this.state.errorform == true ? 
  
                              <Alert severity="error">
                                <AlertTitle>Erreur</AlertTitle>
                                {this.state.errormessage}
                              </Alert>
  
  
  
                              :
  
                              null
  
                            }
  
                            <div className='row text-white d-flex justify-content-center'>
                                                
                                <Button className="btn"style={{ margin:"20px", width:'100px'}} onClick={ () => this.closeadduser()} variant="primary">{this.state.lng ==='FR'? 'Fermer': 'Close'}</Button>
                                <Button className="btn" style={{ margin:"20px",right: 0, width:'100px'}} onClick={() => this.adduser()} variant="success">{this.state.lng ==='FR'? 'Ajouter': 'Add'}</Button>
  
                            </div>
   
                          </div>
  
                      </Modal.Body>
                      </Modal>
  
                      :
  
                      null
  
                      }


                      {this.state.modalvalidate === true ?
                        
                        <Modal
                        
                        show={this.state.modalvalidate}
                        onHide={ () => this.setState({modalvalidate:false})}
                        dialogClassName="modal-dialog"
                        aria-labelledby="example-custom-modal-styling-title" centered
                        >
                          <Modal.Body>
                                      
                          <div className="d-flex flex-row justify-content-center">
                                            
                              <div className="d-flex flex-column" style={{margin:"5px"}}>
                                  
                                  <div className="d-flex flex-column">
                                  <p style={{fontWeight:"bold", textAlign:"center", marginTop:"20px"}}>{this.state.lng ==='FR'? 'Voulez-vous valider': 'Would you like to validate'} &nbsp;</p>
                                      <p style={{fontWeight:"bold", textAlign:"center", marginTop:"20px"}}>{this.state.nom}  {this.state.prenoms}?&nbsp;</p>
                                      <form>
                                      
                                      <div className='row text-white d-flex align-items-center'>
                                                  
                                            <Button className="btn"style={{ margin:"20px", width:'100px'}} onClick={ () => this.setState({modalvalidate:false})} variant="primary">{this.state.lng ==='FR'? 'Fermer': 'Close'}</Button>
                                            <Button className="btn" style={{ margin:"10px",right: 0, width:'120px'}} onClick={() => this.validateuser()} variant="success">{this.state.lng ==='FR'? 'Valider': 'Validate'}</Button>

                                      </div>
                                      
                                    </form>
                                  </div>
                                  
                              </div>

                          </div>    

                        </Modal.Body>
                        </Modal>

                        :

                        null

                        }  
  
                    
                    {this.state.modalupdate === true ?
  
                    <Modal
                    
                    show={this.state.modalupdate}
                    onHide={ () => this.setState({modalupdate:false})}
                    dialogClassName="modal-dialog"
                    aria-labelledby="example-custom-modal-styling-title" centered
                    >
                      <Modal.Body>
                                  
                      <div className="d-flex flex-row justify-content-center">
                                        
                          <div className="d-flex flex-column" style={{margin:"5px"}}>
                              
                              <div className="d-flex flex-column">
                                  <p style={{fontWeight:"bold", textAlign:"center", marginTop:"20px"}}>{this.state.nom}  {this.state.prenoms}&nbsp;</p>
                                  <form>
                                  
                                  <div className="form-group" style={{margin:'10px'}}>
                                    <label >{this.state.lng ==='FR'? 'Société': 'Society'}</label>
                                    <div>
                                      <Form.Select style={{height:'30px', paddingTop:'0px', paddingLeft:'10px' }} value={this.state.societe} onChange={this.selectsociety}>
                                        {this.state.listsociete.map((soc) => (
                                          <option value={soc.idsociete}>{soc.nomsociete}</option>
                                        ))}
                                      </Form.Select>
                                    </div>
                                  </div>
  
                                  <div className="form-group" style={{margin:'10px'}}>
                                    <label >{this.state.lng ==='FR'? 'Type d\'utilisateur': 'User type'}</label>
                                    <div>
                                      <Form.Select style={{height:'30px', paddingTop:'0px', paddingLeft:'10px' }} value={this.state.usertype} onChange={this.selectusertype}>
                                        {this.state.listtypeuser.map((typ) => (
                                          <option value={typ.idtypeutilisateur}>{typ.nomtypeutilisateur}</option>
                                        ))}
                                      </Form.Select>
                                    </div>
                                  </div>
  
                                  <div className="form-group" style={{margin:'5px'}}>
                                    <label>{this.state.lng ==="FR" ? 'Taxe' : 'Taxe'}</label>
                                    <input type="number" className="form-control" value={this.state.taxe} onChange={(e) => this.setState({ taxe: e.target.value})} />
                                  </div>
                                  
                                  <div className="form-group" style={{margin:'10px'}}>
                                    <Form.Group>
                                      <Form.Check type={"checkbox"}>
                                        <Form.Check.Label>Active</Form.Check.Label>
                                          <Form.Check.Input
                                            type={"checkbox"}
                                            defaultChecked={parseInt(this.state.activeuserupdate)}
                                            onClick={(e) => {
                                              this.setState({ activeuserupdate: e.target.checked });
                                            }}
                                          />
                                      </Form.Check>
                                    </Form.Group>
                                  </div>
  
                                  <div className='row text-white d-flex align-items-center'>
                                              
                                        <Button className="btn"style={{ margin:"20px", width:'100px'}} onClick={ () => this.setState({modalupdate:false})} variant="primary">{this.state.lng ==='FR'? 'Fermer': 'Close'}</Button>
                                        <Button className="btn" style={{ margin:"10px",right: 0, width:'120px'}} onClick={() => this.updateuser()} variant="success">{this.state.lng ==='FR'? 'Mettre à jour': 'Update'}</Button>
  
                                  </div>
                                  
                                </form>
                              </div>
                              
                          </div>
  
                      </div>    
  
                    </Modal.Body>
                    </Modal>
  
                    :
  
                    null
  
                    }
  
                    {this.state.modalcredit === true ?
  
                    <Modal
  
                    show={this.state.modalcredit}
                    onHide={ () => this.setState({modalcredit:false, otp:'', nombrecredit:0})}
                    dialogClassName="modal-dialog"
                    aria-labelledby="example-custom-modal-styling-title" centered
                    >
                      <Modal.Body>
                                  
                      <div className="d-flex flex-row justify-content-center">
                                        
                          <div className="d-flex flex-column" style={{margin:"20px"}}>
                              
                              <div className="d-flex flex-column">
                                  <p style={{fontWeight:"bold", textAlign:"center", marginTop:"20px"}}>{this.state.nom}  {this.state.prenoms}&nbsp;</p>
                                  <form>
                                  
                                  <div className="form-group" style={{margin:'5px'}}>
                                    <label>{this.state.lng ==="FR" ? 'Crédit à ajouter' : 'Credit to add'}</label>
                                    <input type="number" className="form-control" value={this.state.nombrecredit} onChange={(e) => this.setState({ nombrecredit: e.target.value})} />
                                  </div>
  
                                  <label style={{margin:'5px', textAlign:"center"}}>{this.state.lng ==="FR" ? 'Code OTP' : 'OTP CODE'} </label>
                                    <div class="container">
                                      <div class="row">
                                        <div className="col-10 ">
                              
                                        <OtpInput
                                          value={this.state.otp}
                                          onChange={this.otpchange}
                                          numInputs={6}
                                          shouldAutoFocus={true}
                                          inputStyle={{
                                            margin:"5px",
                                            border: "1px solid #2A83E8",
                                            borderRadius: "8px",
                                            width: "54px",
                                            height: "54px",
                                            fontSize: "30px",
                                            color: "#000",
                                            fontWeight: "400",
                                            caretColor: "#2A83E8"
                                          }}
                                          focusStyle={{
                                            border: "1px solid #CFD3DB",
                                            outline: "none"
                                          }}
                                          renderSeparator={<span> - </span>}
                                          renderInput={(props) => <input {...props} />}
                                        />
                                        </div>
                                       
                                      </div>
                                    </div>
  
                                  {this.state.errorform == true ? 
                          
                                    <Alert severity="error">
                                      <AlertTitle>{this.state.lng ==="FR" ? 'Erreur' : 'Error'}</AlertTitle>
                                      {this.state.errormessage}
                                    </Alert>
  
                                    
                                    :
  
                                    null
  
                                  }
  
                                  <div className='row text-white d-flex align-items-center justify-content-center'>
                                              
                                        <Button className="btn"style={{ margin:"20px", width:'100px'}} onClick={ () => this.setState({modalcredit:false, otp:'', nombrecredit:0})} variant="primary">{this.state.lng ==='FR'? 'Fermer': 'Close'}</Button>
                                        <Button className="btn" style={{ margin:"10px",right: 0, width:'100px'}} onClick={() => this.addcredituser()} variant="success">{this.state.lng ==='FR'? 'Ajouter': 'Add'}</Button>
  
                                  </div>
                                  
                                </form>
                              </div>
                              
                          </div>
  
                      </div>    
  
                    </Modal.Body>
                    </Modal>
  
                    :
  
                    null
  
                    }
  
                    {this.state.modaldelete === true ?
  
                    <Modal
  
                    show={this.state.modaldelete}
                    onHide={ () => this.setState({modaldelete:false})}
                    dialogClassName="modal-dialog"
                    aria-labelledby="example-custom-modal-styling-title" centered
                    >
                      <Modal.Body>
                                  
                      <div className="d-flex flex-row justify-content-center">
                                        
                          <div className="d-flex flex-column" style={{margin:"20px"}}>
                              
                              <div className="d-flex flex-column">
                                  <p style={{fontWeight:"bold", textAlign:"center", marginTop:"20px"}}>{this.state.lng ==='FR'? 'Voulez-vous supprimer': 'Would you like to delete'} &nbsp;</p>
                                  <p style={{fontWeight:"bold", textAlign:"center", marginTop:"20px"}}>{this.state.nom}  {this.state.prenoms} ?&nbsp;</p>
                                  <form>
  
                                  <div className='row text-white d-flex align-items-center'>
                                              
                                        <Button className="btn"style={{ margin:"20px", width:'100px'}} onClick={ () => this.setState({modaldelete:false})} variant="primary">{this.state.lng ==='FR'? 'Fermer': 'Close'}</Button>
                                        <Button className="btn" style={{ margin:"10px",right: 0, width:'100px'}} onClick={() => this.deleteuser()} variant="danger">{this.state.lng ==='FR'? 'Supprimer': 'Delete'}</Button>
  
                                  </div>
                                  
                                </form>
                              </div>
                              
                          </div>
  
                      </div>    
  
                    </Modal.Body>
                    </Modal>
  
                    :
  
                    null
  
                    }

                    <Button  style={{ width:'150px',height:"50px", marginTop:"50px", marginTop: isBrowser?"0px":"50px", marginBottom:"30px",right:10}} onClick={() => this.openadduser()} variant="success"><span><AddIcon /></span>{this.state.lng ==="FR" ? 'Ajouter' : 'Add'}</Button>

                    <div className='centrervue' >
  
                    <input className="inputfind" style={{marginTop: isBrowser?"0px":"50px"}} type="text" name="search" placeholder={this.state.lng ==='FR'? 'Rechercher': 'Search'} value={this.state.filterdata} onChange={(e) => this.filterlist(e.target.value)}/>
           
                    <BrowserView>
                     
                        <Table>
                          <Thead>
                            <Tr>
                              
                              <Th >{this.state.lng ==='FR'? 'Nom': 'Name'}</Th>
                              <Th >{this.state.lng ==='FR'? 'Prénoms': 'First name'}</Th>
                              <Th >{this.state.lng ==='FR'? 'Email': 'Email'}</Th>
                              <Th >{this.state.lng ==='FR'? 'Société': 'Society'}</Th>
                              <Th >{this.state.lng ==='FR'? 'Type': 'Type'}</Th>
                              <Th >{this.state.lng ==='FR'? 'Taxe': 'Taxe'}</Th>
                              <Th >{this.state.lng ==='FR'? 'Crédit acheté': 'Credit buy'}</Th>
                              <Th >{this.state.lng ==='FR'? 'Crédit consommé': 'Credit used'}</Th>
                              <Th >{this.state.lng ==='FR'? 'Crédit restant': 'Credit remaining'}</Th>
                              <Th >{this.state.lng ==='FR'? 'Téléphone': 'Phone'}</Th>
                              <Th >{this.state.lng ==='FR'? 'Poste': 'Job'}</Th>
                              <Th >{this.state.lng ==='FR'? 'Connecté le': 'Last connected'}</Th>
                              <Th >{this.state.lng ==='FR'? 'Ajouter crédit': 'Add credit'}</Th>
                              <Th >{this.state.lng ==='FR'? 'Mettre à jour': 'Update'}</Th>
                              {this.state.idtype !== '1' && this.state.idtype !== '4' ?
  
                                  <Th >{this.state.lng ==='FR'? 'Supprimer': 'Delete'}</Th>
  
                                :
  
                                null
  
                              }
                              <Th >{this.state.lng ==='FR'? 'Valide': 'Valid'}</Th>
                            </Tr>
                          </Thead>
                          <Tbody >
                            {this.state.listuser.map((row) => (
                              <Tr key={row.email} style={{backgroundColor: parseInt(row.active) === 0 ? "#F5B041": "#f5fffa",color: parseInt(row.active) === '0' ? "white": "black"}} className="tableborder">
                                
                                <Td >{row.nomutilisateur}</Td>
                                <Td >{row.prenomsutilisateur}</Td>
                                <Td ><a style={{textAlign:'left', textDecoration:'none'}} href={"mailto:" + row.email}>{row.email}</a></Td>
                                <Td >{row.nomsociete}</Td>
                                <Td >{row.nomtypeutilisateur}</Td>
                                <Td style={{textAlign:'center'}}>{row.taxe}</Td>    
                                <Td style={{textAlign:'center'}}>{row.creditachete.toLocaleString()} €</Td>
                                <Td style={{textAlign:'center'}}>{row.creditconsomme.toLocaleString()} €</Td>
                                <Td style={{textAlign:'center'}}>{row.creditactuel.toLocaleString()} €</Td>
  
                                {/*
                                <Td style={{textAlign:'center'}}>{Math.floor(row.creditachete / (3600 * 24))}d {Math.floor((row.creditachete % (3600 * 24)) / 3600)}h {Math.floor((row.creditachete % 3600) / 60)}min {Math.round(((row.creditachete / 60)-Math.floor(row.creditachete / 60))*60)}s</Td>
                                <Td style={{textAlign:'center'}}>{Math.floor(row.creditconsomme / (3600 * 24))}d {Math.floor((row.creditconsomme % (3600 * 24)) / 3600)}h {Math.floor((row.creditconsomme % 3600) / 60)}min {Math.round(((row.creditconsomme / 60)-Math.floor(row.creditconsomme / 60))*60)}s</Td>
                                <Td style={{textAlign:'center'}}>{Math.floor(row.creditactuel / (3600 * 24))}d {Math.floor((row.creditactuel % (3600 * 24)) / 3600)}h {Math.floor((row.creditactuel % 3600) / 60)}min {Math.round(((row.creditactuel / 60)-Math.floor(row.creditactuel / 60))*60)}s</Td>
                                */}
                                
                                <Td ><a style={{textAlign:'left', textDecoration:'none'}} href={"tel:" + row.telephoneutilisateur}>{row.telephoneutilisateur}</a></Td>
                                <Td >{row.posteutilisateur}</Td>
                                <Td >{parseInt(new Date(Date.parse(row.lastconnected.replace(/[-]/g, '/'))).getDate()) < 10 ? <label>0{parseInt(new Date(Date.parse(row.lastconnected.replace(/[-]/g, '/'))).getDate())}</label>:parseInt(new Date(Date.parse(row.lastconnected.replace(/[-]/g, '/'))).getDate())}/{parseInt(new Date(Date.parse(row.lastconnected.replace(/[-]/g, '/'))).getMonth())+1 < 10 ? <label>0{parseInt(new Date(Date.parse(row.lastconnected.replace(/[-]/g, '/'))).getMonth())+1}</label>:parseInt(new Date(Date.parse(row.lastconnected.replace(/[-]/g, '/'))).getMonth())+1}/{new Date(Date.parse(row.lastconnected.replace(/[-]/g, '/'))).getFullYear()} {parseInt(new Date(Date.parse(row.lastconnected.replace(/[-]/g, '/'))).getHours()) < 10 ? <label>0{parseInt(new Date(Date.parse(row.lastconnected.replace(/[-]/g, '/'))).getHours())}</label>:parseInt(new Date(Date.parse(row.lastconnected.replace(/[-]/g, '/'))).getHours())}h:{parseInt(new Date(Date.parse(row.lastconnected.replace(/[-]/g, '/'))).getMinutes()) < 10 ? <label>0{parseInt(new Date(Date.parse(row.lastconnected.replace(/[-]/g, '/'))).getMinutes())}</label>:parseInt(new Date(Date.parse(row.lastconnected.replace(/[-]/g, '/'))).getMinutes())}m:{parseInt(new Date(Date.parse(row.lastconnected.replace(/[-]/g, '/'))).getSeconds()) < 10 ? <label>0{parseInt(new Date(Date.parse(row.lastconnected.replace(/[-]/g, '/'))).getSeconds())}</label>:parseInt(new Date(Date.parse(row.lastconnected.replace(/[-]/g, '/'))).getSeconds())}s</Td>
                                {row.idtypeutilisateur !== '2' ?
                                
                                <Td ><button className="btn btn-success btn-circle btn-xl"  onClick={() => this.showcredituser(row.nomutilisateur,row.prenomsutilisateur,row.email)}><span><ScheduleSendIcon /></span></button></Td>
                                  
                                  :
  
                                    null
  
                                }
                                
                                <Td ><button className="btn btn-primary btn-circle btn-xl" style={{margin:'10px'}} onClick={() => this.showupdateuser(row.typeutilisateur,row.idsocieteutilisateur,row.active,row.nomutilisateur,row.prenomsutilisateur,row.email,row.taxe)}><span><EditIcon /></span></button></Td>
                                {row.idtypeutilisateur !== '2' ?
                                
                                    <Td ><button className="btn btn-danger btn-circle btn-xl" onClick={() => this.showdeleteuser(row.nomutilisateur,row.prenomsutilisateur,row.typeutilisateur,row.email)}><span><DeleteIcon /></span></button></Td>
                                  
                                  :
  
                                    null
  
                                }

                                {parseInt(row.validateuser) == 0 ?

                                  <div className="contentcenter">
                                  
                                    <button className="btn btn-transparent btn-circle btn-xl" style={{margin:'10px'}} onClick={() => this.showvalidateuser(row.nomutilisateur,row.prenomsutilisateur,row.email)}><span style={{color:'#162938'}} ><PublishedWithChangesIcon /></span></button>

                                  </div>

                                  :

                                  <div className="contentcenter">
                                  
                                    <button className="btn btn-transparent btn-circle btn-xl" style={{margin:'10px'}} ><span style={{color:'#2A83E8'}} ><CheckIcon /></span></button>

                                  </div>
                                  
                                }
                    
                              </Tr>
                            ))}
                          </Tbody>
                        </Table>
  
                        {this.state.listuser.length>0?
  
                        <ReactPaginate
                        previousLabel={"<< prev"}
                        previousClassName={"prevClass"}
                        previousLinkClassName={"prevClass"}
                        nextLabel={"next >>"}
                        nextClassName={"next"}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={this.state.pageCount}
                        marginPagesDisplayed={3}
                        pageRangeDisplayed={3}
                        onPageChange={this.handlePageClick}
                        containerClassName={"pagination"}
                        subContainerClassName={"pages pagination"}
                        activeClassName={"active"}/>
  
                        :
  
                        null
                        }
                    
  
                    </BrowserView>
  
                    <MobileView>
  
                      <div className='paginationmobile' style={{marginTop:"-5%"}}>
  
                      {this.state.listuser.length>0?
  
                        <ReactPaginate
                        previousLabel={"<<"}
                        previousClassName={"prevClass"}
                        previousLinkClassName={"prevClass"}
                        nextLabel={">>"}
                        nextClassName={"next"}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={this.state.pageCount}
                        marginPagesDisplayed={3}
                        pageRangeDisplayed={3}
                        onPageChange={this.handlePageClick}
                        containerClassName={"pagination"}
                        subContainerClassName={"pages pagination"}
                        activeClassName={"active"}/>
  
                        :
  
                        null
                        
                      }
  
                      </div>
  
                      <div  style={{marginTop:"5%"}}>
                          <p style={{color:"transparent"}}>.</p>
                      </div>
  
                      <div style={{marginTop:"40px"}}>
  
                      {this.state.listuser.map((row) => (
  
                          <Card style={{ width: '90%', margin:"5%"}}>
                          
                          <Card.Body  key={row.email} style={{backgroundColor: parseInt(row.active) === '0' ? "#F5B041": "#f5fffa"}}>
                            <Card.Title style={{color:parseInt(row.active) === '0' ? "white":"black"}}>{row.nomutilisateur} {row.prenomsutilisateur}</Card.Title>
                            <Card.Text>
                                <a style={{textAlign:'left', textDecoration:'none'}} href={"mailto:" + row.email}>{row.email}</a>
                                <p style={{color:row.active === '0' ? "white":"black", marginTop:"10px"}}>{row.nomsociete}</p>
                                <p style={{color:row.active === '0' ? "white":"black"}}>{row.nomtypeutilisateur}</p>
                                <p style={{color:row.active === '0' ? "white":"black"}}>{row.taxe}</p>
                                <p >{parseInt(new Date(Date.parse(row.lastconnected.replace(/[-]/g, '/'))).getDate()) < 10 ? <label>0{parseInt(new Date(Date.parse(row.lastconnected.replace(/[-]/g, '/'))).getDate())}</label>:parseInt(new Date(Date.parse(row.lastconnected.replace(/[-]/g, '/'))).getDate())}/{parseInt(new Date(Date.parse(row.lastconnected.replace(/[-]/g, '/'))).getMonth())+1 < 10 ? <label>0{parseInt(new Date(Date.parse(row.lastconnected.replace(/[-]/g, '/'))).getMonth())+1}</label>:parseInt(new Date(Date.parse(row.lastconnected.replace(/[-]/g, '/'))).getMonth())+1}/{new Date(Date.parse(row.lastconnected.replace(/[-]/g, '/'))).getFullYear()} {parseInt(new Date(Date.parse(row.lastconnected.replace(/[-]/g, '/'))).getHours()) < 10 ? <label>0{parseInt(new Date(Date.parse(row.lastconnected.replace(/[-]/g, '/'))).getHours())}</label>:parseInt(new Date(Date.parse(row.lastconnected.replace(/[-]/g, '/'))).getHours())}h:{parseInt(new Date(Date.parse(row.lastconnected.replace(/[-]/g, '/'))).getMinutes()) < 10 ? <label>0{parseInt(new Date(Date.parse(row.lastconnected.replace(/[-]/g, '/'))).getMinutes())}</label>:parseInt(new Date(Date.parse(row.lastconnected.replace(/[-]/g, '/'))).getMinutes())}m:{parseInt(new Date(Date.parse(row.lastconnected.replace(/[-]/g, '/'))).getSeconds()) < 10 ? <label>0{parseInt(new Date(Date.parse(row.lastconnected.replace(/[-]/g, '/'))).getSeconds())}</label>:parseInt(new Date(Date.parse(row.lastconnected.replace(/[-]/g, '/'))).getSeconds())}s</p>
                                <a style={{textAlign:'left', textDecoration:'none'}} href={"tel:" + row.telephoneutilisateur}>{row.telephoneutilisateur}</a>
                                <p style={{color:row.active === '0' ? "white":"black", marginTop:"10px"}}>{row.posteutilisateur}</p>
                                <p style={{color:row.active === '0' ? "white":"black"}}>{row.creditactuel.toLocaleString()} €</p>
                                {/*<p style={{color:row.active === '0' ? "white":"black"}}>{Math.floor(row.creditactuel / (3600 * 24))}d {Math.floor((row.creditactuel % (3600 * 24)) / 3600)}h {Math.floor((row.creditactuel % 3600) / 60)}min {Math.round(((row.creditactuel / 60)-Math.floor(row.creditactuel / 60))*60)}s</p>*/}
                                
                            </Card.Text>
                              <div className='d-flex flex-row align-items-center justify-content-center'>
                                              
                                <button type="submit" className="btn btn-success btn-circle btn-xl" style={{margin:'10px'}} onClick={() => this.showcredituser(row.nomutilisateur,row.prenomsutilisateur,row.email)}><span><ScheduleSendIcon /></span></button>
                                <button type="submit" className="btn btn-primary btn-circle btn-xl" style={{margin:'10px'}} onClick={() => this.showupdateuser(row.typeutilisateur,row.idsocieteutilisateur,row.active,row.nomutilisateur,row.prenomsutilisateur,row.email,row.taxe)}><span><EditIcon /></span></button>
                                <button type="submit" className="btn btn-danger btn-circle btn-xl" style={{margin:'10px'}} onClick={() => this.showdeleteuser(row.nomutilisateur,row.prenomsutilisateur,row.typeutilisateur,row.email)}><span><DeleteIcon/></span></button>
  
                              </div>
                          </Card.Body>
                          </Card>
                      
                      ))}
  
                      </div>
  
                    </MobileView>
                    
                    </div> 
                 
                  </div>


                  }

                </div>

               
                
          );
        }
    
}

export default Utilisateurs;
