import React,  {Alert,Component} from 'react';
import {useEffect,useRef} from 'react';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import Carousel from 'react-bootstrap/Carousel';
import TextField from '@material-ui/core/TextField';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import PersonIcon from '@material-ui/icons/Person';
import SearchIcon from '@material-ui/icons/Search';
import SendIcon from '@material-ui/icons/Send';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import ForumIcon from '@mui/icons-material/Forum';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import BarChartIcon from '@mui/icons-material/BarChart';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from '@material-ui/core/styles';
import { green, purple,white, red, blue } from '@material-ui/core/colors';
import Autocomplete from '@material-ui/lab/Autocomplete';
import queryString from 'query-string';
//import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import ReadMoreIcon from '@mui/icons-material/ReadMore';
import AssignmentIcon from '@mui/icons-material/Assignment';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import './moncss.css';
import { Nav,Navbar,Button,Form,Row,Modal,Col,Container} from 'react-bootstrap';
import ReactAudioPlayer from 'react-audio-player';
import {  BarChart, Bar,LineChart, Line,Label, LabelList, PieChart, Pie, Sector, Cell, AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend,ResponsiveContainer } from "recharts";
import ReactToPrint from 'react-to-print';
import { useReactToPrint } from 'react-to-print';
import Loader from "react-js-loader";
import logo from './logotransparent.png';
import Card from 'react-bootstrap/Card'
import { BrowserView, MobileView, isBrowser, isMobile,isIE, isSafari} from 'react-device-detect';
import AudioPlayer, { RHAP_UI } from 'react-h5-audio-player'
import 'react-h5-audio-player/lib/styles.css';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table'
import SpeakerNotesIcon from '@mui/icons-material/SpeakerNotes';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import SendAndArchiveIcon from '@mui/icons-material/SendAndArchive';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import SaveAsIcon from '@mui/icons-material/SaveAs';
import CancelIcon from '@mui/icons-material/Cancel';
import { ThreeSixty } from '@material-ui/icons';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import ReactPaginate from 'react-paginate';
import { isExpired, decodeToken }from "react-jwt";
const key = "MIICXAIBAAKBgQCiQ5lLUPoLiI3VI69VfZ32tWjsL6HvqzYEtUbxutptHb3PYhKcbqirJ2cADUyWBWpfWgAQyshSciov5PhskWF5wRyhxc0WOLuK72icFqOu2ZLE2TvRvAzjNT2TaBHBeeJ2t39u6pPBz9ejZuXc05AapG2Jh7HfCORkTsCIhwGydwIDAQABAoGAT1wYzMeF/RJuQV85mWcG9w8NKs53y68yxDoQ0ZBNaKCztaGSFwR5UzhZZsn"
const CryptoJS = require("crypto-js");

var listdata=[];

const data = [
  { name: 'Positive', value: 644 },
  { name: 'Neutral', value: 254 },
  { name: 'Negative', value: 103 },
];

const COLORS = ['#198754', '#333333', '#dc3545'];

const COLORSCHART = ['#00934c', '#e89900', '#e40001'];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 2;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill="black" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
      {`${(percent * 100).toFixed(2)}%`}
    </text>
  );
};

var errocode = 0;

class Trash extends Component{
    
    constructor(props) {
    super(props);
      this.state = {
  
        selectedFile: null,
        soundfilename:null,
        dureesound:'',
        selectedOption: null,
        listsound: [],
        listsoundinit: [],
        listlng: [],
        persons: [],
        speakers: [],
        speaker1: [],
        speaker1sentiment: [],
        speaker2: [],
        speaker2sentiment: [],
        speakernegatif: 0.00,
        speakerneutre: 0.00,
        speakerpositif: 0.00,
        selectedValue: "",
        diarizer: [],
        nom: '',
        prenoms: '',
        mail: '',
        tokenuser:[],
        isModalthematique: false,
        isModalspeaker: false,
        isModalupdate: false,
        isModaladd: false,
        isModaldelete:false,
        isModalrestore:false,
        idaudioselect: 0,
        idaudioselectprint: 0,
        thematique:'',
        show_input:'text',
        tokenexist: true,
        tokenvalue: '',
        username: '',
        soundlink:'',
        soundname:'',
        soundlng:'',
        summarization:'',
        speaker1name:'',
        speaker2name:'',
        loadershow: true,
        textloading:'',
        lng: '',
        isModaldetails: false,
        isModaltranscription: false,
        wbslink:'',
        comment:'',
        commentbutton:false,
        speakerbutton:false,
        thematiquebutton:false,
        printbutton:false,
        filterdata:'',
        offset: 0,
        listsoundpage: [],
        perPage: 5,
        currentPage: 0,
        loadingdata: true,
      };

      this.selectlng = this.selectlng.bind(this);
      this.handlePageClick = this.handlePageClick.bind(this);
        
  }

  handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    this.setState({
        currentPage: selectedPage,
        offset: offset
    }, () => {
        this.loadMoreData(this.state.listsoundpage)
    });

};

  loadMoreData(data) {

  const slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
  this.setState({
    pageCount: Math.ceil(data.length / this.state.perPage),
    listsound:slice
  })

  window.scrollTo(0, 0)

  }

  loadpage(slice){
    this.setState({
      listsound:slice
      
    })
    
  }

  filterlist(e){

  
    this.setState({filterdata: e})
  
    if(e.lenght == 0){
      this.setState({listsound: this.state.listsoundinit})
      return;
    }
  
    const filteredItems = this.state.listsoundinit.filter((soundlist) => {
      return (soundlist.nomaudio).toLowerCase().includes(e.toLowerCase()) || (soundlist.comment).toLowerCase().includes(e.toLowerCase()) || (soundlist.langue).toLowerCase().includes(e.toLowerCase()) || (soundlist.speaker1).toLowerCase().includes(e.toLowerCase()) || (soundlist.speaker2).toLowerCase().includes(e.toLowerCase()) || (soundlist.summarization).toLowerCase().includes(e.toLowerCase()) || (soundlist.sentimentglobale).toLowerCase().includes(e.toLowerCase()) || (soundlist.texttranscript).toLowerCase().includes(e.toLowerCase());
    });
  
    
    if(filteredItems.length>0){
  
      this.setState({listsound: filteredItems})
  
      var slice = filteredItems.slice(this.state.offset, this.state.offset + this.state.perPage)
            
            this.setState({
                pageCount: Math.ceil(filteredItems.length / this.state.perPage),
                listsound:slice,
                listsoundpage:filteredItems,
            })
  
    }else{
  
      this.setState({listsound: [], listsoundpage:[]})
  
    }
   
    
  }

  componentWillMount() {

    sessionStorage.removeItem( 'iconify-count');
    sessionStorage.removeItem( 'iconify-version');

    let token = sessionStorage.getItem('tokenunsaid');

    const myDecodedToken = decodeToken(token);

    const datauser = JSON.stringify(myDecodedToken.init).replace(/\"/g, "");

    var bytes  = CryptoJS.AES.decrypt(datauser.toString().replace(/p1L2u3S/g, '+' ).replace(/p1L2u3S/g, '+' ).replace(/s1L2a3S4h/g, '/').replace(/e1Q2u3A4l/g, '=').toString(), key);
    var originaldata = bytes.toString(CryptoJS.enc.Utf8);
    var userdata  = originaldata.split('-');

    if(userdata[5] === "FR"){
      this.setState({ lng:'FR' })
    }else{
      this.setState({ lng:'EN'})
    }

    window.addEventListener("keydown", this.handleKeypress);

    let wbslinkload = sessionStorage.getItem('wbs');

    let tokencredit = JSON.stringify(myDecodedToken.uid).replace(/\"/g, "");
    
    fetch(wbslinkload + `getdatauser`,{                  
          
      mode: 'cors',     
      method:'post',
      headers: {'Authorization': 'Bearer '+ tokencredit},

      })

      .then(res => {

        errocode = res.status;
  
        if (!res.ok) {
            
            return res.json().then(json => { throw json; });
  
        }
  
        return res.json();
  
      })
      .then(data => {
        
        if(JSON.stringify(data.status).replace(/"/g, "") == "unsubscribed" && window.location.pathname != "/Buycredit"){

          document.location.href = "/Buycredit";

        }else{

          this.setState({
            loadingdata:false
          })
          
        }
        
    })

    this.setState({ wbslink:wbslinkload})

    if(userdata[5] === "FR"){
      this.setState({

        textloading:'Chargement en cours ...',
      
      });
    }else{
      this.setState({

        textloading:'Loading data ...',
      
      });
    }

    if(token === '' || token === 'Undefined'){
      this.setState({ tokenexist: false})
      return
    }else{

      this.setState({ tokenvalue: token, lng:userdata[5]})

      this.reloaddata();
      
    }

    

  }

  reloaddata () {

    if(this.state.lng === "FR"){
      document.title = "Liste des audios en corbeille";
    }else{
      document.title = "Audio list in trash";
    }

    if(this.state.tokenvalue !== ''){

          //window.location.reload(false);
          this.setState({ reload: true})
          
    }

  }
  
  async componentDidMount() { 

    let idsc = parseInt(sessionStorage.getItem('idsociety'));
    let idtype = parseInt(sessionStorage.getItem('typeuser'));
    let iduserselect = parseInt(sessionStorage.getItem('iduser'));
    let lnguser = sessionStorage.getItem('langueuser');

    let token = sessionStorage.getItem('tokenunsaid');
    
    const myDecodedToken = decodeToken(token);

    if(myDecodedToken == null){
      this.setState({ typeuser: null})
      document.location.href = '/login';
      return;
    }
    const isMyTokenExpired = isExpired(token);

    if(isMyTokenExpired == true){
      this.setState({ typeuser: null})
      document.location.href = '/login';
      return;
    }

    token = JSON.stringify(myDecodedToken.uid).replace(/\"/g, ""); 

    const response = await fetch(this.state.wbslink + 'listtrashsound',{                       
      mode:'cors',
      method:'post',
      headers: {'accept': 'application/json','Authorization': 'Bearer '+ token},
      });
    const data = await response.json();

    if(JSON.stringify(data).replace(/"/g, "") !== '0'){
      this.setState({ listsound: data, listsoundinit: data,listsoundpage:data,loadershow: false})

      data.forEach((item)=>{
        listdata.push({ idaudio:item.linksound,idclient:item.idclient,idsociety:item.idsociety,nomaudio: item.nomaudio, duree:item.duree,langue:item.langue,iddiarizeur:item.iddiarizeur,dateheurediarization:item.dateheurediarization,speaker1:item.speaker1, speaker2:item.speaker2, summarization: item.summarization, thematique:item.thematique, comment:item.comment, sentimentglobale:item.sentimentglobale,sentimentpositif:item.sentimentpositif,sentimentneutre:item.sentimentneutre,sentimentnegatif:item.sentimentnegatif,dataextraction:item.dataextraction,texttranscript:item.texttranscript});
      })
    
      var slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
      
      this.setState({
          pageCount: Math.ceil(data.length / this.state.perPage),
          listsoundinit :data,
          listsound:slice
      })

    }else{
      this.setState({ loadershow: false})
    }

    if(this.state.lng === "FR"){
      document.title = "Liste des audios en corbeille";
    }else{
      document.title = "Audio list in trash";
    }

}

async refreshlistsound() { 

  let token = sessionStorage.getItem('tokenunsaid');
    
  const myDecodedToken = decodeToken(token);

  if(myDecodedToken == null){
    this.setState({ typeuser: null})
    document.location.href = '/login';
    return;
  }
  const isMyTokenExpired = isExpired(token);

  if(isMyTokenExpired == true){
    this.setState({ typeuser: null})
    document.location.href = '/login';
    return;
  }

  token = JSON.stringify(myDecodedToken.uid).replace(/\"/g, ""); 

  const response = await fetch(this.state.wbslink + 'listtrashsound',{                       
    mode:'cors',
    method:'post',
    headers: {'accept': 'application/json','Authorization': 'Bearer '+ token},
    });
  const data = await response.json();

  if(JSON.stringify(data).replace(/"/g, "") !== '0'){
    this.setState({ listsound: data, listsoundinit: data,listsoundpage:data,loadershow: false})

    data.forEach((item)=>{
      listdata.push({ idaudio:item.linksound,idclient:item.idclient,idsociety:item.idsociety,nomaudio: item.nomaudio, duree:item.duree,langue:item.langue,iddiarizeur:item.iddiarizeur,dateheurediarization:item.dateheurediarization,speaker1:item.speaker1, speaker2:item.speaker2, summarization: item.summarization, thematique:item.thematique, comment:item.comment, sentimentglobale:item.sentimentglobale,sentimentpositif:item.sentimentpositif,sentimentneutre:item.sentimentneutre,sentimentnegatif:item.sentimentnegatif,dataextraction:item.dataextraction,texttranscript:item.texttranscript});
    })
  
    var slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
    
    this.setState({
        pageCount: Math.ceil(data.length / this.state.perPage),
        listsoundinit :data,
        listsound:slice
    })

  }else{
    this.setState({ loadershow: false})
  }

  if(this.state.lng === "FR"){
    document.title = "Liste des audios en corbeille";
  }else{
    document.title = "Audio list in trash";
  }



    this.loaderdesactive();

}

    handleChange = selectedOption => {
      this.setState({ selectedOption });
      //alert(`Option selected:`, selectedOption);
    };

    onFileChange = event => { 

      // Update the state 
      this.setState({ selectedFile: event.target.files[0] }); 
      if(event.target.files[0] !== null){
        this.setState({
          loadershow: false,
          soundfilename : event.target.files[0].name,
        });
      }

    }; 
     
    // On file upload (click the upload button) 
    onFileUpload = () => { 

      if(this.state.selectedFile === null  && this.state.lng === "FR"){

          this.setState({
            loadershow: true,
            textloading:'Veuillez séléctionner un fichier audio.'
          });
        
        return;
      
      }

      if(this.state.selectedFile === null && this.state.lng !== "FR"){

        this.setState({
          loadershow: true,
          textloading:'Please select an audio file.'
        });
      
      return;
    
    }

      if(this.state.soundlng ==='' && this.state.lng === "FR"){

          this.setState({
            loadershow: true,
            textloading:'Veuillez séléctionner la language de l\'audio.'
          });
        
        return;

      }

      if(this.state.soundlng ==='' && this.state.lng !== "FR"){

          this.setState({
            loadershow: true,
            textloading:'Please select audio language.'
          });
        
        return;

      }

      if(this.state.lng === "FR"){
        this.setState({
          loadershow: true,
          textloading:'Chargement du fichier en cours ...'
        });
      }else{
        this.setState({
          loadershow: true,
          textloading:'Loading data file ...'
        });
      }

      let iduserupload = sessionStorage.getItem('iduser');
      let idusersociety = sessionStorage.getItem('idsociety');

      if(idusersociety === '' || idusersociety === 'undefined'){
        idusersociety = 0;
      }

      // Create an object of formData 
      const formData = new FormData(); 
     
      // Update the formData object 
      formData.append( 
        "sound", 
        this.state.selectedFile, 
        this.state.selectedFile.name,
      ); 

      formData.append("iduser",iduserupload);
      formData.append("idsoc",idusersociety);
      formData.append("lngsound",this.state.soundlng);
     
      // Details of the uploaded file 
     // console.log(this.state.selectedFile); 

     
      // Request made to the backend api 
      // Send formData object 
      axios.post(this.state.wbslink + "audio/addfile.php", formData)
      .then(res => {
    
        //alert(JSON.stringify(res.data));
        this.setState({ listsound : res.data});

        this.loaderdesactive();

    })

  }

  loaderdesactive(){

    this.setState({ loadershow: false, selectedFile:'', soundlng:'', isModaladd:false, isModaltranscription:false, idaudioselect:0,comment:""});

    if(this.state.lng === "FR"){
      document.title = "Liste des audios en corbeille";
    }else{
      document.title = "Audio list in trash";
    }
    
  }

  async refreshlist() {
    
      let idsc = parseInt(sessionStorage.getItem('idsociety'));
      let idtype = parseInt(sessionStorage.getItem('typeuser'));
      let iduserselect = parseInt(sessionStorage.getItem('iduser'));
      
      if(idsc>1 && idtype==3){

        const response = await fetch(this.state.wbslink + 'audio/listaudioidtrash.php',{                  
          method:'post',
          headers: {'Content-Type':'application/x-www-form-urlencoded'},
          body: queryString.stringify({
            idsoc:idsc
          })});

        const data = await response.json();

        if(data.lenght>0){
          this.setState({ listsound: data, loadershow: false})
        }else{
          this.setState({ loadershow: false})
        }

      }

      if(idsc>1 && idtype==4){

        const response = await fetch(this.state.wbslink + 'audio/listaudioidtrash.php',{                  
          method:'post',
          headers: {'Content-Type':'application/x-www-form-urlencoded'},
          body: queryString.stringify({
            idsoc:idsc
          })});

        const data = await response.json();

        if(data.lenght>0){
          this.setState({ listsound: data, loadershow: false})
        }else{
          this.setState({ loadershow: false})
        }

      }

      if(idsc==1 && idtype==4){

        const response = await fetch(this.state.wbslink + 'audio/listaudioidusertrash.php',{                  
          method:'post',
          headers: {'Content-Type':'application/x-www-form-urlencoded'},
          body: queryString.stringify({
            idusers:iduserselect
          })});

        const data = await response.json();

        if(data.lenght>0){
          this.setState({ listsound: data, loadershow: false})
        }else{
          this.setState({ loadershow: false})
        }

      }
      
      if(idtype==2){

        const response = await fetch(this.state.wbslink + 'audio/listaudiotrash.php',{                  
          method:'post',
          headers: {'Content-Type':'application/x-www-form-urlencoded'},
          body: queryString.stringify({
            idsoc:idsc
          })});

        const data = await response.json();

        if(data.lenght>0){
          this.setState({ listsound: data, loadershow: false})
        }else{
          this.setState({ loadershow: false})
        }
      
      }
      
    }; 

    fileData = () => { 
      
      if (this.state.selectedFile) { 

    var file = this.state.selectedFile;
    
    var reader = new FileReader();

    reader.onload = function (event) {

        var audioContext = new (window.AudioContext || window.webkitAudioContext)();

        audioContext.decodeAudioData(event.target.result, function(buffer) {
            var duration = parseInt(buffer.duration);

            var h = Math.floor(duration / 3600);
            var m = Math.floor(duration % 3600 / 60);
            var s = Math.floor(duration % 3600 % 60);
        
            var hDisplay = h > 0 ? h + (h == 1 ? " hour, " : " hours, ") : "";
            var mDisplay = m > 0 ? m + (m == 1 ? " minute, " : " minutes, ") : "";
            var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
            var dureetotal = "La durée de l'audio est de " + hDisplay + mDisplay + sDisplay + " secondes.";
            //this.setState({ dureesound: dureetotal });
            
            //alert(dureetotal);

        });
    };

    reader.onerror = function (event) {
        console.error("An error ocurred reading the file: ", event);
    };

    reader.readAsArrayBuffer(file);
          
        return ( 
          <div> 
            <p>Nom du fichier: {this.state.selectedFile.name}</p> 
          </div> 
        ); 
      } else { 
        return ( 
          <div> 
            <br /> 
            <h4>Veuillez choisir un fichier audio.</h4> 
          </div> 
        ); 
      } 
    }; 

    audiosend (nomaudio,nomsound,timesound,lgnsound) {
 
      let minuterestant = sessionStorage.getItem('creditrestant'); 
      let iduserdiarizeur = sessionStorage.getItem('iduser');  
     
      if(parseInt(timesound)>parseInt(minuterestant)){
        if(this.state.lng === "FR"){
          alert('Crédit insuffisant.');
          return;
        }else{
          alert('Credit not enough.');
          return;
        }
        
      }

      if(this.state.lng === "FR"){
        this.setState({

          textloading:'Transcription en cours ...',
        
        });
      }else{
        this.setState({

          textloading:'Loading transcription ...',
        
        });
      }

      this.setState({
        
        loadershow: true,
        soundlink: this.state.wbslink + "sound/" + nomaudio + ".wav",
        soundname : nomsound,
        idaudioselect : nomaudio,
        isModaltranscription: true
      
      });

      let idsc = parseInt(sessionStorage.getItem('idsociety'));

      

      fetch(this.state.wbslink + `diarizer.php`,{                  

                method:'post',
                headers: {'Content-Type':'application/x-www-form-urlencoded'},
                body: queryString.stringify({nomaudio : nomaudio + '.wav', iduserdiari: iduserdiarizeur, dureeaudio : timesound,langueaudio:lgnsound, idsociete:idsc })

        })

        .then(response => response.json())
        .then(data => {

          if(JSON.stringify(data).replace(/\"/g, "") == "OK"){

            let newcredit = minuterestant - timesound;

            sessionStorage.setItem(
              'creditrestant', newcredit 
            );

            //this.loadpseakerafterdiarize(data)
            this.refreshlistsound()

            if(this.state.lng === "FR"){
              document.title = "Liste des audios en corbeille";
            }else{
              document.title = "Audio list in trash";
            }

          }else{

            //alert(data);

            this.setState({ diarizer: [], loadershow: false, });


            

          }
          //alert(data)
          //const jsonfile = JSON.parse(data)
          //alert(JSON.stringify(jsonfile.recognizedPhrases[0].nbest[3].display))
            //this.setState({ diarizer: data});

        })
        .catch(error => {
              this.setState({ diarizer: [], loadershow: false});
        });   
        
  };

  loadpseakerafterdiarize(data){

    if(data.length>0){

        this.state.speaker1.length = 0;
        this.state.speaker2.length = 0;  

        var Positif1 = 0;
        var Neutre1 = 0;
        var Negatif1 = 0;
        var Speaker1speak = 0;

        var Positif2 = 0;
        var Neutre2 = 0;
        var Negatif2 = 0;
        var Speaker2speak = 0;

        var Positif = 0;
        var Neutre = 0;
        var Negatif = 0;
        var Speaktotal = data.length;

        var SentimentGlobalePositif = 0.00;
        var SentimentGlobaleNegatif = 0.00;
        var SentimentGlobaleNeutre = 0.00;

        data.forEach((item)=>{

          if(item.sentimentspeaker === 'positive'){
            Positif++
          }

          if(item.sentimentspeaker === 'neutral' || item.sentimentspeaker === 'mixed'){
            Neutre++
          }

          if(item.sentimentspeaker === 'negative'){
            Negatif++
          }


          {/*if(parseFloat(item.sentiment)>=0.05){
            SentimentGlobalePositif+= parseFloat(item.sentiment)
          }

          if(parseFloat(item.sentiment)>-0.05 && parseFloat(item.sentiment)<0.05){
            SentimentGlobaleNeutre+= parseFloat(item.sentiment)
          }

          if(parseFloat(item.sentiment)<= -0.05){
            SentimentGlobaleNegatif+= parseFloat(item.sentiment)
          }*/}

          if(item.speaker === '1'){

            Speaker1speak++

            if(item.sentimentspeaker === 'positive'){
              Positif1++
            }

            if(item.sentimentspeaker === 'neutral' || item.sentimentspeaker == 'mixed'){
              Neutre1++
            }

            if(item.sentimentspeaker === 'negative'){
              Negatif1++
            }

            this.state.speaker1.push({ idspeak: item.iddiarization, starttime:item.starttime, sentiment:item.sentiment, Global: item.sentimentspeaker, Positif:parseFloat(item.positif)*100, Negatif:parseFloat(item.negatif)*100, Neutre:parseFloat(item.neutre)*100});

          }else{

            Speaker2speak++

            if(item.sentimentspeaker === 'positive'){
              Positif2++
            }

            if(item.sentimentspeaker === 'neutral' || item.sentimentspeaker === 'mixed'){
              Neutre2++
            }

            if(item.sentimentspeaker === 'negative'){
              Negatif2++
            }

            this.state.speaker2.push({ idspeak: item.iddiarization, starttime:item.starttime, sentiment:item.sentiment, Global: item.sentimentspeaker, Positif:parseFloat(item.positif)*100, Negatif:parseFloat(item.negatif)*100, Neutre:parseFloat(item.neutre)*100});

          }

        })

      }

      this.state.speaker1sentiment.push({ 
        name: 'Positif', value:  (Positif1/Speaker1speak)*100,
      });
      this.state.speaker1sentiment.push({ 
          name: 'Neutre', value: (Neutre1/Speaker1speak)*100 ,
      });
      this.state.speaker1sentiment.push({ 
        name: 'Négatif', value: (Negatif1/Speaker1speak)*100,
      });

      this.state.speaker2sentiment.push({ 
        name: 'Positif', value:  (Positif2/Speaker2speak)*100,
      });
      this.state.speaker2sentiment.push({ 
          name: 'Neutre', value: (Neutre2/Speaker2speak)*100 ,
      });
      this.state.speaker2sentiment.push({ 
        name: 'Négatif', value: (Negatif2/Speaker2speak)*100,
      });

      SentimentGlobalePositif = ((Positif/Speaktotal)*100);
      SentimentGlobaleNeutre = ((Neutre/Speaktotal)*100);
      SentimentGlobaleNegatif = ((Negatif/Speaktotal)*100);

      this.setState({
        speakers: data,
        speakerpositif: SentimentGlobalePositif.toFixed(2),
        speakerneutre: SentimentGlobaleNeutre.toFixed(2),
        speakernegatif: SentimentGlobaleNegatif.toFixed(2),
        loadershow: false,
      })

      if(this.state.lng === "FR"){
        document.title = "Liste des audios en corbeille";
      }else{
        document.title = "Audio list in trash";
      }

    var idlastline = 'lastline';
    var element = document.getElementById(idlastline);

    if(element !== 'null'){
      element.scrollIntoView({ block: 'end',  behavior: 'smooth' });
    }

      this.refreshlistsound();   
       
  }
  
  sentimentvalue(idaudiospeak,typeaction) {


    if(this.state.lng === "FR"){
      this.setState({

        textloading:'Chargement en cours ...',
      
      });
    }else{
      this.setState({

        textloading:'Loading data ...',
      
      });
    }

    this.setState({ 

      loadershow: true,
      soundlink: this.state.wbslink + "sound/" + idaudiospeak + ".wav",
      
      

    });

    if(typeaction=="vue"){
      this.setState({
        idaudioselect : idaudiospeak,
      })
    }

    fetch(this.state.wbslink + `speaker.php`,{                  
            
            method:'post',
            headers: {'Content-Type':'application/x-www-form-urlencoded; '},
            body: queryString.stringify({
              idaudio : idaudiospeak
            })

      })

      .then(response => response.json())
      .then(data => {

        //alert(JSON.stringify(data))
    
          if(data.length>0){

            this.loadpseaker(data,typeaction)

          }

      })
      .catch(error => {
          this.setState({ speakers: []});
      });  
      
};   


diarizationeffectue(){

  if(this.state.lng === "FR"){
    alert('Transcription déjà effectué.')
  }else{
    alert('Transcription finish.')
  }  

}

diarizationnoneffectue(){

  if(this.state.lng === "FR"){
    alert('Transcription non effectué.')
  }else{
    alert('Transcription not finish.')
  }  

}

  async loadpseaker(data,typeaction){

    if(data.length>0){

        this.state.speaker1.length = 0;
        this.state.speaker2.length = 0;  

        var Positif1 = 0;
        var Neutre1 = 0;
        var Negatif1 = 0;
        var Speaker1speak = 0;
        
        var idscroll =0;

        var Positif2 = 0;
        var Neutre2 = 0;
        var Negatif2 = 0;
        var Speaker2speak = 0;

        var Positif = 0.00;
        var Neutre = 0.00;
        var Negatif = 0.00;
        var Speaktotal = data.length;

        var SentimentGlobalePositif = 0.00;
        var SentimentGlobaleNegatif = 0.00;
        var SentimentGlobaleNeutre = 0.00;

        data.forEach((item)=>{

          {/*if(parseFloat(item.sentiment)>=0.05){
            SentimentGlobalePositif+= parseFloat(item.sentiment)
          }

          if(parseFloat(item.sentiment)>-0.05 && parseFloat(item.sentiment)<0.05){
            SentimentGlobaleNeutre+= parseFloat(item.sentiment)
          }

          if(parseFloat(item.sentiment)<= -0.05){
            SentimentGlobaleNegatif+= parseFloat(item.sentiment)
          }*/}

          if(item.speaker === '1'){

            idscroll = parseInt(item.iddiarization);

            Speaker1speak++

            if(item.sentimentspeaker === 'positive'){
              Positif1++
            }

            if(item.sentimentspeaker === 'neutral' || item.sentimentspeaker === 'mixed'){
              Neutre1++
            }

            if(item.sentimentspeaker === 'negative'){
              Negatif1++
            }

            this.state.speaker1.push({ idspeak: item.iddiarization, starttime:item.starttime, sentiment:item.sentiment, Global: item.sentimentspeaker, Positif:parseFloat(item.positif)*100, Negatif:parseFloat(item.negatif)*100, Neutre:parseFloat(item.neutre)*100});

          }else{

            Speaker2speak++

            if(item.sentimentspeaker === 'positive'){
              Positif2++
            }

            if(item.sentimentspeaker === 'neutral' || item.sentimentspeaker === 'mixed'){
              Neutre2++
            }

            if(item.sentimentspeaker === 'negative'){
              Negatif2++
            }

            this.state.speaker2.push({ idspeak: item.iddiarization, starttime:item.starttime, sentiment:item.sentiment, Global: item.sentimentspeaker, Positif: parseFloat(item.positif)*100, Negatif:parseFloat(item.negatif)*100, Neutre:parseFloat(item.neutre)*100});

          }

        })

      }

      this.state.speaker1sentiment.push({ 
        name: 'Positif', value:  (Positif1/Speaker1speak)*100,
      });
      this.state.speaker1sentiment.push({ 
          name: 'Neutre', value: (Neutre1/Speaker1speak)*100 ,
      });
      this.state.speaker1sentiment.push({ 
        name: 'Négatif', value: (Negatif1/Speaker1speak)*100,
      });

      this.state.speaker2sentiment.push({ 
        name: 'Positif', value:  (Positif2/Speaker2speak)*100,
      });
      this.state.speaker2sentiment.push({ 
          name: 'Neutre', value: (Neutre2/Speaker2speak)*100 ,
      });
      this.state.speaker2sentiment.push({ 
        name: 'Négatif', value: (Negatif2/Speaker2speak)*100,
      });

      //SentimentGlobalePositif = ((Positif/Speaktotal)*100);
      ///SentimentGlobaleNeutre = ((Neutre/Speaktotal)*100);
      //SentimentGlobaleNegatif = ((Negatif/Speaktotal)*100);
      {/*speakerpositif: SentimentGlobalePositif.toFixed(2),
        speakerneutre: SentimentGlobaleNeutre.toFixed(2),
      speakernegatif: SentimentGlobaleNegatif.toFixed(2),*/}

      this.setState({
        speakers: data,
        loadershow: false,
      }) 

      if(typeaction=="vue"){
        this.setState({
        isModaldetails : true,
        })
      }else{

        await new Promise(resolve => setTimeout(resolve, 1500));

        this.setState({
          printbutton : true,
          })
        
        //document.getElementById(this.state.soundname).click();

      }

      if(this.state.lng === "FR"){
        document.title = "Liste des audios en corbeille";
      }else{
        document.title = "Audio list in trash";
      }
    
    {/*var idlastline = 'lastline';
    var element = document.getElementById(idlastline);

    if(element !== 'null'){
      element.scrollIntoView({ block: 'end',  behavior: 'smooth' });
    }*/}
       
  }

  

  selectligne(idscroll){

    var idline = idscroll;

    var element = document.getElementById(idline);

    if(element !== 'null' && parseInt(idline)>0){
      element.scrollIntoView({ block: 'end',  behavior: 'smooth' });
    }

    idline = 0;

    element = 'null';
    
  }




  updatemodal(idaudio,thematiqueupdate,speaker1,speaker2,namesound) {
   
    this.setState({ isModalupdate: true,
      soundlink: this.state.wbslink + "sound/" + idaudio + ".wav",
      soundname : namesound,
      idaudioselect : idaudio,
      thematique: thematiqueupdate,
      speaker1: speaker1,
      speaker2: speaker2,
      speakers: []});
    //this.themeatiquesound(idaudio);      
    };   

  themeatiquemodal(idaudio,thematiqueupdate) {
    this.setState({  idaudioselect : idaudio, thematique: thematiqueupdate, speakers: []});
    //this.themeatiquesound(idaudio);      
};   

updatesound () {

  fetch(this.state.wbslink + `audio/updateaudio.php`,{                  

            method:'post',
            headers: {'Content-Type':'application/x-www-form-urlencoded'},
            body: queryString.stringify({idaudio : this.state.idaudioselect, thematique: this.state.thematique, speaker1: this.state.speaker1, speaker2: this.state.speaker2})

    })
    .then(response => response.json())
    .then(data => {
      //alert(JSON.stringify(data))

        this.setState({ isModalupdate: false, thematique :'', idaudioselect :0 , speaker1: '', speaker2: ''});

        this.refreshlistsound();

    })
    .catch(error => {

          this.setState({ listsound: [], isModalupdate: false, thematique :'', idaudioselect :0, speaker1: '', speaker2: '' });

    });   
    
};   

deletemodal(idaudio,thematiqueupdate,speaker1,speaker2,namesound) {
   
  this.setState({
    isModaldelete: true,
    soundname : namesound,
    idaudioselect : idaudio,
   });
  //this.themeatiquesound(idaudio);      
};   

restoremodal(idaudio,thematiqueupdate,speaker1,speaker2,namesound) {
   
  this.setState({
    isModalrestore: true,
    soundname : namesound,
    idaudioselect : idaudio,
   });
  //this.themeatiquesound(idaudio);      
};   


  themeatiquesound () {

    fetch(this.state.wbslink + `thematique.php`,{                  

              method:'post',
              headers: {'Content-Type':'application/x-www-form-urlencoded'},
              body: queryString.stringify({idaudio : this.state.idaudioselect, thematique: this.state.thematique})

      })
      .then(response => response.json())
      .then(data => {

          this.setState({ isModalthematique: false, thematique :'', idaudioselect :0, thematiquebutton : false });

          this.refreshlistsound();

      })
      .catch(error => {
            this.setState({ listsound: [], isModalthematique: false, thematique :'', idaudioselect :0, thematiquebutton : false });
      });   
      
};   

updatecomment () {

  fetch(this.state.wbslink + `updatecomment.php`,{                  

            method:'post',
            headers: {'Content-Type':'application/x-www-form-urlencoded'},
            body: queryString.stringify({idaudio : this.state.idaudioselect, comment: this.state.comment})

    })
    .then(response => response.json())
    .then(data => {

        this.setState({ comment :'', idaudioselect :0, commentbutton : false });

        this.refreshlistsound();

    })
    .catch(error => {

          this.setState({ listsound: [], isModalupdate: false, thematique :'', idaudioselect :0, speaker1: '', speaker2: '',commentbutton : false });

    });   
    
};   


speakermodal(idaudio,speaker1update,speaker2update) {
  this.setState({idaudioselect : idaudio, speaker1: speaker1update, speaker2: speaker2update});
  //this.themeatiquesound(idaudio);      
};   

speakermodalsound () {

  fetch(this.state.wbslink + `speakerupdate.php`,{                  

            method:'post',
            headers: {'Content-Type':'application/x-www-form-urlencoded'},
            body: queryString.stringify({idaudio : this.state.idaudioselect, speaker1: this.state.speaker1, speaker2: this.state.speaker2})

    })
    .then(response => response.json())
    .then(data => {
     
        this.setState({ isModalspeaker: false, thematique :'', idaudioselect :0, speaker1: '', speaker2: '',speakerbutton:false });

        this.refreshlistsound();

    })
    .catch(error => {
          this.setState({ listsound: [], isModalspeaker: false, thematique :'', idaudioselect :0, speaker1: '', speaker2: '',speakerbutton:false });
    });   
    
};   

deletesound() {

    // Create an object of formData 
    const formData = new FormData(); 

    formData.append("idaudio",this.state.idaudioselect);

    let token = sessionStorage.getItem('tokenunsaid');

    const myDecodedToken = decodeToken(token);

    if(myDecodedToken == null){
      this.setState({ typeuser: null})
      document.location.href = '/login';
      return;
    }
    const isMyTokenExpired = isExpired(token);

    if(isMyTokenExpired == true){
      this.setState({ typeuser: null})
      document.location.href = '/login';
      return;
    }

    token = JSON.stringify(myDecodedToken.uid).replace(/\"/g, "");

    fetch(this.state.wbslink + `deletesound`,{                  
          
      mode: 'cors',     
      method:'post',
      headers: {'Authorization': 'Bearer '+ token},
      body: formData

      })

    .then(res => {

      errocode = res.status;

      if (!res.ok) {
          
          return res.json().then(json => { throw json; });

      }

      return res.json();

    })
    .then(data => {

    if(data.length > 0){
      
      if(JSON.stringify(data).replace(/"/g, "") === 'SUCCESS'){

        this.setState({ soundname :'', idaudioselect :0, isModaldelete : false });

        this.refreshlistsound();

      }else{
        this.setState({ speakers: []});
      }

    }else{

      this.setState({ speakers: []});

    }

  })
  .catch(err => {

    if(errocode == 401 || errocode == 0){
      
      sessionStorage.removeItem("tokenunsaid");
      document.location.href = "/login";

      return

    }

    alert("Erreur :" + errocode + ", "+JSON.stringify(err.message).replace(/\"/g, ""))

    this.setState({
      loadershow: false
    })

  })
    
};   

restoresound() {

    // Create an object of formData 
    const formData = new FormData(); 

    formData.append("idaudio",this.state.idaudioselect);

    let token = sessionStorage.getItem('tokenunsaid');

    const myDecodedToken = decodeToken(token);

    if(myDecodedToken == null){
      this.setState({ typeuser: null})
      document.location.href = '/login';
      return;
    }
    const isMyTokenExpired = isExpired(token);

    if(isMyTokenExpired == true){
      this.setState({ typeuser: null})
      document.location.href = '/login';
      return;
    }

    token = JSON.stringify(myDecodedToken.uid).replace(/\"/g, "");

    fetch(this.state.wbslink + `restoresound`,{                  
          
      mode: 'cors',     
      method:'post',
      headers: {'Authorization': 'Bearer '+ token},
      body: formData

      })

    .then(res => {

      errocode = res.status;

      if (!res.ok) {
          
          return res.json().then(json => { throw json; });

      }

      return res.json();

    })
    .then(data => {

    if(data.length > 0){
      
      if(JSON.stringify(data).replace(/"/g, "") === 'SUCCESS'){

        this.setState({ soundname :'', idaudioselect :0, isModalrestore : false });

        this.refreshlistsound();

      }else{
        this.setState({ speakers: []});
      }

    }else{

      this.setState({ speakers: []});

    }

  })
  .catch(err => {

    if(errocode == 401 || errocode == 0){
      
      sessionStorage.removeItem("tokenunsaid");
      document.location.href = "/login";

      return

    }

    alert("Erreur :" + errocode + ", "+JSON.stringify(err.message).replace(/\"/g, ""))

    this.setState({
      loadershow: false
    })

  })
    
};  

  
ecouteraudio (soundid,soundnameshow,positifvalue,neutrevalue,negatifvalue,nomspeaker1,nomspeaker2,thematique,comment,summarization) {

  this.setState({
    soundlink: this.state.wbslink + "sound/" + soundid ,
    soundname : soundnameshow,
    idaudioselect : soundid,
    speaker1: [],
    speaker2: [],
    speaker1name: nomspeaker1,
    speaker2name: nomspeaker2,
    thematique : thematique,
    comment:comment,
    summarization:summarization,
    speaker1sentiment: [],
    speaker2sentiment: [],
    printbutton:false,
    speakerpositif: (positifvalue*100).toFixed(2),
    speakerneutre: (neutrevalue*100).toFixed(2),
    speakernegatif: (negatifvalue*100).toFixed(2),
    commentbutton:false,
    speakerbutton:false,
    thematiquebutton:false,
    printbutton:false,
  });

  this.sentimentvalue(soundid,"vue")

};   


scrolltospeech=(payload)=>{

  
  var idline = JSON.stringify(payload.payload.idspeak).replace(/\"/g, "")

  var element = document.getElementById(idline);

  if(element !== 'null' && parseInt(idline)>0){
    element.scrollIntoView({ block: 'end',  behavior: 'smooth' });
  }

  idline = 0;

  element = 'null';

  /*text.slice(0, text.lastIndexOf(':'))*/
  
  
     
};

selectlng(e) {
  this.setState({ soundlng: e.target.value });
}

texttoprint (soundid,soundnameshow,positifvalue,neutrevalue,negatifvalue,nomspeaker1,nomspeaker2,thematique,summarization){


  this.setState({
    soundlink: this.state.wbslink + "sound/" + soundid ,
    soundname : soundnameshow,
    idaudioselect : 0,
    idaudioselectprint : soundid,
    speaker1: [],
    speaker2: [],
    speaker1name: nomspeaker1,
    speaker2name: nomspeaker2,
    thematique : thematique,
    summarization:summarization,
    speaker1sentiment: [],
    speaker2sentiment: [],
    printbutton:false,
    speakerpositif: (positifvalue*100).toFixed(2),
    speakerneutre: (neutrevalue*100).toFixed(2),
    speakernegatif: (negatifvalue*100).toFixed(2),
  });

  this.sentimentvalue(soundid,"print")

}

cleardata(){

  this.setState({
    soundlink: "",
    soundname : "",
    idaudioselect : 0,
    idaudioselectprint:0,
    speaker1: [],
    speaker2: [],
    speaker1name: "",
    speaker2name: "",
    thematique : "",
    speaker1sentiment: [],
    speaker2sentiment: [],
    speakerpositif: 0,
    speakerneutre: 0,
    speakernegatif: 0,
    speaker:[],
    printbutton:false,
  });


}

handleKeypress = event => {

  if (event.key === 'Enter' || event.code === "NumpadEnter") {
    
    if(this.state.idaudioselect > 0)

      if(this.state.thematiquebutton == true){
        
        this.themeatiquesound();

        return;

      }

      if(this.state.speakerbutton == true){
        
        this.speakermodalsound();

        return;

      }

      if(this.state.commentbutton == true){
        
        this.updatecomment();

        return;

      }

    }

  if (event.key === 'Escape') {
    
    if(this.state.idaudioselect > 0)

    this.refreshlistsound();

    return;

  }

}

  render() {

    const handleChange = (e) => {
      this.setState({selectedValue: e.target.value})
    }

          return (

            <div>

              {this.state.loadingdata == true?

                null
              
                :

                <div className="App" style={{marginTop:'80px'}}>
                  
                  {this.state.tokenvalue === '' ?
                            

                            <Redirect push to="/Login"/>
                              

                            :

                            null

                    }

                    
      
                    {/*{this.state.loadershow === true ?
                          

                    <Loader type="spinner-cub" bgColor={'#2A83E8'} title={this.state.textloading} color={'#2A83E8'} size={100} />
                      

                    :

                      <div>

                        {this.state.listsound.length === 0 ?

                        <p style={{fontWeight:"bold"}}>{this.state.lng ==="FR" ? 'Pas d\'audio eregistré.' : 'No sound saved.'}&nbsp;</p>

                        :

                        null

                        }
                        
                      </div>

                    }*/}

                    {this.state.listsound.length === 0 ?

                    <p style={{fontWeight:"bold"}}>{this.state.lng ==="FR" ? 'Pas d\'audio au corbeille.' : 'No sound in trash.'}&nbsp;</p>

                    :

                    null

                    }


              <div className='centrervue'>

              <input className="inputfind" style={{marginTop: isBrowser?"0px":"50px"}} type="text" name="search" placeholder={this.state.lng ==='FR'? 'Rechercher': 'Search'} value={this.state.filterdata} onChange={(e) => this.filterlist(e.target.value)}/>

                <BrowserView>

            
                    <Table>
                      <Thead>
                        <Tr>
                          
                          
                          <Th >{this.state.lng ==="FR" ? 'ID de l\'appel' : 'ID Call'}</Th>
                          <Th >{this.state.lng ==="FR" ? 'Durée' : 'Duration'}</Th>
                          {/*<TableCell >{this.state.lng ==="FR" ? 'Mettre à jour Thématique' : 'Update Thematic'}</TableCell>*/}
                          {/*<Th >{this.state.lng ==="FR" ? 'Thématique' : 'Thematic'}</Th>*/}
                          <Th >{this.state.lng ==="FR" ? 'Résumé' : 'Summarization'}</Th>
                          {/*<TableCell >{this.state.lng ==="FR" ? 'Mettre à jour Speaker' : 'Update Speaker'}</TableCell>*/}
                          <Th >Speaker</Th>
                          <Th >{this.state.lng ==="FR" ? 'Commentaire' : 'Comment'}</Th>
                          <Th style={{textAlign:'center'}}>{this.state.lng ==="FR" ? 'Détails' : 'Call Details'}</Th>
                          {/*<Th style={{textAlign:'center'}}>{this.state.lng ==="FR" ? 'Mettre à jour' : 'Update'}</Th>*/}
                          <Th style={{textAlign:'center'}}>{this.state.lng ==="FR" ? 'Générer le PDF' : 'Generate PDF'}</Th>
                          <Th style={{textAlign:'center'}}>{this.state.lng ==="FR" ? 'Restaurer' : 'Restore'}</Th>
                          <Th style={{textAlign:'center'}}>{this.state.lng ==="FR" ? 'Supprimer' : 'Delete'}</Th>
                         
                        </Tr>
                      </Thead>
                      <Tbody>
                      {this.state.listsound.map((row) => (
                          <Tr key={row.linksound} style={{backgroundColor: row.linksound === this.state.idaudioselect ? "#333333": "white"}} className="tableborder" >
                            
                            
                            <Td style={{color: row.linksound === this.state.idaudioselect ? "white": "black"}} className="maxwidthline">{row.nomaudio}</Td>
                            <Td style={{color: row.linksound === this.state.idaudioselect ? "white": "black"}}>{Math.floor((row.duree % 3600) / 60)}min {Math.round(((row.duree / 60)-Math.floor(row.duree / 60))*60)}s</Td>
                            <Td style={{color: row.linksound === this.state.idaudioselect ? "white": "black",textAlign:"left"}} className="maxwidthline">{row.summarization.substring(0,100)} {row.summarization.length>100?" ...":""}</Td>
                            {/*<TableCell ><button type="submit" className="btn-success btn-circle btn-xl" onClick={() => this.themeatiquemodal(row.linksound,row.thematique)}><span><AssignmentIcon  style={{color:"white"}}/></span></button>
                                    </TableCell>*/}
                            
                            {/*
                            <Td style={{color: row.linksound === this.state.idaudioselect ? "white": "black"}} className="maxwidthline">
                            
                            {row.linksound === this.state.idaudioselect?
                              
                              <div className="container " >

                                {this.state.thematiquebutton== true?

                                  <div className="row ">


                                    <div className="col " style={{width:"25px"}}>
                                      
                                      {this.state.thematiquebutton== true?
                                        <span style={{color:'#dc3545'}} onClick={() => this.refreshlistsound()}><CancelIcon /></span>
                                        :
                                        null
                                      }
                                      
                                    </div>
                                    <div className="col">

                                      {this.state.thematique === "" ?

                                        <div>

                                            {this.state.thematiquebutton== true?

                                                <textarea type="textarea" name="thematique" rows={3}  value={this.state.thematique} onChange={(e) => this.setState({ thematique: e.target.value})}/>

                                                :

                                                <p onClick={() => this.setState({thematique:row.thematique!== ""? row.thematique :"", idaudioselect:row.linksound,thematiquebutton:true})}>{row.thematique!== "" ? row.thematique : <p>{this.state.lng ==="FR" ? 'Cliquer pour ajouter la thématique' : 'Click to add thematique'} </p>}</p>
                                          
                                            }


                                        </div>


                                          :

                                          <div>

                                              {this.state.thematiquebutton== true?

                                              <textarea type="textarea" name="thematique" rows={3}  value={this.state.thematique} onChange={(e) => this.setState({ thematique: e.target.value})}/>

                                              :

                                              <p onClick={() => this.setState({thematique:row.thematique!== ""? row.thematique :"", idaudioselect:row.linksound,thematiquebutton:true})}>{row.thematique!== "" ? row.thematique : <p>{this.state.lng ==="FR" ? 'Cliquer pour ajouter la thématique' : 'Click to add thematique'} </p>}</p>

                                              }

                                          </div>


                                      }
                                      
                                    </div>
                                    <div className="col" style={{width:"25px"}}>
                                      {this.state.thematiquebutton== true?
                                        <span style={{color:'#00934c'}} onClick={() => this.updatecomment()}><SaveAsIcon /></span>
                                        :
                                        null
                                      }        
                                    </div>
                                  </div>

                                  :



                                  <p onClick={() => this.setState({thematique:row.thematique!== ""? row.thematique :"", idaudioselect:row.linksound,thematiquebutton:true})}>{row.thematique!== "" ? row.thematique : <p>{this.state.lng ==="FR" ? 'Cliquer pour ajouter la thématique' : 'Click to add thematique'} </p>}</p>

                                
                                
                                }

                              </div>

                              :

                              <p onClick={() => this.setState({thematique:row.thematique!== ""? row.thematique :"", idaudioselect:row.linksound,thematiquebutton:true})}>{row.thematique!== "" ? row.thematique : <p>{this.state.lng ==="FR" ? 'Cliquer pour ajouter la thématique' : 'Click to add thematique'} </p>}</p>
                              
                              }

                              </Td>
                              */}
                            {/*<TableCell ><button type="submit" className="btn-warning btn-circle btn-xl" style={{color:'white'}}onClick={() => this.speakermodal(row.linksound,row.speaker1,row.speaker2)}><span><RecordVoiceOverIcon /></span></button></TableCell>*/}
                            
                            <Td style={{color: row.linksound === this.state.idaudioselect ? "white": "black"}} className="maxwidthline"><p>Speaker 1 : {row.speaker1}, Speaker 2 : {row.speaker2}</p></Td>
                            <Td style={{color: row.linksound === this.state.idaudioselect ? "white": "black"}} className="maxwidthline"><p>{row.comment}</p></Td>
                            
                            
                            <Td style={{textAlign:'center'}}>

                              {parseFloat(row.sentimentpositif)>parseFloat(row.sentimentnegatif) ?

                              <button className="btn btntransparent btn-circle btn-xl" onClick={() => this.ecouteraudio(row.linksound,row.nomaudio,row.sentimentpositif,row.sentimentneutre,row.sentimentnegatif,row.speaker1,row.speaker2,row.thematique,row.comment,row.summarization)}><span style={{color:'#00934c'}}><VisibilityIcon /></span></button>

                              :

                              null

                              }


                              {parseFloat(row.sentimentpositif)<parseFloat(row.sentimentnegatif) ?

                              <button  className="btn btntransparent btn-circle btn-xl" onClick={() => this.ecouteraudio(row.linksound,row.nomaudio,row.sentimentpositif,row.sentimentneutre,row.sentimentnegatif,row.speaker1,row.speaker2,row.thematique,row.comment,row.summarization)}><span style={{color:'#e40001'}}><VisibilityIcon /></span></button>

                              :

                              null

                              }

                              {parseFloat(row.sentimentpositif)===parseFloat(row.sentimentnegatif) && row.iddiarizeur !== '0' ?

                              <button className="btn btntransparent btn-circle btn-xl" onClick={() => this.ecouteraudio(row.linksound,row.nomaudio,row.sentimentpositif,row.sentimentneutre,row.sentimentnegatif,row.speaker1,row.speaker2,row.thematique,row.comment,row.summarization)}><span style={{color:'#e89900'}}><VisibilityIcon /></span></button>

                              :

                              null

                              }

                              {/*{parseInt(row.sentimentglobale) === 1 && row.iddiarizeur !== '0'?

                              <button type="submit" className="btn btntransparent btn-circle btn-xl" onClick={() => this.ecouteraudio(row.linksound,row.nomaudio.slice(row.nomaudio.lenght, -4),row.sentimentpositif,row.sentimentneutre,row.sentimentnegatif,row.speaker1,row.speaker2,row.thematique)}><span style={{color:'#00934c'}}><VisibilityIcon /></span></button>

                              :

                              null

                              }


                              {parseInt(row.sentimentglobale) === 2 && row.iddiarizeur !== '0'?

                              <button type="submit" className="btn btntransparent btn-circle btn-xl" onClick={() => this.ecouteraudio(row.linksound,row.nomaudio.slice(row.nomaudio.lenght, -4),row.sentimentpositif,row.sentimentneutre,row.sentimentnegatif,row.speaker1,row.speaker2,row.thematique)}><span style={{color:'#e40001'}}><VisibilityIcon /></span></button>

                              :

                              null

                              }

                              {parseInt(row.sentimentglobale) === 0 && row.iddiarizeur !== '0' ?

                              <button type="submit" className="btn btntransparent btn-circle btn-xl" onClick={() => this.ecouteraudio(row.linksound,row.nomaudio.slice(row.nomaudio.lenght, -4),row.sentimentpositif,row.sentimentneutre,row.sentimentnegatif,row.speaker1,row.speaker2,row.thematique)}><span style={{color:'#e89900'}}><VisibilityIcon /></span></button>

                              :

                              null

                              }*/}

                              {row.iddiarizeur === '0' ?

                              <button  className="btn-gris btn-circle btn-xl" onClick={() => this.diarizationnoneffectue()}><span style={{color:"#333333"}}><VisibilityOffIcon /></span></button>

                              :

                              null

                              }

                            </Td>

                            {/*<Td ><button  className="btn btntransparent btn-circle btn-xl" onClick={() => this.updatemodal(row.linksound,row.thematique,row.speaker1,row.speaker2,row.nomaudio.slice(row.nomaudio.lenght, -4))}><span><EditIcon style={{color:"#2A83E8"}}/></span></button> </Td>*/}

                                    <Td> 

                                    {row.iddiarizeur === '0' ?

                                        null

                                        :

                                        <div>

                                          
                                          {row.linksound === this.state.idaudioselectprint ? 
                                          
                                            <div>

                                                {this.state.printbutton== true ?

                                                      <ReactToPrint
                                                                                                
                                                      trigger={() => (
                                                        <button id={row.nomaudio} className="btn btntransparent btn-circle btn-xl"><span><LocalPrintshopIcon style={{color:"#929292"}}/></span></button>
                                                        
                                                      )}

                                                      onAfterPrint={() => (
                                                        this.cleardata() 
                                                      )}

                                                      documentTitle = {row.nomaudio}
                                                      
                                                      content={() => this.componentRef}

                                                      />

                                                  :

                                                  <div>

                                                    {this.state.speaker1.length>0?

                                                        <Loader type="spinner-cub" bgColor={'red'} color={'white'} size={25} />

                                                        

                                                      :

                                                      null

                                                    }

                                                  </div>


                                                }

                                            </div>
                                            
                                            
                                            : 

                                            <button className="btn btntransparent btn-circle btn-xl" onClick={() => {
                                              this.texttoprint(row.linksound,row.nomaudio,row.sentimentpositif,row.sentimentneutre,row.sentimentnegatif,row.speaker1,row.speaker2,row.thematique,row.summarization);
                                            }} >
                                              <span><PictureAsPdfIcon style={{color:"#929292"}}/></span>
                                            </button>


                                          }

                                          

                                        </div>

                                    }

                                    </Td>

                                    <Td ><button  className="btn btntransparent btn-circle btn-xl" onClick={() => this.restoremodal(row.linksound,row.thematique,row.speaker1,row.speaker2,row.nomaudio)}><span><SettingsBackupRestoreIcon style={{color:"#2A83E8"}}/></span></button> </Td>
                                    <Td ><button  className="btn btntransparent btn-circle btn-xl" onClick={() => this.deletemodal(row.linksound,row.thematique,row.speaker1,row.speaker2,row.nomaudio)}><span><DeleteIcon style={{color:"#dc3545"}}/></span></button> </Td>

                          </Tr>
                        ))}
                      </Tbody>
                    </Table>

                    {this.state.listsound.length>0?

                    <ReactPaginate
                    previousLabel={"<< prev"}
                    previousClassName={"prevClass"}
                    previousLinkClassName={"prevClass"}
                    nextLabel={"next >>"}
                    nextClassName={"next"}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={this.state.pageCount}
                    marginPagesDisplayed={3}
                    pageRangeDisplayed={3}
                    onPageChange={this.handlePageClick}
                    containerClassName={"pagination"}
                    subContainerClassName={"pages pagination"}
                    activeClassName={"active"}/>

                    :

                    null
                    }
                  

                </BrowserView>
                
                <MobileView>

                <div className='paginationmobile' >

                {this.state.listsound.length>0?

                  <ReactPaginate
                  previousLabel={"<<"}
                  previousClassName={"prevClass"}
                  previousLinkClassName={"prevClass"}
                  nextLabel={">>"}
                  nextClassName={"next"}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={this.state.pageCount}
                  marginPagesDisplayed={3}
                  pageRangeDisplayed={3}
                  onPageChange={this.handlePageClick}
                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"}/>

                  :

                  null
                  
                }

                </div>

                <div style={{height:"80px"}}>

                </div>

                    {this.state.listsound.map((row) => (

                      <Card style={{ width: '90%', margin:"5%"}}>

                        <Card.Body key={row.linksound} style={{backgroundColor: row.linksound === this.state.idaudioselect ? "#333333": "white"}}>
                          
                          <p style={{color: row.linksound === this.state.idaudioselect ? "white": "black"}}>{row.nomaudio}</p>

                          {row.iddiarizeur === '0' ?

                            <div>

                                <p style={{marginTop:"20px"}}>{this.state.lng ==="FR" ? 'L\'audio n\'a pas encore été transcrit.' : 'Sound not transcript.'}</p>



                            </div>
                            

                            :

                            <div>

                                {row.summarization !== "" ?
                                  <p style={{color: row.linksound === this.state.idaudioselect ? "white": "black", textAlign:"left"}}>{this.state.lng ==="FR" ? 'Résumé : ' : 'Summarization : '} {row.summarization.substring(0,100)} {row.summarization.length>100?" ...":""}</p>
                                  :
                                  null
                                }

                                {/* 
                                {row.thematique !== "" ?
                                  <p style={{color: row.linksound === this.state.idaudioselect ? "white": "black", fontWeight:"bold", textAlign:"left"}}>Thématique: {row.thematique}</p>
                                  :
                                  null
                                }
                                */}

                                <p style={{color: row.linksound === this.state.idaudioselect ? "white": "black", textAlign:"left"}}>Speaker 1 : {row.speaker1}<br/>Speaker 2 : {row.speaker2}</p>
                                <p style={{color: row.linksound === this.state.idaudioselect ? "white": "black", textAlign:"left"}}>{row.comment}</p>
                                <p style={{color: row.linksound === this.state.idaudioselect ? "white": "black"}}>{this.state.lng ==="FR" ? 'Durée : ' : 'Duration : '}{Math.floor((row.duree % 3600) / 60)}min {Math.round(((row.duree / 60)-Math.floor(row.duree / 60))*60)}s</p>
                                {parseFloat(row.sentimentpositif)>parseFloat(row.sentimentnegatif) ?
                                  
                                  <div className='d-flex flex-row align-items-center'style={{ marginTop:"20px" }}>
                                          
                                      <button  className="btn btntransparent btn-circle btn-xl" style={{marginRight: "12.5%"}} onClick={() => this.ecouteraudio(row.linksound,row.nomaudio,row.sentimentpositif,row.sentimentneutre,row.sentimentnegatif,row.speaker1,row.speaker2,row.thematique,row.comment,row.summarization)}><span style={{color:'#00934c'}}><VisibilityIcon /></span></button>
                                      <button  className="btn btntransparent btn-circle btn-xl" style={{marginLeft: "12.5%",marginRight: "12.5%"}} onClick={() => this.restoremodal(row.linksound,row.thematique,row.speaker1,row.speaker2,row.nomaudio)}><span><SettingsBackupRestoreIcon style={{color:"#2A83E8"}}/></span></button>
                                      <button  className="btn btntransparent btn-circle btn-xl" style={{marginLeft: "12.5%",marginRight: "12.5%"}} onClick={() => this.deletemodal(row.linksound,row.thematique,row.speaker1,row.speaker2,row.nomaudio)}><span><DeleteIcon style={{color:"#dc3545"}}/></span></button>

                                  </div>

                                  :

                                  null

                                }


                                {parseFloat(row.sentimentpositif)<parseFloat(row.sentimentnegatif) ?

                                  <div className='d-flex flex-row align-items-center'style={{ marginTop:"20px" }}>
                                                                            
                                    <button className="btn btntransparent btn-circle btn-xl" style={{marginRight: "12.5%"}} onClick={() => this.ecouteraudio(row.linksound,row.nomaudio,row.sentimentpositif,row.sentimentneutre,row.sentimentnegatif,row.speaker1,row.speaker2,row.thematique,row.comment,row.summarization)}><span style={{color:'#e40001'}}><VisibilityIcon /></span></button>
                                    <button  className="btn btntransparent btn-circle btn-xl" style={{marginLeft: "12.5%",marginRight: "12.5%"}} onClick={() => this.restoremodal(row.linksound,row.thematique,row.speaker1,row.speaker2,row.nomaudio)}><span><SettingsBackupRestoreIcon style={{color:"#2A83E8"}}/></span></button>
                                    <button  className="btn btntransparent btn-circle btn-xl" style={{marginLeft: "12.5%",marginRight: "12.5%"}} onClick={() => this.deletemodal(row.linksound,row.thematique,row.speaker1,row.speaker2,row.nomaudio)}><span><DeleteIcon style={{color:"#dc3545"}}/></span></button>

                                  </div>

                                  :

                                  null

                                }

                                {parseFloat(row.sentimentpositif)===parseFloat(row.sentimentnegatif) && row.iddiarizeur !== '0' ?

                                  <div className='d-flex flex-row align-items-center'style={{ marginTop:"20px" }}>
                                                                                                              
                                    <button className="btn btntransparent btn-circle btn-xl" style={{marginRight: "12.5%"}} onClick={() => this.ecouteraudio(row.linksound,row.nomaudio,row.sentimentpositif,row.sentimentneutre,row.sentimentnegatif,row.speaker1,row.speaker2,row.thematique,row.comment,row.summarization)}><span style={{color:'#e89900'}}><VisibilityIcon /></span></button>
                                    <button  className="btn btntransparent btn-circle btn-xl" style={{marginLeft: "12.5%",marginRight: "12.5%"}} onClick={() => this.restoremodal(row.linksound,row.thematique,row.speaker1,row.speaker2,row.nomaudio)}><span><SettingsBackupRestoreIcon style={{color:"#2A83E8"}}/></span></button>
                                    <button  className="btn btntransparent btn-circle btn-xl" style={{marginLeft: "12.5%",marginRight: "12.5%"}} onClick={() => this.deletemodal(row.linksound,row.thematique,row.speaker1,row.speaker2,row.nomaudio)}><span><DeleteIcon style={{color:"#dc3545"}}/></span></button>

                                  </div>

                                  :

                                  null

                                }
                                                               

                            </div>

                          }

                          <button  className="btn btntransparent btn-circle btn-xl"  style={{marginLeft: "12.5%",marginRight: "12.5%"}} onClick={() => this.restoremodal(row.linksound,row.thematique,row.speaker1,row.speaker2,row.nomaudio)}><span><SettingsBackupRestoreIcon style={{color:"#2A83E8"}}/></span></button>
                          <button  className="btn btntransparent btn-circle btn-xl" style={{marginLeft: "12.5%",marginRight: "12.5%"}} onClick={() => this.deletemodal(row.linksound,row.thematique,row.speaker1,row.speaker2,row.nomaudio)}><span><DeleteIcon style={{color:"#dc3545"}}/></span></button>

                        </Card.Body>

                      </Card>

                    ))}

                  

                </MobileView>

                      
                  
                  </div> 

                  {this.state.isModaldetails === true ?

                    <div> 

                    <Modal
                    fullscreen
                    show={this.state.isModaldetails}
                    onHide={ () => this.setState({isModaldetails:false})}
                    centered
                    animation={false}
                    className="modaldetails"
                    >
                     
                      <Modal.Body>

                        <BrowserView>

                        <div className='modaldetailsdata' >

                            {/*<p style={{color:'#2A83E8', fontWeight:'bold', fontSize:'1.5em',textAlign:"center"}}>{this.state.soundname}</p>*/}

                            <div style={{position:"fixed", right:'10px', top:0, zIndex: '1000'}}>
                              <button  className="btn btntransparent" onClick={() => this.setState({isModaldetails:false})}><span style={{color:'#e40001'}}><CloseIcon sx={{ width: '50px', height:'50px' }}/></span></button>
                            </div>
                                                    
                                <div className="row">

                                  <div className="col-sm thematiquevalue">
                                    <div className="row">
                                        <div style={{width:'18%'}}>
                                        <img src={logo} style={{width:'100px', height:'70px', marginTop:'20px'}}/>
                                        {/*<span style={{color:'white',textAlign:"center"}}><ForumIcon sx={{ width: '100%', height:'100%' }}/></span>*/}
                                        </div>

                                        <div style={{width:'82%'}} className="d-flex flex-column align-items-center ">
                                          <p style={{color:'white', fontWeight:'bold', marginTop:'10px'}}>{this.state.soundname}</p>
                                          {/*<p style={{color:'white', fontWeight:'bold', fontSize:'1em',textAlign:"left", marginTop:'10px', textDecoration:'underline'}}>{this.state.lng ==="FR" ? 'Thématique' : 'Thematique'}</p>*/}
                                          {/*<hr className="lineseparator"></hr>*/}
                                          <p style={{color:'white',textAlign:"left"}}>{this.state.lng ==="FR" ? 'Résumé: ' : 'Summarization: '}{this.state.summarization}</p>
                                        </div>

                                    </div>
                                    
                                  </div>
                                  
                                  {/*<div className="col-sm">
                                    
                                    <p style={{color:'black', fontWeight:'bold', fontSize:'1.5em',textAlign:"center"}}>{this.state.lng ==="FR" ? 'Audio player' : 'Audio player'}</p>

                                        

                                        <ReactToPrint
                                          trigger={() => (
                                            <a className="ant-btn ant-btn-primary" href="#">
                                              {this.state.lng ==='FR'? 'Imprimer': 'Print'}
                                            </a>
                                          )}
                                          content={() => this.componentRef}
                                          />
                                                                
                                  </div>*/}

                                  <div className="col-sm" >

                                  <p style={{color:'black', fontWeight:'bold', fontSize:'1.5em',textAlign:"center"}}>{this.state.lng ==="FR" ? 'Sentiment global de l\'appel' : 'Global call sentiment'}</p>

                                    <div className='sentimentglobal' style={{marginLeft:'5px',marginRight:'5px',marginTop:'0px', fontWeight:'bold'}}>

                                      <div className="champnegatifmodal" style={{padding:'5px'}}>

                                        <div className="row">
                                          <div className="col">
                                            
                                            <p style={{position:"relative"}}> {this.state.lng ==='FR'? 'Négatif': 'Negative'}</p>
                                            <p style={{fontSize:"1.2em",marginTop:"-20px"}}>{this.state.speakernegatif}%</p>

                                          </div>
                                          <div className="col">
                                            <span style={{color:'black',textAlign:"center",fontSize:'1.5em', opacity:0.5}} ><BarChartIcon sx={{ width: '50px', height:'50px' }} /></span>
                                          </div>
                                        </div>
                                      
                                          
                                      
                                      </div>

                                      <div className="champneutremodal" style={{padding:'5px'}}>

                                        <div className="row">
                                          <div className="col">
                                            
                                            <p style={{position:"relative"}}> {this.state.lng ==='FR'? 'Neutre': 'Neutral'}</p>
                                            <p style={{fontSize:"1.2em",marginTop:"-20px"}}>{this.state.speakerneutre}%</p>

                                          </div>
                                          <div className="col">
                                            <span style={{color:'black',textAlign:"center",fontSize:'1.5em', opacity:0.5}} ><BarChartIcon sx={{ width: '50px', height:'50px' }} /></span>
                                          </div>
                                        </div>
                                        
                                      </div>

                                      <div className="champpositifmodal" style={{padding:'5px'}}>

                                        <div className="row">
                                          <div className="col">
                                            
                                            <p style={{position:"relative"}}> {this.state.lng ==='FR'? 'Positif': 'Positive'}</p>
                                            <p style={{fontSize:"1.2em",marginTop:"-20px"}}>{this.state.speakerpositif}%</p>

                                          </div>
                                          <div className="col">
                                            <span style={{color:'black',textAlign:"center",fontSize:'1.5em', opacity:0.5}} ><BarChartIcon sx={{ width: '50px', height:'50px' }} /></span>
                                          </div>
                                        </div>
                                      
                                      </div>

                                    </div>

                                  </div>

                                </div>

                              {this.state.speaker1.length>0 ?

                                <div className='modaldatasentiment'>

                                  <div className="row" >

                                    <div className="col-12 col-md-8">
                                        
                                        <div className='reponsediarization' >

                                        <div >
                                          <p style={{color:'black', fontWeight:'bold', fontSize:'1.2em', textAlign:'center', marginTop:'0px'}}>{this.state.lng ==="FR" ? 'Transcription ' : 'Transcription '}</p>
                                        </div>

                                            {this.state.speakers.map((row) => (

                                                      <div key={row.iddiarization} style={{marginTop:'20px'}}>

                                                          <div> {row.speaker === '1' ?
                                                                                  
                                                            <div  id={parseInt(row.iddiarization)}>

                                                              <div className="container">

                                                                <div className="row" >

                                                                  <div className="col-sm">

                                                                      <div class="container">
                                                                        <div class="row">
                                                                          <div class="col">
                                                                            <p className="champnegatifsentimentmodal">
                                                                              {parseInt(parseFloat(row.negatif)*100)}%
                                                                            </p>
                                                                          </div>
                                                                          <div class="col">
                                                                            <p className="champneutresentimentmodal">
                                                                              {parseInt(parseFloat(row.neutre)*100)}%
                                                                            </p>
                                                                          </div>
                                                                          <div class="col">
                                                                            <p className="champpositifsentimentmodal">
                                                                              {parseInt(parseFloat(row.positif)*100)}%
                                                                            </p>
                                                                          </div>
                                                                        </div>
                                                                      </div>

                                                                  </div>
                                                                  
                                                                  <div className="col-sm" style={{textAlign:'center'}}>
                                                                    <p >{row.starttime}</p>
                                                                  </div>

                                                                  <div className="col-sm">
                                                                    
                                                                  </div>

                                                                </div>

                                                              </div>

                                                              <div className="row">

                                                                <div className="col-8">
                                                                  <div style={{width:'100%', textAlign:'left', marginLeft:'20px'}} className='textleft'>
                                                                    <p style={{lineHeight:'20px'}}>{row.phrase}</p>
                                                                  </div>
                                                                </div>

                                                                <div className="col-1">
                                                                  {row.sentimentspeaker ==='positive' ?

                                                                  <div className="btnpositifmodal btn-sentiment leftunsaid">
                                                                    <div className="centerunsaid">S1</div>
                                                                  </div>
                                                                  :

                                                                  null

                                                                  }

                                                                  {row.sentimentspeaker ==='neutral' ?

                                                                  <div className="btnneutremodal btn-sentiment leftunsaid">
                                                                    <div className="centerunsaid">S1</div>
                                                                  </div>

                                                                  :

                                                                  null

                                                                  }

                                                                  {row.sentimentspeaker ==='mixed' ?

                                                                  <div className="btnmixed btn-sentiment leftunsaid">
                                                                    <div className="centerunsaid">S1</div>
                                                                  </div>

                                                                  :

                                                                  null

                                                                  }

                                                                  {row.sentimentspeaker ==='negative' ?

                                                                  <div className="btnnegatifmodal btn-sentiment leftunsaid">
                                                                    <div className="centerunsaid">S1</div>
                                                                  </div>

                                                                  :

                                                                  null

                                                                  }

                                                                </div>

                                                            </div>

                                                            <hr className="lineseparator"></hr>                                               
                                                              
                                                          </div>

                                                            :
                                                                                  
                                                          <div  id={parseInt(row.iddiarization)}>

                                                            <div className="container">

                                                              <div className="row">

                                                                <div className="col-sm">

                                                                </div>
                                                                
                                                                <div className="col-sm" style={{textAlign:'center'}}>
                                                                  <p >{row.starttime}</p>
                                                                </div>

                                                                <div className="col-sm">
                                                                  <div class="container">
                                                                      <div class="row">
                                                                        <div class="col">
                                                                          <p className="champnegatifsentimentmodal">
                                                                            {parseInt(parseFloat(row.negatif)*100)}%
                                                                          </p>
                                                                        </div>
                                                                        <div class="col">
                                                                          <p className="champneutresentimentmodal">
                                                                            {parseInt(parseFloat(row.neutre)*100)}%
                                                                          </p>
                                                                        </div>
                                                                        <div class="col">
                                                                          <p className="champpositifsentimentmodal">
                                                                            {parseInt(parseFloat(row.positif)*100)}%
                                                                          </p>
                                                                        </div>
                                                                      </div>
                                                                    </div>
                                                                </div>

                                                              </div>

                                                            </div>

                                                            <div className="row">

                                                            <div className="col-2">
                                                                <div style={{width:'100%', textAlign:'center'}}>
                                                                  <p style={{lineHeight:'20px', color:'transparent'}}>.</p>
                                                                </div>
                                                                
                                                              </div>
                                                              
                                                              <div className="col-1">
                                                                {row.sentimentspeaker ==='positive' ?

                                                                <div className="btnpositifmodal btn-sentiment rightunsaid">
                                                                  <div className="centerunsaid">S2</div>
                                                                </div>
                                                                :

                                                                null

                                                                }

                                                                {row.sentimentspeaker ==='neutral' ?

                                                                <div className="btnneutremodal btn-sentiment rightunsaid">
                                                                  <div className="centerunsaid">S2</div>
                                                                </div>

                                                                :

                                                                null

                                                                }

                                                                {row.sentimentspeaker ==='mixed' ?

                                                                <div className="btnmixed btn-sentiment rightunsaid">
                                                                  <div className="centerunsaid">S2</div>
                                                                </div>

                                                                :

                                                                null

                                                                }

                                                                {row.sentimentspeaker ==='negative' ?

                                                                <div className="btnnegatifmodal btn-sentiment rightunsaid">
                                                                  <div className="centerunsaid">S2</div>
                                                                </div>

                                                                :

                                                                null

                                                                }
                                                                
                                                              </div>

                                                              <div className="col-8">
                                                                <div style={{width:'100%', textAlign:'center'}} className='textright'>
                                                                  <p style={{lineHeight:'20px'}}>{row.phrase}</p>
                                                                </div>
                                                                
                                                              </div>

                                                          </div>    

                                                          <hr className="lineseparator"></hr>                        
                                                                                                                  
                                                          </div>

                                                        }
                                                        
                                                        </div>

                                                        
                                                      </div>

                                            ))}

                                          <div style={{height:'50px'}} id="lastline">
                                              <p style={{color:'transparent'}}>.</p>
                                          </div>

                                          </div>
                                    </div>



                                    <div className="col-6 col-md-4">

                                      <div className='sentimentchart' style={{marginTop:'0px'}} id="chart">

                                        <p style={{color:'black', marginTop:'0px',fontWeight:'bold', fontSize:'1.5em',textAlign:'center'}} >S1 : {this.state.speaker1name}</p>                           
                                          
                                          <div className='listesentiment'>
                                            <PieChart width={500} height={300}
                                                  margin={{
                                                    top: 0,
                                                    right: 0,
                                                    left: 0,
                                                    bottom: 20,
                                                  }}
                                                >                                     
                                              <Pie  data={Object.values(this.state.speaker1sentiment)} innerRadius={60} outerRadius={80} paddingAngle={5} cx="50%"cy="50%" labelLine={true}  label={renderCustomizedLabel} fill="#8884d8" dataKey="value" >
                                                {data.map((entry, index) => (
                                                  <Cell key={`cell-${index}`} fill={COLORSCHART[index % COLORSCHART.length]} />
                                                  
                                                ))}
                                                <Label value="Speaker 1" offset={0} position="center" style={{fontWeight:'bold', fontSize:'1.2em'}}/>
                                                {/*<LabelList dataKey="name" position="insideTop" angle="0" fill="#333333" />*/}
                                              </Pie>
                                              <Tooltip/>
                                              {/*<Legend />*/}
                                            </PieChart>
                                          </div>

                                          <p style={{color:'black', fontWeight:'bold', fontSize:'1.5em',textAlign:'center'}}>{this.state.lng ==="FR" ? 'S1 : Sentiment de l\'appel' : 'S1 : Call sentiment'}</p>

                                          <div className='listesentiment' style={{marginTop:"20px"}}> 

                                            {/*<LineChart width={350} height={250} data={Object.values(this.state.speaker1)} >
                                                <CartesianGrid strokeDasharray="3 3" />
                                                <XAxis dataKey="starttime" />
                                              <YAxis domain={[0.00, 1.00]}/>
                                              <Tooltip />
                                              <Line type="monotone" dataKey="Positif" stroke="#00934c" fill="#00934c" activeDot={{r: 8, onClick: (event, payload) => this.scrolltospeech(payload)}} />
                                              <Line type="monotone" dataKey="Neutre" stroke="#e89900"  activeDot={{r: 8, onClick: (event, payload) => this.scrolltospeech(payload)}}/>
                                              <Line type="monotone" dataKey="Negatif" stroke="#e40001"  activeDot={{r: 8, onClick: (event, payload) => this.scrolltospeech(payload)}}/>
                                                </LineChart>*/}
                                              
                                              <BarChart width={500} height={300} 
                                              margin={{
                                                top: 0,
                                                right: 60,
                                                left: 20,
                                                bottom: 5
                                              }}
                                              data={Object.values(this.state.speaker1)}
                                            >
                                              <CartesianGrid strokeDasharray="3 3" />
                                              <XAxis dataKey="starttime" />
                                              <YAxis domain={[0, 100]}/>
                                              <Tooltip />
                                              <Bar dataKey="Positif" stackId="a" fill="#198754" onClick={this.scrolltospeech}/>
                                              <Bar dataKey="Neutre" stackId="a" fill="#e89900" onClick={this.scrolltospeech}/>
                                              <Bar dataKey="Negatif" stackId="a" fill="#dc3545" onClick={this.scrolltospeech}/>
                                            </BarChart>

                                          </div>
                                            
                                          <hr className="lineseparatorspeaker"></hr>

                                          {this.state.speaker2.length>0 ?

                                          <div>

                                            <p style={{color:'#2A83E8', textAlign:'center', fontWeight:'bold', fontSize:'1.5em',marginTop:'20px'}} >S2 : {this.state.speaker2name}</p>                       
                                        
                                            <div className='listesentiment'>
                                              <PieChart width={500} height={300}
                                                    margin={{
                                                      top: 0,
                                                      right: 0,
                                                      left: 0,
                                                      bottom: 20,
                                                    }}
                                                  >          
                                                <Pie  data={Object.values(this.state.speaker2sentiment)} innerRadius={60} outerRadius={80} paddingAngle={5} cx="50%"cy="50%" labelLine={true}  label={renderCustomizedLabel} fill="#8884d8" dataKey="value" >
                                                  {data.map((entry, index) => (
                                                    <Cell key={`cell-${index}`} fill={COLORSCHART[index % COLORSCHART.length]} />
                                                    
                                                  ))}
                                                  <Label value="Speaker 2" offset={0} position="center" style={{fontWeight:'bold', fontSize:'1.2em',color:'#2A83E8'}} fill="#2A83E8"/>
                                                  {/*<LabelList dataKey="name" position="insideTop" angle="0" fill="#333333" />*/}
                                                </Pie>
                                                <Tooltip/>
                                                {/*<Legend />*/}
                                              </PieChart>
                                            </div>

                                            <p style={{color:'#2A83E8', fontWeight:'bold', fontSize:'1.5em',textAlign:'center'}}>{this.state.lng ==="FR" ? 'S2 : Sentiment de l\'appel' : 'S2 : Call sentiment'}</p>

                                            <div className='listesentiment' style={{marginTop:"20px"}}> 

                                            <BarChart width={500} height={300} 
                                                margin={{
                                                  top: 0,
                                                  right: 60,
                                                  left: 20,
                                                  bottom: 5
                                                }}
                                                data={Object.values(this.state.speaker2)}
                                              >
                                                <CartesianGrid strokeDasharray="3 3" />
                                                <XAxis dataKey="starttime" />
                                                <YAxis domain={[0, 100]}/>
                                                <Tooltip />
                                                <Bar dataKey="Positif" stackId="a" fill="#198754" onClick={this.scrolltospeech}/>
                                                <Bar dataKey="Neutre" stackId="a" fill="#e89900" onClick={this.scrolltospeech}/>
                                                <Bar dataKey="Negatif" stackId="a" fill="#dc3545" onClick={this.scrolltospeech}/>
                                              </BarChart>

                                            </div>

                                          </div>

                                          :

                                          null

                                          }

                                          

                                          <div style={{height:'50px'}} >
                                              <p style={{color:'transparent'}}>.</p>
                                          </div>

                                      </div>

                                    </div>




                                  </div>
                                                                                                                                        
                                </div>

                            :

                            null

                            }

                            </div>

                            <div class="container">
                            <div class="row">
                              <div class="col-sm">
                                
                              </div>
                              <div class="col-sm">
                                <div className="audioplayermodal">
                                    <ReactAudioPlayer
                                      src={this.state.soundlink}
                                      controls
                                      volume
                                      
                                      />
                                </div>
                              </div>
                              <div class="col-sm">
                                
                              </div>
                            </div>
                            </div>
                        
                        
                        </BrowserView>

                        <MobileView>

                            <div style={{position:"fixed", right:'10px', top:0, zIndex: '1000', backgroundColor:"white"}}>

                            <div className="container" >
                              <div className="row">

                                <p style={{color:'#2A83E8', fontWeight:'bold', textAlign:"center"}}>{this.state.lng ==="FR" ? 'Relire ' : 'Replay '} {this.state.soundname}</p>
                                <div className="col-10 d-flex justify-content-center">

                                    {/* 
                                    <ReactAudioPlayer
                                      src={this.state.soundlink}
                                      controls
                                      volume 
                                      />
                                      */}

                                      <AudioPlayer
                                        src={this.state.soundlink}    
                                        customControlsSection={
                                          [
                                            RHAP_UI.MAIN_CONTROLS,
                                            RHAP_UI.VOLUME_CONTROLS,
                                          ]
                                        }                                                                  
                                      />

                                </div>

                                <div className="col-2 d-flex justify-content-center">
                                  <button className="btn btntransparent" style={{marginTop:"-5px"}} onClick={() => this.setState({isModaldetails:false})}><span style={{color:'#e40001'}}><CloseIcon sx={{ width: '50px', height:'50px' }}/></span></button>
                                </div>

                              </div>

                            </div>

                            </div>

                            {/*<p style={{color:'#2A83E8', fontWeight:'bold', marginTop:'40px', textAlign:"center"}}>{this.state.soundname}</p>*/}
                            <p style={{color:'black',textAlign:"left",marginTop:'150px' }}>{this.state.lng ==="FR" ? 'Résumé: ' : 'Summarization: '}{this.state.summarization}</p>

                            <div className="row">

                                  <div className="col-sm" >

                                  <p style={{color:'black', fontWeight:'bold', fontSize:'1.5em',textAlign:"center"}}>{this.state.lng ==="FR" ? 'Sentiment global de l\'appel' : 'Global call sentiment'}</p>

                                    <div className='sentimentglobal' style={{marginLeft:'5px',marginRight:'5px',marginTop:'0px', fontWeight:'bold'}}>

                                      <div className="champnegatifmodal" style={{padding:'5px'}}>

                                        <div className="row">
                                          <div className="col">
                                            
                                            <p style={{position:"relative"}}> {this.state.lng ==='FR'? 'Négatif': 'Negative'}</p>
                                            <p style={{fontSize:"1.2em",marginTop:"-20px"}}>{this.state.speakernegatif}%</p>

                                          </div>
                                          
                                        </div>

                                      </div>

                                      <div className="champneutremodal" style={{padding:'5px'}}>

                                        <div className="row">

                                          <div className="col">
                                            
                                            <p style={{position:"relative"}}> {this.state.lng ==='FR'? 'Neutre': 'Neutral'}</p>
                                            <p style={{fontSize:"1.2em",marginTop:"-20px"}}>{this.state.speakerneutre}%</p>

                                          </div>
                                          
                                        </div>
                                        
                                      </div>

                                      <div className="champpositifmodal" style={{padding:'5px'}}>

                                        <div className="row">

                                          <div className="col">
                                            
                                            <p style={{position:"relative"}}> {this.state.lng ==='FR'? 'Positif': 'Positive'}</p>
                                            <p style={{fontSize:"1.2em",marginTop:"-20px"}}>{this.state.speakerpositif}%</p>

                                          </div>
                                         
                                        </div>
                                      
                                      </div>

                                    </div>

                                  </div>

                                </div>

                                <p style={{color:'black', marginTop:'20px',fontWeight:'bold', fontSize:'1.5em',textAlign:'center'}} >{this.state.speaker1name}</p>                           
                                          
                                <div className='listesentiment'>
                                  <PieChart width={350} height={300}
                                        margin={{
                                          top: 0,
                                          right: 0,
                                          left: 0,
                                          bottom: 20,
                                        }}
                                      >                                     
                                    <Pie  data={Object.values(this.state.speaker1sentiment)} innerRadius={60} outerRadius={80} paddingAngle={5} cx="50%"cy="50%" labelLine={true}  label={renderCustomizedLabel} fill="#8884d8" dataKey="value" >
                                      {data.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={COLORSCHART[index % COLORSCHART.length]} />
                                        
                                      ))}
                                      <Label value="Speaker 1" offset={0} position="center" style={{fontWeight:'bold', fontSize:'1.2em'}}/>
                                      {/*<LabelList dataKey="name" position="insideTop" angle="0" fill="#333333" />*/}
                                    </Pie>
                                    <Tooltip/>
                                    {/*<Legend />*/}
                                  </PieChart>
                                </div>

                                <p style={{color:'black', fontWeight:'bold', fontSize:'1.5em',textAlign:'center',marginTop:"20px"}}>{this.state.lng ==="FR" ? 'Sentiment de l\'appel' : 'Call sentiment'}</p>

                                <div className='listesentiment' style={{marginTop:"20px"}}> 

                                  {/*<LineChart width={350} height={250} data={Object.values(this.state.speaker1)} >
                                      <CartesianGrid strokeDasharray="3 3" />
                                      <XAxis dataKey="starttime" />
                                    <YAxis domain={[0.00, 1.00]}/>
                                    <Tooltip />
                                    <Line type="monotone" dataKey="Positif" stroke="#00934c" fill="#00934c" activeDot={{r: 8, onClick: (event, payload) => this.scrolltospeech(payload)}} />
                                    <Line type="monotone" dataKey="Neutre" stroke="#e89900"  activeDot={{r: 8, onClick: (event, payload) => this.scrolltospeech(payload)}}/>
                                    <Line type="monotone" dataKey="Negatif" stroke="#e40001"  activeDot={{r: 8, onClick: (event, payload) => this.scrolltospeech(payload)}}/>
                                      </LineChart>*/}
                                    
                                    <BarChart width={350} height={300} 
                                    margin={{
                                      top: 0,
                                      right: 60,
                                      left: 20,
                                      bottom: 5
                                    }}
                                    data={Object.values(this.state.speaker1)}
                                  >
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="starttime" />
                                    <YAxis domain={[0, 100]}/>
                                    <Tooltip />
                                    <Bar dataKey="Positif" stackId="a" fill="#198754" onClick={this.scrolltospeech}/>
                                    <Bar dataKey="Neutre" stackId="a" fill="#e89900" onClick={this.scrolltospeech}/>
                                    <Bar dataKey="Negatif" stackId="a" fill="#dc3545" onClick={this.scrolltospeech}/>
                                  </BarChart>

                                </div>
                                
                                {/* 
                                  <hr className="lineseparatorspeaker"></hr>
                                */}

                                {this.state.speaker2.length>0 ?

                                <div>

                                    <p style={{color:'#2A83E8', textAlign:'center', fontWeight:'bold', fontSize:'1.5em',marginTop:'40px'}} >{this.state.speaker2name}</p>                       
                                        
                                        <div className='listesentiment'>
                                          <PieChart width={350} height={300}
                                                margin={{
                                                  top: 0,
                                                  right: 0,
                                                  left: 0,
                                                  bottom: 20,
                                                }}
                                              >          
                                            <Pie  data={Object.values(this.state.speaker2sentiment)} innerRadius={60} outerRadius={80} paddingAngle={5} cx="50%"cy="50%" labelLine={true}  label={renderCustomizedLabel} fill="#8884d8" dataKey="value" >
                                              {data.map((entry, index) => (
                                                <Cell key={`cell-${index}`} fill={COLORSCHART[index % COLORSCHART.length]} />
                                                
                                              ))}
                                              <Label value="Speaker 2" offset={0} position="center" style={{fontWeight:'bold', fontSize:'1.2em',color:'#2A83E8'}} fill="#2A83E8"/>
                                              {/*<LabelList dataKey="name" position="insideTop" angle="0" fill="#333333" />*/}
                                            </Pie>
                                            <Tooltip/>
                                            {/*<Legend />*/}
                                          </PieChart>
                                        </div>
        
                                        <p style={{color:'#2A83E8', fontWeight:'bold', fontSize:'1.5em',textAlign:'center',marginTop:"20px"}}>{this.state.lng ==="FR" ? 'Sentiment de l\'appel' : 'Call sentiment'}</p>
        
                                        <div className='listesentiment' style={{marginTop:"20px"}}> 
        
                                        <BarChart width={350} height={300} 
                                            margin={{
                                              top: 0,
                                              right: 60,
                                              left: 20,
                                              bottom: 5
                                            }}
                                            data={Object.values(this.state.speaker2)}
                                          >
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <XAxis dataKey="starttime" />
                                            <YAxis domain={[0, 100]}/>
                                            <Tooltip />
                                            <Bar dataKey="Positif" stackId="a" fill="#198754" onClick={this.scrolltospeech}/>
                                            <Bar dataKey="Neutre" stackId="a" fill="#e89900" onClick={this.scrolltospeech}/>
                                            <Bar dataKey="Negatif" stackId="a" fill="#dc3545" onClick={this.scrolltospeech}/>
                                          </BarChart>
        
                                        </div>

                                </div>
                                

                                :

                                null

                                }

                                {this.state.speaker1.length>0 ?

                                  <div>

                                      <div >
                                        <p style={{color:'black', fontWeight:'bold', fontSize:'1.5em', textAlign:'center', marginTop:'20px'}}>{this.state.lng ==="FR" ? 'Transcription ' : 'Transcription '}</p>
                                      </div>

                                      {this.state.speakers.map((row) => (

                                        <div key={row.iddiarization} style={{marginTop:'20px'}}>

                                            <div> {row.speaker === '1' ?
                                                                    
                                              <div  id={parseInt(row.iddiarization)}>

                                                  <div style={{textAlign:'center'}}>
                                                    <p >{row.starttime}</p>
                                                  </div>

                                                      <div className="container">

                                                          <div className="row">
                                                            
                                                            <div className="col d-flex justify-content-center">
                                                              <p className="champnegatifsentimentmodalmobile">
                                                                {parseInt(parseFloat(row.negatif)*100)}%
                                                              </p>
                                                            </div>

                                                            <div className="col d-flex justify-content-center">
                                                              <p className="champneutresentimentmodalmobile">
                                                                {parseInt(parseFloat(row.neutre)*100)}%
                                                              </p>
                                                            </div>

                                                            <div className="col d-flex justify-content-center">
                                                              <p className="champpositifsentimentmodalmobile">
                                                                {parseInt(parseFloat(row.positif)*100)}%
                                                              </p>
                                                            </div>

                                                          </div>

                                                        </div>

                                                <div className="row">

                                                  <div className="col-11">
                                                    <div style={{width:'100%', textAlign:'left'}} className='textleft'>
                                                      <p style={{lineHeight:'20px', paddingRight:"30px"}}>{row.phrase}</p>
                                                    </div>
                                                  </div>

                                                  <div className="col-1">
                                                    {row.sentimentspeaker ==='positive' ?

                                                    <div className="btnpositifmodal btn-sentiment leftunsaidmobile">
                                                      <div className="centerunsaid">S1</div>
                                                    </div>
                                                    :

                                                    null

                                                    }

                                                    {row.sentimentspeaker ==='neutral' ?

                                                    <div className="btnneutremodal btn-sentiment leftunsaidmobile">
                                                      <div className="centerunsaid">S1</div>
                                                    </div>

                                                    :

                                                    null

                                                    }

                                                    {row.sentimentspeaker ==='mixed' ?

                                                    <div className="btnmixed btn-sentiment leftunsaidmobile">
                                                      <div className="centerunsaid">S1</div>
                                                    </div>

                                                    :

                                                    null

                                                    }

                                                    {row.sentimentspeaker ==='negative' ?

                                                    <div className="btnnegatifmodal btn-sentiment leftunsaidmobile">
                                                      <div className="centerunsaid">S1</div>
                                                    </div>

                                                    :

                                                    null

                                                    }

                                                  </div>

                                              </div>

                                              <hr className="lineseparator"></hr>                                               
                                                
                                            </div>

                                              :
                                                                    
                                            <div  id={parseInt(row.iddiarization)}>

                                              <div className="container">

                                                <div className="col-sm" style={{textAlign:'center'}}>
                                                  <p >{row.starttime}</p>
                                                </div>

                                                    <div className="container">

                                                        <div className="row">

                                                          <div className="col d-flex justify-content-center">
                                                            <p className="champnegatifsentimentmodalmobile">
                                                              {parseInt(parseFloat(row.negatif)*100)}%
                                                            </p>
                                                          </div>

                                                          <div className="col d-flex justify-content-center">
                                                            <p className="champneutresentimentmodalmobile">
                                                              {parseInt(parseFloat(row.neutre)*100)}%
                                                            </p>
                                                          </div>

                                                          <div className="col d-flex justify-content-center">
                                                            <p className="champpositifsentimentmodalmobile">
                                                              {parseInt(parseFloat(row.positif)*100)}%
                                                            </p>
                                                          </div>

                                                        </div>

                                                    </div>

                                              </div>

                                              <div className="row">

                                                                                            
                                                <div className="col-1">
                                                  {row.sentimentspeaker ==='positive' ?

                                                  <div className="btnpositifmodal btn-sentiment rightunsaidmobile">
                                                    <div className="centerunsaid">S2</div>
                                                  </div>
                                                  :

                                                  null

                                                  }

                                                  {row.sentimentspeaker ==='neutral' ?

                                                  <div className="btnneutremodal btn-sentiment rightunsaidmobile">
                                                    <div className="centerunsaid">S2</div>
                                                  </div>

                                                  :

                                                  null

                                                  }

                                                  {row.sentimentspeaker ==='mixed' ?

                                                  <div className="btnmixed btn-sentiment rightunsaidmobile">
                                                    <div className="centerunsaid">S2</div>
                                                  </div>

                                                  :

                                                  null

                                                  }

                                                  {row.sentimentspeaker ==='negative' ?

                                                  <div className="btnnegatifmodal btn-sentiment rightunsaidmobile">
                                                    <div className="centerunsaid">S2</div>
                                                  </div>

                                                  :

                                                  null

                                                  }
                                                  
                                                </div>

                                                <div className="col-11">
                                                  <div style={{width:'100%', textAlign:'left'}} className='textright'>
                                                    <p style={{lineHeight:'20px', paddingLeft:"20px"}}>{row.phrase}</p>
                                                  </div>
                                                  
                                                </div>

                                            </div>    

                                            <hr className="lineseparator"></hr>                        
                                                                                                    
                                            </div>

                                          }
                                          
                                          </div>

                                          
                                        </div>

                                        ))}


                                  </div>

                                  :

                                  null

                                }







                        </MobileView>

                       

                        

                          


                    </Modal.Body>
                    </Modal>

                    </div>

                    :

                    null

                  }

                  {this.state.isModalupdate === true ?

                  <Modal

                  show={this.state.isModalupdate}
                  onHide={ () => this.setState({isModalupdate:false,thematique :'', idaudioselect :0 , speaker1: '', speaker2: '',soundlink: '', soundname : '',})}
                  dialogClassName="modal-dialog"
                  aria-labelledby="example-custom-modal-styling-title" centered
                  >
                    <Modal.Body>

                    <p style={{color:'#2A83E8', fontWeight:'bold', fontSize:isBrowser?'1.5em':'1em',textAlign:"center", margin:0}}>{this.state.soundname}</p>

                    <ReactAudioPlayer
                      src={this.state.soundlink}
                      controls
                      volume
                      className="audioplayermodal"
                      />

                      <div className="d-flex flex-column align-items-center">

                         

                          {/*
                          <p style={{fontWeight:"bold", textAlign:"center"}}>{this.state.lng ==="FR" ? 'Thématique :' : 'Thematique:'}&nbsp;</p>
                          
                          <label>
                            <textarea type="textarea" name="thematique" rows={3} cols={50} className="w-100" value={this.state.thematique} onChange={(e) => this.setState({ thematique: e.target.value})}/>
                          </label>
                           */}
                          
                      
                          <p style={{fontWeight:"bold", textAlign:"center", marginTop:"20px"}}>Speaker :&nbsp;</p>
                          
                          
                          <p style={{fontWeight:"bold"}}>Speaker 1  :&nbsp;</p>
                          <input  className="w-100" type="text" placeholder="Speaker 1" ref="speaker1" value={this.state.speaker1} onChange={(e) => this.setState({ speaker1: e.target.value})}/>

                          <p style={{fontWeight:"bold", marginTop:"20px"}}>Speaker 2  :&nbsp;</p>
                          <input  className="w-100" type="text" placeholder="Speaker 2" ref="speaker2" value={this.state.speaker2} onChange={(e) => this.setState({ speaker2: e.target.value})}/>
                          
                          
                          
                     

                        <div style={{marginTop:"20px" }}>
                                          
                              <Button className="btn" style={{ marginRight:"20px", width:'130px'}} onClick={ () => this.setState({isModalupdate:false})} variant="primary">{this.state.lng ==="FR" ? 'Fermer' : 'Close'}</Button>
                              <Button className="btn" style={{  width:'130px'}} onClick={() => this.updatesound()} variant="success">{this.state.lng ==="FR" ? 'Mettre à jour' : 'Update'}</Button>

                          </div>

                      </div>

                  </Modal.Body>
                  </Modal>

                  :

                  null

                  }

                  {this.state.isModaladd === true ?

                  <Modal

                  show={this.state.isModaladd}
                  onHide={ () => this.setState({isModaladd:false})}
                  dialogClassName="modal-dialog"
                  aria-labelledby="example-custom-modal-styling-title" centered
                  >
                    <Modal.Body>
                                      
                        <div className="d-flex flex-column align-items-center" style={{margin:"20px"}}>

                            <label htmlFor="filePicker" style={{background:"#2A83E8", padding:"5px 10px", marginTop:'20px', color:'white', borderRadius:'5px',textAlign:'center' }}> {this.state.lng ==='FR'? 'Choisir fichier': 'Choose file'} </label>
                            <input id="filePicker" style={{visibility:"hidden"}} type={"file"} onChange={this.onFileChange} accept="audio/*,.gsm"/>

                            {this.state.soundfilename === null ?

                                <div >
                                    {this.state.lng ==='FR'? 'Aucun fichier selectionné': 'No file chosen'}                     
                                </div>

                              :

                                this.state.soundfilename

                            }

                            <p>{this.state.lng ==='FR'? 'Langue de l\'audio': 'Audio language'}  :</p>
                            <div style={{margin:'10px'}} className='d-flex justify-content-center'>
                              <Form.Select style={{height:'40px', width:'200px' }} value={this.state.soundlng} onChange={this.selectlng}>
                                {this.state.listlng.map((lng) => (
                                  <option value={lng.lng}>{this.state.lng ==='FR'? lng.languefr: lng.langueen}</option>
                                ))}
                              </Form.Select>
                            </div>

                            <div style={{margin:'10px'}}>

                            {this.state.soundlng !== '' ?

                              <button onClick={this.onFileUpload} style={{marginTop:'70px'}} className="btn btn-primary"> 
                                {this.state.lng ==='FR'? 'TELEGARGER DANS LE CLOUD ': 'UPLOADED TO THE CLOUD'} 
                              </button>

                              :

                              null

                            }

                            {this.state.loadershow === true ?
                          

                              <Loader type="spinner-cub" bgColor={'#2A83E8'} title={this.state.textloading} color={'#2A83E8'} size={100} />
                                
          
                              :
        
                              null
        
                            }
                              
                            </div>                            
                            
                        </div>

                  </Modal.Body>
                  </Modal>

                  :

                  null

                  }

                  {this.state.isModaldelete === true ?

                  <Modal

                  show={this.state.isModaldelete}
                  onHide={ () => this.setState({isModaldelete:false,soundname :'', idaudioselect :0})}
                  dialogClassName="modal-dialog"
                  aria-labelledby="example-custom-modal-styling-title" centered
                  >
                    <Modal.Body>
                                
                    <div className="d-flex flex-row justify-content-center">
                                      
                        <div className="d-flex flex-column" style={{margin:"20px"}}>
                            
                            <div className="d-flex flex-column">
                                <p style={{fontWeight:"bold", textAlign:"center", marginTop:"20px"}}>{this.state.lng ==='FR'? 'Voulez-vous supprimer': 'Would you like to delete'} &nbsp;</p>
                                <p style={{fontWeight:"bold", textAlign:"center", marginTop:"20px"}}>{this.state.soundname} ?&nbsp;</p>
                                <form>

                                
                                
                              </form>
                            </div>
                            
                        </div>

                        

                    </div>  

                    <div className='d-flex flex-row justify-content-center'>
                                            
                          <Button className="btn"style={{ margin:"10px", width:'100px'}} onClick={ () => this.setState({isModaldelete:false,soundname :'', idaudioselect :0})} variant="primary">{this.state.lng ==='FR'? 'Fermer': 'Close'}</Button>
                          <Button className="btn" style={{ margin:"10px",right: 0, width:'100px'}} onClick={() => this.deletesound()} variant="danger">{this.state.lng ==='FR'? 'Supprimer': 'Delete'}</Button>

                    </div>  

                  </Modal.Body>
                  </Modal>

                  :

                  null

                  }


                  {this.state.isModalrestore === true ?

                  <Modal

                  show={this.state.isModalrestore}
                  onHide={ () => this.setState({isModalrestore:false,soundname :'', idaudioselect :0})}
                  dialogClassName="modal-dialog"
                  aria-labelledby="example-custom-modal-styling-title" centered
                  >
                    <Modal.Body>
                                
                    <div className="d-flex flex-row justify-content-center">
                                      
                        <div className="d-flex flex-column" style={{margin:"20px"}}>
                            
                            <div className="d-flex flex-column">
                                <p style={{fontWeight:"bold", textAlign:"center", marginTop:"20px"}}>{this.state.lng ==='FR'? 'Voulez-vous restaurer': 'Would you like to restore'} &nbsp;</p>
                                <p style={{fontWeight:"bold", textAlign:"center", marginTop:"20px"}}>{this.state.soundname} ?&nbsp;</p>
                                <form>

                                
                                
                              </form>
                            </div>
                            
                        </div>

                        

                    </div>  

                    <div className='d-flex flex-row justify-content-center'>
                                            
                          <Button className="btn"style={{ margin:"10px", width:'100px'}} onClick={ () => this.setState({isModalrestore:false,soundname :'', idaudioselect :0})} variant="primary">{this.state.lng ==='FR'? 'Fermer': 'Close'}</Button>
                          <Button className="btn" style={{ margin:"10px",right: 0, width:'100px'}} onClick={() => this.restoresound()} variant="danger">{this.state.lng ==='FR'? 'Restaurer': 'Restore'}</Button>

                    </div>  

                  </Modal.Body>
                  </Modal>

                  :

                  null

                  }

                  {this.state.isModalthematique === true ?

                      <Modal
                      
                      show={this.state.isModalthematique}
                      onHide={ () => this.setState({isModalthematique:false})}
                      dialogClassName="modal-dialog"
                      aria-labelledby="example-custom-modal-styling-title" centered
                      >
                        <Modal.Body>
                                    
                        <div className="d-flex flex-row">
                                          
                            <div className="d-flex flex-column" style={{margin:"20px"}}>
                                
                                <div className="d-flex flex-column">
                                    <p style={{fontWeight:"bold"}}>{this.state.lng ==="FR" ? 'Thématique :' : 'Thematique'}&nbsp;</p>
                                    <form>
                                    <label>
                                      <textarea type="textarea" name="thematique" rows={5} style={{width:'400px'}} value={this.state.thematique} onChange={(e) => this.setState({ thematique: e.target.value})}/>
                                    </label>
                                    
                                    <div className='row text-white d-flex align-items-center'>
                                                
                                        <Button className="btn"style={{ margin:"20px", width:'150px'}} onClick={ () => this.setState({isModalthematique:false})} variant="primary">{this.state.lng ==="FR" ? 'Fermer' : 'Close'}</Button>
                                        <Button className="btn" style={{ margin:"10px",right: 0, width:'150px'}} onClick={() => this.themeatiquesound()} variant="success">{this.state.lng ==="FR" ? 'Mettre à jour' : 'Update'}</Button>

                                    </div>
                                    
                                  </form>
                                </div>
                                
                            </div>

                        </div>    

                      </Modal.Body>
                      </Modal>

                      :

                      null

                  }

                  {this.state.isModaltranscription === true ?

                  <Modal

                  show={this.state.isModaltranscription}
                  /*onHide={ () => this.setState({isModaltranscription:false})}*/
                  dialogClassName="modal-dialog"
                  aria-labelledby="example-custom-modal-styling-title" centered
                  >
                    <Modal.Body>
                                      
                        <div className="d-flex flex-column align-items-center" style={{margin:"20px"}}>

                            {this.state.loadershow === true ?
                          

                              <Loader type="spinner-cub" bgColor={'#2A83E8'} title={this.state.textloading} color={'#2A83E8'} size={100} />
                                

                              :

                              null

                            }
                    
                            
                        </div>

                  </Modal.Body>
                  </Modal>

                  :

                  null

                  }

                  {this.state.isModalspeaker === true ?

                  <Modal
                  
                  show={this.state.isModalspeaker}
                  onHide={ () => this.setState({isModalspeaker:false})}
                  dialogClassName="modal-dialog"
                  aria-labelledby="example-custom-modal-styling-title" centered
                  >
                    <Modal.Body>
                                
                    <div className="d-flex flex-row">
                                      
                        <div className="d-flex flex-column" style={{margin:"20px"}}>
                            
                            <div className="d-flex flex-column">
                                <p style={{fontWeight:"bold", textAlign:"center", marginTop:"20px"}}>SPEAKER :&nbsp;</p>
                                <form>
                                
                                <p style={{fontWeight:"bold"}}>Speaker 1  :&nbsp;</p>
                                <input  type="text" placeholder="Speaker 1" ref="speaker1" value={this.state.speaker1} onChange={(e) => this.setState({ speaker1: e.target.value})}/>

                                <p>&nbsp;</p>
                                <p style={{fontWeight:"bold"}}>Speaker 2  :&nbsp;</p>
                                <input  type="text" placeholder="Speaker 2" ref="speaker2" value={this.state.speaker2} onChange={(e) => this.setState({ speaker2: e.target.value})}/>
                                
                                <div className='row text-white d-flex align-items-center'>
                                            
                                      <Button className="btn"style={{ margin:"20px", width:'150px'}} onClick={ () => this.setState({isModalspeaker:false})} variant="primary">{this.state.lng ==="FR" ? 'Fermer' : 'Close'}</Button>
                                      <Button className="btn" style={{ margin:"10px",right: 0, width:'150px'}} onClick={() => this.speakermodalsound()} variant="success">{this.state.lng ==="FR" ? 'Mettre à jour' : 'Update'}</Button>

                                </div>
                                
                              </form>
                            </div>
                            
                        </div>

                    </div>    

                  </Modal.Body>
                  </Modal>

                  :

                  null

                  }


                  {this.state.speaker1.length>0?


                  <BrowserView>

                  <div ref={el => (this.componentRef = el)} >


                      {/*<p style={{color:'#2A83E8', fontWeight:'bold', fontSize:'1.5em',textAlign:"center"}}>{this.state.soundname}</p>*/}

                      
                                              
                          <div className="row">

                            <div className="col-sm thematiquevalue" style={{width:'92%', marginLeft:'4%', marginRight:'4%', marginTop:'4%'}}>
                              <div className="row">
                                  <div style={{width:'18%'}}>
                                  <img src={logo} style={{width:'100px', height:'70px'}}/>
                                  {/*<span style={{color:'white',textAlign:"center"}}><ForumIcon sx={{ width: '100%', height:'100%' }}/></span>*/}
                                  </div>

                                  <div style={{width:'82%'}} className="d-flex flex-column align-items-center ">
                                    <p style={{color:'white', fontWeight:'bold', marginTop:'10px'}}>{this.state.soundname}</p>
                                    {/*<p style={{color:'white', fontWeight:'bold', fontSize:'1em',textAlign:"left", marginTop:'10px', textDecoration:'underline'}}>{this.state.lng ==="FR" ? 'Thématique' : 'Thematique'}</p>*/}
                                    {/*<hr className="lineseparator"></hr>*/}
                                    <p style={{color:'white',textAlign:"left"}}>{this.state.lng ==="FR" ? 'Résumé: ' : 'Summarization: '}{this.state.summarization}</p>
                                  </div>

                              </div>
                              
                            </div>


                            
                            {/*<div className="col-sm">
                              
                              <p style={{color:'black', fontWeight:'bold', fontSize:'1.5em',textAlign:"center"}}>{this.state.lng ==="FR" ? 'Audio player' : 'Audio player'}</p>

                                  

                                  <ReactToPrint
                                    trigger={() => (
                                      <a className="ant-btn ant-btn-primary" href="#">
                                        {this.state.lng ==='FR'? 'Imprimer': 'Print'}
                                      </a>
                                    )}
                                    content={() => this.componentRef}
                                    />
                                                          
                            </div>*/}

                            <div className="col-sm" >

                            <p style={{color:'black', fontWeight:'bold', fontSize:'1.5em',textAlign:"center"}}>{this.state.lng ==="FR" ? 'Sentiment global de l\'appel' : 'Global call sentiment'}</p>

                              <div className='sentimentglobal' style={{marginLeft:'5px',marginRight:'5px',marginTop:'0px', fontWeight:'bold'}}>

                                <div className="champnegatifmodal" style={{padding:'5px'}}>

                                  <div className="row">
                                    <div className="col">
                                      
                                      <p style={{position:"relative"}}> {this.state.lng ==='FR'? 'Négatif': 'Negative'}</p>
                                      <p style={{fontSize:"1.2em",marginTop:"-20px"}}>{this.state.speakernegatif}%</p>

                                    </div>
                                    <div className="col">
                                      <span style={{color:'black',textAlign:"center",fontSize:'1.5em', opacity:0.5}} ><BarChartIcon sx={{ width: '50px', height:'50px' }} /></span>
                                    </div>
                                  </div>
                                
                                    
                                
                                </div>

                                <div className="champneutremodal" style={{padding:'5px'}}>

                                  <div className="row">
                                    <div className="col">
                                      
                                      <p style={{position:"relative"}}> {this.state.lng ==='FR'? 'Neutre': 'Neutral'}</p>
                                      <p style={{fontSize:"1.2em",marginTop:"-20px"}}>{this.state.speakerneutre}%</p>

                                    </div>
                                    <div className="col">
                                      <span style={{color:'black',textAlign:"center",fontSize:'1.5em', opacity:0.5}} ><BarChartIcon sx={{ width: '50px', height:'50px' }} /></span>
                                    </div>
                                  </div>
                                  
                                </div>

                                <div className="champpositifmodal" style={{padding:'5px'}}>

                                  <div className="row">
                                    <div className="col">
                                      
                                      <p style={{position:"relative"}}> {this.state.lng ==='FR'? 'Positif': 'Positive'}</p>
                                      <p style={{fontSize:"1.2em",marginTop:"-20px"}}>{this.state.speakerpositif}%</p>

                                    </div>
                                    <div className="col">
                                      <span style={{color:'black',textAlign:"center",fontSize:'1.5em', opacity:0.5}} ><BarChartIcon sx={{ width: '50px', height:'50px' }} /></span>
                                    </div>
                                  </div>
                                
                                </div>

                              </div>

                            </div>

                          </div>

                        {this.state.speaker1.length>0 ?

                          <div >

                            <div className="container">

                              <div className="row">

                                <div className="col-6">

                                  <p style={{color:'black', marginTop:'0px',fontWeight:'bold', fontSize:'1.5em',textAlign:'center', height:"60px"}} >S1 : {this.state.speaker1name}</p>                           
                                    
                                    <div >
                                      <PieChart width={400} height={250}
                                          
                                          >                                     
                                        <Pie  data={Object.values(this.state.speaker1sentiment)} innerRadius={60} outerRadius={80} paddingAngle={5} cx="50%"cy="50%" labelLine={true}  label={renderCustomizedLabel} fill="#8884d8" dataKey="value" >
                                          {data.map((entry, index) => (
                                            <Cell key={`cell-${index}`} fill={COLORSCHART[index % COLORSCHART.length]} />
                                            
                                          ))}
                                          <Label value="Speaker 1" offset={0} position="center" style={{fontWeight:'bold', fontSize:'1.2em'}}/>
                                          {/*<LabelList dataKey="name" position="insideTop" angle="0" fill="#333333" />*/}
                                        </Pie>
                                        <Tooltip/>
                                        {/*<Legend />*/}
                                      </PieChart>
                                    </div>

                                    <p style={{color:'black', fontWeight:'bold', fontSize:'1.5em',textAlign:'center'}}>{this.state.lng ==="FR" ? 'S1 : Sentiment de l\'appel' : 'S1 : Call sentiment'}</p>

                                    <div style={{marginTop:"20px"}}> 

                                      {/*<LineChart width={350} height={250} data={Object.values(this.state.speaker1)} >
                                          <CartesianGrid strokeDasharray="3 3" />
                                          <XAxis dataKey="starttime" />
                                        <YAxis domain={[0.00, 1.00]}/>
                                        <Tooltip />
                                        <Line type="monotone" dataKey="Positif" stroke="#00934c" fill="#00934c" activeDot={{r: 8, onClick: (event, payload) => this.scrolltospeech(payload)}} />
                                        <Line type="monotone" dataKey="Neutre" stroke="#e89900"  activeDot={{r: 8, onClick: (event, payload) => this.scrolltospeech(payload)}}/>
                                        <Line type="monotone" dataKey="Negatif" stroke="#e40001"  activeDot={{r: 8, onClick: (event, payload) => this.scrolltospeech(payload)}}/>
                                          </LineChart>*/}
                                        
                                        <BarChart width={300} height={250} 
                                      
                                        data={Object.values(this.state.speaker1)}
                                      >
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="starttime" />
                                        <YAxis domain={[0, 100]}/>
                                        <Tooltip />
                                        <Bar dataKey="Positif" stackId="a" fill="#198754" onClick={this.scrolltospeech}/>
                                        <Bar dataKey="Neutre" stackId="a" fill="#e89900" onClick={this.scrolltospeech}/>
                                        <Bar dataKey="Negatif" stackId="a" fill="#dc3545" onClick={this.scrolltospeech}/>
                                      </BarChart>

                                    </div>
                                    
                                </div>

                                <div className="col-6" >

                                  {this.state.speaker2.length>0 ?

                                    <div>

                                      <p style={{color:'#2A83E8', marginTop:'0px',fontWeight:'bold', fontSize:'1.5em',textAlign:'center', height:"60px"}} >S2 : {this.state.speaker2name}</p>                       
                                  
                                      <div >
                                        <PieChart width={400} height={250}
                                              
                                            >          
                                          <Pie  data={Object.values(this.state.speaker2sentiment)} innerRadius={60} outerRadius={80} paddingAngle={5} cx="50%"cy="50%" labelLine={true}  label={renderCustomizedLabel} fill="#8884d8" dataKey="value" >
                                            {data.map((entry, index) => (
                                              <Cell key={`cell-${index}`} fill={COLORSCHART[index % COLORSCHART.length]} />
                                              
                                            ))}
                                            <Label value="Speaker 2" offset={0} position="center" style={{fontWeight:'bold', fontSize:'1.2em',color:'#2A83E8'}} fill="#2A83E8"/>
                                            {/*<LabelList dataKey="name" position="insideTop" angle="0" fill="#333333" />*/}
                                          </Pie>
                                          <Tooltip/>
                                          {/*<Legend />*/}
                                        </PieChart>
                                      </div>

                                      <p style={{color:'#2A83E8', fontWeight:'bold', fontSize:'1.5em',textAlign:'center'}}>{this.state.lng ==="FR" ? 'S2 : Sentiment de l\'appel' : 'S2 : Call sentiment'}</p>

                                      <div  style={{marginTop:"20px"}}> 

                                      <BarChart width={300} height={250} 
                                        
                                          data={Object.values(this.state.speaker2)}
                                        >
                                          <CartesianGrid strokeDasharray="3 3" />
                                          <XAxis dataKey="starttime" />
                                          <YAxis domain={[0, 100]}/>
                                          <Tooltip />
                                          <Bar dataKey="Positif" stackId="a" fill="#198754" onClick={this.scrolltospeech}/>
                                          <Bar dataKey="Neutre" stackId="a" fill="#e89900" onClick={this.scrolltospeech}/>
                                          <Bar dataKey="Negatif" stackId="a" fill="#dc3545" onClick={this.scrolltospeech}/>
                                        </BarChart>

                                      </div>

                                    
                                    </div>
                                
                                    :
                                    
                                    null

                                  }

                                </div>
                        
                              </div>

                            </div>


                                  <div >
                                    <p style={{color:'black', fontWeight:'bold', fontSize:'1.2em', textAlign:'center', marginTop:'0px'}}>{this.state.lng ==="FR" ? 'Transcription ' : 'Transcription '}</p>
                                  </div>

                                      {this.state.speakers.map((row) => (

                                                <div style={{marginTop:'20px'}}>

                                                    <div> {row.speaker === '1' ?
                                                                            
                                                      <div >

                                                        <div className="container" >

                                                          <div className="row" >

                                                            <div className="col-sm">

                                                            <div className="col-sm" style={{textAlign:'center'}}>
                                                              <p >{row.starttime}</p>
                                                            </div>

                                                                <div class="container d-flex flex-column align-items-center">
                                                                  <div class="row">
                                                                    <div class="col-4">
                                                                      <p className="champnegatifsentimentmodal" style={{margin:'5%'}}>
                                                                        {parseInt(parseFloat(row.negatif)*100)}%
                                                                      </p>
                                                                    </div>
                                                                    <div class="col-4">
                                                                      <p className="champneutresentimentmodal" style={{margin:'5%'}}>
                                                                        {parseInt(parseFloat(row.neutre)*100)}%
                                                                      </p>
                                                                    </div>
                                                                    <div class="col-4">
                                                                      <p className="champpositifsentimentmodal" style={{margin:'5%'}}>
                                                                        {parseInt(parseFloat(row.positif)*100)}%
                                                                      </p>
                                                                    </div>
                                                                  </div>
                                                                </div>

                                                            </div>
                                                            
                                                            <div className="col-sm">
                                                              
                                                            </div>

                                                          </div>

                                                        </div>

                                                        <div className="row" style={{marginTop:"20px"}}>

                                                          <div className="col-11">
                                                            <div style={{width:'100%', textAlign:'left', marginLeft:'20px'}} className='textleft'>
                                                              <p style={{lineHeight:'20px'}}>{row.phrase}</p>
                                                            </div>
                                                          </div>

                                                          <div className="col-1">
                                                            {row.sentimentspeaker ==='positive' ?

                                                            <div className="btnpositifmodal btn-sentiment leftunsaid">
                                                              <div className="centerunsaid">S1</div>
                                                            </div>
                                                            :

                                                            null

                                                            }

                                                            {row.sentimentspeaker ==='neutral' ?

                                                            <div className="btnneutremodal btn-sentiment leftunsaid">
                                                              <div className="centerunsaid">S1</div>
                                                            </div>

                                                            :

                                                            null

                                                            }

                                                            {row.sentimentspeaker ==='mixed' ?

                                                            <div className="btnmixed btn-sentiment leftunsaid">
                                                              <div className="centerunsaid">S1</div>
                                                            </div>

                                                            :

                                                            null

                                                            }

                                                            {row.sentimentspeaker ==='negative' ?

                                                            <div className="btnnegatifmodal btn-sentiment leftunsaid">
                                                              <div className="centerunsaid">S1</div>
                                                            </div>

                                                            :

                                                            null

                                                            }

                                                          </div>

                                                      </div>

                                                      <hr className="lineseparator"></hr>                                               
                                                        
                                                    </div>

                                                      :
                                                                            
                                                    <div  >

                                                      <div className="container">

                                                        <div className="row">

                                                          <div className="col-sm">

                                                          </div>
                                                          
                                                          <div className="col-sm" style={{textAlign:'center'}}>
                                                            <p >{row.starttime}</p>
                                                          </div>

                                                          <div className="col-sm">
                                                            <div class="container d-flex flex-column align-items-center">
                                                                <div class="row">
                                                                  <div class="col">
                                                                    <p className="champnegatifsentimentmodal" style={{margin:'5%'}}>
                                                                      {parseInt(parseFloat(row.negatif)*100)}%
                                                                    </p>
                                                                  </div>
                                                                  <div class="col">
                                                                    <p className="champneutresentimentmodal" style={{margin:'5%'}}>
                                                                      {parseInt(parseFloat(row.neutre)*100)}%
                                                                    </p>
                                                                  </div>
                                                                  <div class="col">
                                                                    <p className="champpositifsentimentmodal" style={{margin:'5%'}}>
                                                                      {parseInt(parseFloat(row.positif)*100)}%
                                                                    </p>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                          </div>

                                                        </div>

                                                      </div>

                                                      <div className="row" style={{marginTop:"20px"}}>

                                                      
                                                        
                                                        <div className="col-1">
                                                          {row.sentimentspeaker ==='positive' ?

                                                          <div className="btnpositifmodal btn-sentiment rightunsaidprint">
                                                            <div className="centerunsaid">S2</div>
                                                          </div>
                                                          :

                                                          null

                                                          }

                                                          {row.sentimentspeaker ==='neutral' ?

                                                          <div className="btnneutremodal btn-sentiment rightunsaidprint">
                                                            <div className="centerunsaid">S2</div>
                                                          </div>

                                                          :

                                                          null

                                                          }

                                                          {row.sentimentspeaker ==='mixed' ?

                                                          <div className="btnmixed btn-sentiment rightunsaidprint">
                                                            <div className="centerunsaid">S2</div>
                                                          </div>

                                                          :

                                                          null

                                                          }

                                                          {row.sentimentspeaker ==='negative' ?

                                                          <div className="btnnegatifmodal btn-sentiment rightunsaidprint">
                                                            <div className="centerunsaid">S2</div>
                                                          </div>

                                                          :

                                                          null

                                                          }
                                                          
                                                        </div>

                                                        <div className="col-10" >
                                                          <div style={{width:'100%', textAlign:'center'}} className='textright'>
                                                            <p style={{lineHeight:'20px'}}>{row.phrase}</p>
                                                          </div>
                                                          
                                                        </div>

                                                    </div>    

                                                    <hr className="lineseparator"></hr>                        
                                                                                                            
                                                    </div>

                                                  }
                                                  
                                                  </div>

                                                  
                                                </div>

                                      ))}

                                    <div style={{height:'50px'}} id="lastline">
                                        <p style={{color:'transparent'}}>.</p>
                                    </div>

                                                                                                                                  
                          </div>

                      :

                      null

                      }

                      </div>

                  </BrowserView>


                  :


                  null

                  }



               
                
                </div>

              }

            </div>

              
                
          );
        }
    
}

export default Trash;
