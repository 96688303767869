
import React,  {Component} from 'react';
import {useEffect} from 'react';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import Carousel from 'react-bootstrap/Carousel';
import TextField from '@material-ui/core/TextField';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import PersonIcon from '@material-ui/icons/Person';
import SearchIcon from '@material-ui/icons/Search';
import SendIcon from '@material-ui/icons/Send';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/core/styles';
import { green, purple } from '@material-ui/core/colors';
import Autocomplete from '@material-ui/lab/Autocomplete';
import queryString from 'query-string';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import ReadMoreIcon from '@mui/icons-material/ReadMore';
import AssignmentIcon from '@mui/icons-material/Assignment';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import '../App.css';
import { Nav,Navbar,Button,Form,Row,Modal,Col} from 'react-bootstrap';
import Loader from "react-js-loader";
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import ReactPaginate from 'react-paginate';
import Card from 'react-bootstrap/Card'
import { BrowserView, MobileView, isBrowser, isMobile} from "react-device-detect";
import { isExpired, decodeToken }from "react-jwt";
const key = "MIICXAIBAAKBgQCiQ5lLUPoLiI3VI69VfZ32tWjsL6HvqzYEtUbxutptHb3PYhKcbqirJ2cADUyWBWpfWgAQyshSciov5PhskWF5wRyhxc0WOLuK72icFqOu2ZLE2TvRvAzjNT2TaBHBeeJ2t39u6pPBz9ejZuXc05AapG2Jh7HfCORkTsCIhwGydwIDAQABAoGAT1wYzMeF/RJuQV85mWcG9w8NKs53y68yxDoQ0ZBNaKCztaGSFwR5UzhZZsn"
const CryptoJS = require("crypto-js");

var errocode = 0;

var listdata=[];

class Listpayment extends Component{
    
    constructor(props) {
    super(props);
      this.state = {
        listpayment: [],
        modaladd:false,
        modalupdate: false,
        modaldelete: false,
        idpayment:0,
        idpayment:0,
        namepayment:'',
        latpayment:'',
        longpayment:'',
        loadershow:false,
        tokenexist:'',
        tokenvalue : '',
        wbslink:'',
        listuserinit: [],
        filterdata:'',
        offset: 0,
        listuserpage: [],
        perPage: 5,
        currentPage: 0,
        loadingdata: true,
        
      };

      this.handlePageClick = this.handlePageClick.bind(this);

  }

  handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    this.setState({
        currentPage: selectedPage,
        offset: offset
    }, () => {
        this.loadMoreData(this.state.listpaymentpage)
    });

};

  loadMoreData(data) {

  const slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
  this.setState({
    pageCount: Math.ceil(data.length / this.state.perPage),
    listpayment:slice
  })

  window.scrollTo(0, 0)

  }

  filterlist(e){

  
    this.setState({filterdata: e})
  
    if(e.lenght == 0){
      this.setState({listpayment: this.state.listpaymentinit})
      return;
    }
  
    const filteredItems = this.state.listpaymentinit.filter((paymentlist) => {
      return (paymentlist.name).toLowerCase().includes(e.toLowerCase()) || (paymentlist.nomsociete).toLowerCase().includes(e.toLowerCase()) || (paymentlist.latitude).toLowerCase().includes(e.toLowerCase()) || (paymentlist.longitude).toLowerCase().includes(e.toLowerCase());
    });
  
    
    if(filteredItems.length>0){
  
      this.setState({listpayment: filteredItems})
  
      var slice = filteredItems.slice(this.state.offset, this.state.offset + this.state.perPage)
            
            this.setState({
                pageCount: Math.ceil(filteredItems.length / this.state.perPage),
                listpayment:slice,
                listpaymentpage:filteredItems,
            })
  
    }else{
  
      this.setState({listpayment: [], listpaymentpage:[]})
  
    }
   
    
  }

  componentWillMount() {

    let wbslinkload = sessionStorage.getItem('wbs');

    
    this.setState({
      wbslink:wbslinkload,
    
    })

    let lnguser = sessionStorage.getItem('langueuser');

    this.setState({ lng: lnguser})

    let tokenuser = sessionStorage.getItem('tokenunsaid');

  const myDecodedToken = decodeToken(tokenuser);

      if(myDecodedToken == null){
        this.setState({ typeuser: null})
        document.location.href = '/login';
        return;
      }
      const isMyTokenExpired = isExpired(tokenuser);

      if(isMyTokenExpired == true){
        this.setState({ typeuser: null})
        document.location.href = '/login';
        return;
      }

  let tokencredit = JSON.stringify(myDecodedToken.uid).replace(/\"/g, "");
      
      fetch(wbslinkload + `getdatauser`,{                  
            
        mode: 'cors',     
        method:'post',
        headers: {'Authorization': 'Bearer '+ tokencredit},

        })

        .then(res => {

          errocode = res.status;
    
          if (!res.ok) {
              
              return res.json().then(json => { throw json; });
    
          }
    
          return res.json();
    
        })
        .then(data => {
          
          if(JSON.stringify(data.status).replace(/"/g, "") == "unsubscribed" && window.location.pathname != "/Buycredit"){

            document.location.href = "/Buycredit";

          }else{

            this.setState({
              loadingdata:false
            })
            
          }
          
      })
  
    if(tokenuser === null || tokenuser === 'Undefined'){

      this.setState({ tokenexist: false})
      return

    }else{

      this.setState({ tokenvalue: tokenuser})
      this.reloaddata();
      
    }

  }

  reloaddata () {

    if(this.state.tokenvalue !== ''){

          this.setState({ reload: true})
          
    }

  }
    
  async componentDidMount() { 

    let lnguser = sessionStorage.getItem('langueuser');

    let token = sessionStorage.getItem('tokenunsaid');
    
    const myDecodedToken = decodeToken(token);

    if(myDecodedToken == null){
      this.setState({ typeuser: null})
      document.location.href = '/login';
      return;
    }
    const isMyTokenExpired = isExpired(token);

    if(isMyTokenExpired == true){
      this.setState({ typeuser: null})
      document.location.href = '/login';
      return;
    }

    token = JSON.stringify(myDecodedToken.uid).replace(/\"/g, "");

    const response = await fetch(this.state.wbslink + 'listpayment',{                       
      mode:'cors',
      method:'post',
      headers: {'accept': 'application/json','Authorization': 'Bearer '+ token},
      });
    const data = await response.json();

    if(JSON.stringify(data).replace(/\"/g, "") !== '0'){

      this.setState({ listpayment: data, listpaymentinit: data,listpaymentpage:data,loadershow: false})

      data.forEach((item)=>{
        listdata.push({ 	idpayment :item.idpayment ,name:item.name,email:item.email,telephoneutilisateur:item.telephoneutilisateur,offertype:item.offertype,nomsociete:item.nomsociete,amount:item.amount});
      })
    
      var slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
      
      this.setState({
          pageCount: Math.ceil(data.length / this.state.perPage),
          listpaymentinit :data,
          listpayment:slice
      })
    
    }

    if(lnguser === "FR"){
      document.title = "Liste des paiements";
    }else{
      document.title = "List of payments";
    }

    return;

  }

  filterlist(e){

  
    this.setState({filterdata: e})
  
    if(e.lenght == 0){
      this.setState({listpayment: this.state.listpaymentinit})
      return;
    }
  
    const filteredItems = this.state.listpaymentinit.filter((paymentlist) => {
      return (paymentlist.name).toLowerCase().includes(e.toLowerCase()) || (paymentlist.email).toLowerCase().includes(e.toLowerCase()) || (paymentlist.telephoneutilisateur).toLowerCase().includes(e.toLowerCase()) || (paymentlist.offertype).toLowerCase().includes(e.toLowerCase()) || (paymentlist.amount).toLowerCase().includes(e.toLowerCase());
    });
  
    
    if(filteredItems.length>0){
  
      this.setState({listpayment: filteredItems})
  
      var slice = filteredItems.slice(this.state.offset, this.state.offset + this.state.perPage)
            
            this.setState({
                pageCount: Math.ceil(filteredItems.length / this.state.perPage),
                listpayment:slice,
                listpaymentpage:filteredItems,
            })
  
    }else{
  
      this.setState({listpayment: [], listpaymentpage:[]})
  
    }
   
    
  }


loaderdesactive(){

  this.setState({
    loadershow: false,
    selectedFile:'',
    idpayment: 0,
    namepayment: '',
    latpayment:'',
    longpayment:'',
    textloading:''
  });
  
}


    
  render() {

          return (

              <div>

                {this.state.loadingdata == true?

                  null
                
                  :
                  
                  <div className="App" style={{marginTop:'80px'}}>
                  
                  {this.state.tokenvalue === '' ?
                            

                            <Redirect push to="/Login"/>
                              

                            :

                            null

                    }

                  
                  
            

                 

                  <div className='centrervue' >

                  <input className="inputfind" style={{marginTop: isBrowser?"0px":"20px"}} type="text" name="search" placeholder={this.state.lng ==='FR'? 'Rechercher': 'Search'} value={this.state.filterdata} onChange={(e) => this.filterlist(e.target.value)}/>

                  <BrowserView>
               
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>

                          <TableCell >{this.state.lng ==="FR" ? 'Date' : 'Date'}</TableCell>
                          <TableCell >{this.state.lng ==="FR" ? 'Entreprise' : 'Company'}</TableCell>
                          <TableCell style={{textAlign:"payment"}}>{this.state.lng ==="FR" ? 'Utilisateur' : 'User'}</TableCell>
                          <TableCell style={{textAlign:"payment"}}>{this.state.lng ==="FR" ? 'Offre' : 'Offer'}</TableCell>
                          <TableCell style={{textAlign:"payment"}}>{this.state.lng ==="FR" ? 'Montant' : 'Amount'}</TableCell>
                          
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {this.state.listpayment.map((row) => (
                          <TableRow key={row.idpayment}>

                            <TableCell >{parseInt(new Date(Date.parse(row.paymentdate.replace(/[-]/g, '/'))).getDate()) < 10 ? <label>0{parseInt(new Date(Date.parse(row.paymentdate.replace(/[-]/g, '/'))).getDate())}</label>:parseInt(new Date(Date.parse(row.paymentdate.replace(/[-]/g, '/'))).getDate())}/{parseInt(new Date(Date.parse(row.paymentdate.replace(/[-]/g, '/'))).getMonth())+1 < 10 ? <label>0{parseInt(new Date(Date.parse(row.paymentdate.replace(/[-]/g, '/'))).getMonth())+1}</label>:parseInt(new Date(Date.parse(row.paymentdate.replace(/[-]/g, '/'))).getMonth())+1}/{new Date(Date.parse(row.paymentdate.replace(/[-]/g, '/'))).getFullYear()} - {parseInt(new Date(Date.parse(row.paymentdate.replace(/[-]/g, '/'))).getHours()) < 10 ? <label>0{parseInt(new Date(Date.parse(row.paymentdate.replace(/[-]/g, '/'))).getHours())}</label>:parseInt(new Date(Date.parse(row.paymentdate.replace(/[-]/g, '/'))).getHours())}:{parseInt(new Date(Date.parse(row.paymentdate.replace(/[-]/g, '/'))).getMinutes()) < 10 ? <label>0{parseInt(new Date(Date.parse(row.paymentdate.replace(/[-]/g, '/'))).getMinutes())}</label>:parseInt(new Date(Date.parse(row.paymentdate.replace(/[-]/g, '/'))).getMinutes())}:{parseInt(new Date(Date.parse(row.paymentdate.replace(/[-]/g, '/'))).getSeconds()) < 10 ? <label>0{parseInt(new Date(Date.parse(row.paymentdate.replace(/[-]/g, '/'))).getSeconds())}</label>:parseInt(new Date(Date.parse(row.paymentdate.replace(/[-]/g, '/'))).getSeconds())}s</TableCell>
                            <TableCell >{row.nomsociete}</TableCell>
                            <TableCell style={{textAlign:"payment"}}>{row.name}<br/><a style={{textAlign:'left', textDecoration:'none'}} href={"mailto:" + row.email}>Mail: {row.email}</a> <br/> <a style={{textAlign:'left', textDecoration:'none'}} href={"tel:" + row.telephoneutilisateur}>Tél: {row.telephoneutilisateur}</a></TableCell>
                            <TableCell style={{textAlign:"payment"}}>{row.offertype}</TableCell>
                            <TableCell style={{textAlign:"payment"}}>{row.amount}</TableCell>


                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>

                  {this.state.listpayment.length>0?

                  <ReactPaginate
                  previousLabel={"<< prev"}
                  previousClassName={"prevClass"}
                  previousLinkClassName={"prevClass"}
                  nextLabel={"next >>"}
                  nextClassName={"next"}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={this.state.pageCount}
                  marginPagesDisplayed={3}
                  pageRangeDisplayed={3}
                  onPageChange={this.handlePageClick}
                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"}/>

                  :

                  null
                  }

                  </BrowserView>

                  <MobileView>

                  <div className='paginationmobile' style={{marginTop:"-5%"}}>

                    {this.state.listpayment.length>0?

                      <ReactPaginate
                      previousLabel={"<<"}
                      previousClassName={"prevClass"}
                      previousLinkClassName={"prevClass"}
                      nextLabel={">>"}
                      nextClassName={"next"}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={this.state.pageCount}
                      marginPagesDisplayed={3}
                      pageRangeDisplayed={3}
                      onPageChange={this.handlePageClick}
                      containerClassName={"pagination"}
                      subContainerClassName={"pages pagination"}
                      activeClassName={"active"}/>

                      :

                      null
                      
                    }

                    </div>

                    <div  style={{marginTop:"5%"}}>
                      <p style={{color:"transparent"}}>.</p>
                    </div>

                    <div style={{marginTop:"40px"}}>

                      {this.state.listpayment.map((row) => (

                      <Card style={{ width: '90%', margin:"5%"}}>

                      <Card.Body  key={row.idpayment} style={{backgroundColor: "#f5fffa"}}>
        
                        <Card.Text>

                            <p style={{color:"black", marginTop:"10px"}}>{row.nomsociete}</p>
                            <p style={{color:"black", marginTop:"10px"}}>{row.name}</p>
                            <p style={{color:"black", marginTop:"10px"}}>Latitude : {row.latitude}</p>
                            <p style={{color:"black", marginTop:"10px"}}>Longitude : {row.longitude}</p>
                            <p style={{color:"black", marginTop:"10px"}}>Longitude : {row.longitude}</p>
                      
                        </Card.Text>
                          
                      </Card.Body>
                      </Card>

                      ))}

                    </div>

                  </MobileView>

                  </div> 
               
                </div>

                }

              </div>
        
          );
        }
    
}

export default Listpayment;
